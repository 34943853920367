import React from 'react';
import CryptoJS from 'crypto-js';
import { removeAll } from '../../utils/getLocalStorage';

const decryptData = () => {
    const superSecretKey = 'RamsInode2024';
    const data = localStorage.getItem("data");
    if(data){
      try {
          const bytes = CryptoJS.AES.decrypt(`${data}`, superSecretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          return JSON.parse(decryptedData);
        } catch (error) {
          console.error('Decryption error:', error);
          return null; // or handle the error in a different way
        }
    } else {
      removeAll();
    }
}

export default decryptData;
