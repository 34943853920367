import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    activateSweetAlert,
    deActivateSweetAlert,
  } from "../../SweetAlert";
import { toggleLoading } from "../../LoadingSlice";

interface FirmDetailsState {
  FirmDetails: {
    load: boolean;
    data: [];
  };
}

export const FirmDetailsSlice = createSlice({
  name: "FirmDetails",
  initialState: {
    FirmDetails: {
      load: false,
      data: [],
    },
  } as FirmDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchFirmDetails.pending, (state: FirmDetailsState) => {
        state.FirmDetails.load = true;
      })
      .addCase(fetchFirmDetails.fulfilled, (state: FirmDetailsState, action: any) => {
        state.FirmDetails.data = action.payload;
        state.FirmDetails.load = false;
      })
      .addCase(fetchFirmDetails.rejected, (state: FirmDetailsState) => {
        state.FirmDetails.load = false;
      });
  },
});

export default FirmDetailsSlice.reducer;

export const fetchFirmDetails = createAsyncThunk(
  "FirmDetails/fetchFirmDetails",
  async (param: { apiParam: { firm_id: any } }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/fetchTpiFirmDetails", {
        tpi_firm_id: param.apiParam.firm_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);

export const assignProject = createAsyncThunk(
    "FirmDetails/assignProject",
    async (param: { apiParam: { firm_id: any, login_user_id: any, warehouse_id: any }, navigation: any }, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.post("api/assignProject", {
          assigned_to: param.apiParam.firm_id,
          warehouse_id: param.apiParam.warehouse_id,
          assigned_by: param.apiParam.login_user_id
        });
        thunkAPI.dispatch(toggleLoading());

        if(data.message === "Project already assigned"){
          thunkAPI.dispatch(
            activateSweetAlert({
              show: true,
              type: "warning",
              title: "Warning!",
              confirmBtnText: 'Done',
              message: data.message,
              onConfirm: () => {
                handleonConfirm()
              },
            })
          );
        } else {
        thunkAPI.dispatch(
            activateSweetAlert({
              show: true,
              type: "success",
              title: "Success!",
              confirmBtnText: 'Done',
              message: data.message,
              onConfirm: () => {
                handleonConfirm()
              },
            })
          );
        }
    
        const handleonConfirm = () => {
            param.navigation("/tpi-inspection-summary");
            thunkAPI.dispatch(deActivateSweetAlert());
        };
        
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
      }
    }
  );


