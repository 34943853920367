import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface InventoryState {
  Inventory: {
    load: boolean;
    data: [];
  };
}

export const InventorySlice = createSlice({
  name: "Inventory",
  initialState: {
    Inventory: {
      load: false,
      data: [],
    },
  } as InventoryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(getSensorInventoryCountList.pending, (state: InventoryState) => {
        state.Inventory.load = true;
      })
      .addCase(getSensorInventoryCountList.fulfilled, (state: InventoryState, action: any) => {
        state.Inventory.data = action.payload;
        state.Inventory.load = false;
      })
      .addCase(getSensorInventoryCountList.rejected, (state: InventoryState) => {
        state.Inventory.load = false;
      })
      .addCase(getSensorInventoryListByType.pending, (state: InventoryState) => {
        state.Inventory.load = true;
      })
      .addCase(getSensorInventoryListByType.fulfilled, (state: InventoryState, action: any) => {
        state.Inventory.data = action.payload;
        state.Inventory.load = false;
      })
      .addCase(getSensorInventoryListByType.rejected, (state: InventoryState) => {
        state.Inventory.load = false;
      })
      .addCase(getSensorTypes.pending, (state: InventoryState) => {
        state.Inventory.load = true;
      })
      .addCase(getSensorTypes.fulfilled, (state: InventoryState, action: any) => {
        state.Inventory.data = action.payload;
        state.Inventory.load = false;
      })
      .addCase(getSensorTypes.rejected, (state: InventoryState) => {
        state.Inventory.load = false;
      });
  },
});

export default InventorySlice.reducer;

export const getSensorInventoryCountList = createAsyncThunk(
  "Inventory/getSensorInventoryCountList",
  async (
    param: { apiParam: { per_page: any; current_page: any, search: any } },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("sensor/v1/getSensorInventoryCountList?per_page=" + param.apiParam.per_page + "&current_page=" + param.apiParam.current_page + "&search=" + param.apiParam.search);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);

export const getSensorInventoryListByType = createAsyncThunk(
    "Inventory/getSensorInventoryListByType",
    async (
      param: { apiParam: { per_page: any; current_page: any, search: any, sensor_type_id: any } },
      thunkAPI
    ) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.get("sensor/v1/getSensorInventoryListByType?sensor_type_id=" + param.apiParam.sensor_type_id + "&current_page=" + param.apiParam.current_page + "&per_page=" + param.apiParam.per_page + "&search=" + param.apiParam.search);
        thunkAPI.dispatch(toggleLoading());
  
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);

export const getSensorTypes = createAsyncThunk(
    "Inventory/getSensorTypes",
    async (param, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.get("sensor/v1/getSensorTypes");
        thunkAPI.dispatch(toggleLoading());
  
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);

export const addSensor = createAsyncThunk(
    "Inventory/addSensor",
    async (param: { apiParam: any; navigation: any }, thunkAPI) => {
      try {
        const { data } = await DataServer.post("sensor/v1/addSensor", {
            ...param.apiParam
        });

  
        thunkAPI.dispatch(toggleLoading());
        thunkAPI.dispatch(
          activateSweetAlert({
            show: true,
            type: "success",
            title: "Success!",
            confirmBtnText: "Done",
            message: data.message,
            onConfirm: () => {
              handleonConfirm();
            },
          })
        );
  
        const handleonConfirm = () => {
          thunkAPI.dispatch(deActivateSweetAlert());
          param.navigation("/sensor-inventory");
        };
        return data.data;
      } catch (error) {
        // thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);

export const updateSensorDetails = createAsyncThunk(
    "Inventory/updateSensorDetails",
    async (param: { apiParam: any; navigation: any, sensor_pin: any }, thunkAPI) => {
      try {
        const { data } = await DataServer.patch("sensor/v1/updateSensorDetails", {
            ...param.apiParam,
            sensor_pin: param.sensor_pin
        });

  
        thunkAPI.dispatch(toggleLoading());
        thunkAPI.dispatch(
          activateSweetAlert({
            show: true,
            type: "success",
            title: "Success!",
            confirmBtnText: "Done",
            message: data.message,
            onConfirm: () => {
              handleonConfirm();
            },
          })
        );
  
        const handleonConfirm = () => {
          thunkAPI.dispatch(deActivateSweetAlert());
          param.navigation("/sensor-inventory");
        };
        return data.data;
      } catch (error) {
        // thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);
