import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface HealthParameterListState {
    HealthParameterList: {
        load: boolean;
        data: [];
    };
}

export const HealthParameterListSlice = createSlice({
    name: "HealthParameterList",
    initialState: {
        HealthParameterList: {
            load: false,
            data: [],
        },
    } as HealthParameterListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchHealthParameterList.pending, (state: HealthParameterListState) => {
                state.HealthParameterList.load = true;
            })
            .addCase(fetchHealthParameterList.fulfilled, (state: HealthParameterListState, action: any) => {
                state.HealthParameterList.data = action.payload;
                state.HealthParameterList.load = false;
            })
            .addCase(fetchHealthParameterList.rejected, (state: HealthParameterListState) => {
                state.HealthParameterList.load = false;
            })
            .addCase(fetchDefaultWithcustomData.pending, (state: HealthParameterListState) => {
                state.HealthParameterList.load = true;
            })
            .addCase(fetchDefaultWithcustomData.fulfilled, (state: HealthParameterListState, action: any) => {
                state.HealthParameterList.data = action.payload;
                state.HealthParameterList.load = false;
            })
            .addCase(fetchDefaultWithcustomData.rejected, (state: HealthParameterListState) => {
                state.HealthParameterList.load = false;
            });
    },
});

export default HealthParameterListSlice.reducer;

export const fetchHealthParameterList = createAsyncThunk(
    "HealthParameterList/fetchHealthParameterList",
    async (param: { apiParam: { page: any, per_page: any, search: any, code: any, element_pin: any, warehouse_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getElementHealthsParametersData", {
                warehouse_id: param.apiParam.warehouse_id,
                code: param.apiParam.code,
                element_pin: param.apiParam.element_pin, 
                page: param.apiParam.page,
                per_page: param.apiParam.per_page,
                search: param.apiParam.search
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return(error);
        }
    }
);

export const fetchDefaultWithcustomData = createAsyncThunk(
    "HealthParameterList/fetchDefaultWithcustomData",
    async (param: { apiParam: { page: any, per_page: any, search: any, code: any, element_pin: any, warehouse_id: any } }, thunkAPI) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            const { data } = await DataServer.post("api/getHealthParameterDefaultWithcustomData", {
                warehouse_id: param.apiParam.warehouse_id,
                code: param.apiParam.code,
                element_pin: param.apiParam.element_pin,
                page: param.apiParam.page,
                per_page: param.apiParam.per_page,
                search: param.apiParam.search
            });

            return data.data;

        } catch (error) {
            // return (error);
        }
    }
);
