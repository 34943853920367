import { FC } from "react";
import { Row, Col, Form } from "react-bootstrap";

export type InputSize = "medium" | "large";
export type InputType = "text" | "email" | "password" | "date";

export interface inputProps {
  value: any;
  onChange: any;
  id?: string;
  name?: string;
  label?: string;
  type?: InputType;
  size?: InputSize;
  className?: string;
  placeholder?: string;
  isInvalid?: boolean;
  errorMessage?: string;
}

const Input: FC<inputProps> = ({
  id,
  name,
  label,
  type = "text",
  size = "medium",
  className = "",
  placeholder,
  onChange,
  isInvalid,
  errorMessage,
  ...props
}) => {
  return (
    <>
      {label && (
        <Form.Group className="mb-3 row">
          <Form.Label column sm={3}>
            {label}
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              id={id}
              name={name}
              type={type}
              aria-label={label}
              placeholder={placeholder}
              className={className}
              onChange={(e) => onChange(e.target.value)}
              isInvalid={!!isInvalid}
              {...props}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      )}
      {!label && (
        <Form.Control
          id={id}
          name={name}
          type={type}
          aria-label={label}
          placeholder={placeholder}
          className={className}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      )}
    </>
  );
};

export default Input;
