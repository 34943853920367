import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface CreditSystemStatisticsState {
  CreditSystemStatistics: {
    load: boolean;
    data: [];
  };
}

export const CreditSystemStatisticsSlice = createSlice({
  name: "CreditSystemStatistics",
  initialState: {
    CreditSystemStatistics: {
      load: false,
      data: [],
    },
  } as CreditSystemStatisticsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchCreditSystemStatistics.pending, (state: CreditSystemStatisticsState) => {
        state.CreditSystemStatistics.load = true;
      })
      .addCase(fetchCreditSystemStatistics.fulfilled, (state: CreditSystemStatisticsState, action: any) => {
        state.CreditSystemStatistics.data = action.payload;
        state.CreditSystemStatistics.load = false;
      })
      .addCase(fetchCreditSystemStatistics.rejected, (state: CreditSystemStatisticsState) => {
        state.CreditSystemStatistics.load = false;
      })
  },
});

export default CreditSystemStatisticsSlice.reducer;

export const fetchCreditSystemStatistics = createAsyncThunk(
  "CreditSystemStatistics/fetchCreditSystemStatistics",
  async (param: { apiParam: { tpi_firm_id: any } }, thunkAPI) => {
    try {
      const { data } = await DataServer.get("api/GetCreditSystemStatistics?company_id=" + param.apiParam.tpi_firm_id);
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);
