import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../config.axios";
import { toggleLoading } from "../../LoadingSlice";

interface TpiActionRequiredState {
  tpiactionrequired: {
    load: boolean;
    data: any[]; // Use `any[]` instead of `[]` for better type definition
  };
}

export const fetchTpiActionRequired = createAsyncThunk(
  "tpiactionrequired/fetchTpiActionRequired",
  async (param: { apiParam: { id: any } }, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/editTpiActionRequired", {
        reported_issue_pin: param.apiParam.id
      });
      return data.data;
    } catch (error) {
      // Handle error
    }
  }
);

export const TpiActionRequiredSlice = createSlice({
  name: "tpiactionrequired",
  initialState: {
    tpiactionrequired: {
      load: false,
      data: [],
    },
  } as TpiActionRequiredState,
  reducers: {
    clearTpiActionRequired: (state) => {
      state.tpiactionrequired = {
        load: false,
        data: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTpiActionRequired.pending, (state: TpiActionRequiredState) => {
        state.tpiactionrequired.load = true;
      })
      .addCase(fetchTpiActionRequired.fulfilled, (state: TpiActionRequiredState, action: any) => {
        state.tpiactionrequired.data = action.payload;
        state.tpiactionrequired.load = false;
      })
      .addCase(fetchTpiActionRequired.rejected, (state: TpiActionRequiredState) => {
        state.tpiactionrequired.load = false;
      });
  },
});

export const { clearTpiActionRequired } = TpiActionRequiredSlice.actions;
export default TpiActionRequiredSlice.reducer;
