import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface FirmListState {
  FirmList: {
    load: boolean;
    data: [];
  };
}

export const FirmListSlice = createSlice({
  name: "FirmList",
  initialState: {
    FirmList: {
      load: false,
      data: [],
    },
  } as FirmListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchFirmList.pending, (state: FirmListState) => {
        state.FirmList.load = true;
      })
      .addCase(fetchFirmList.fulfilled, (state: FirmListState, action: any) => {
        state.FirmList.data = action.payload;
        state.FirmList.load = false;
      })
      .addCase(fetchFirmList.rejected, (state: FirmListState) => {
        state.FirmList.load = false;
      });
  },
});

export default FirmListSlice.reducer;

export const fetchFirmList = createAsyncThunk(
  "FirmList/fetchFirmList",
  async (param: any) => {
    try {
      const { data } = await DataServer.post("api/fetchTpiFirmList", {
        warehouse_id: param.apiParam.warehouse_id,
        per_page: 10
      });
      return data.data;
    } catch (error) {
      // console.log("Error in Login", error);
    }
  }
);
