import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface GenerelizedChecklistState {
    GenerelizedChecklist: {
        load: boolean;
        data: [];
    };
}

export const GenerelizedChecklistSlice = createSlice({
    name: "GenerelizedChecklist",
    initialState: {
        GenerelizedChecklist: {
            load: false,
            data: [],
        },
    } as GenerelizedChecklistState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchGenerelizedChecklist.pending, (state: GenerelizedChecklistState) => {
                state.GenerelizedChecklist.load = true;
            })
            .addCase(fetchGenerelizedChecklist.fulfilled, (state: GenerelizedChecklistState, action: any) => {
                state.GenerelizedChecklist.data = action.payload;
                state.GenerelizedChecklist.load = false;
            })
            .addCase(fetchGenerelizedChecklist.rejected, (state: GenerelizedChecklistState) => {
                state.GenerelizedChecklist.load = false;
            });
    },
});

export default GenerelizedChecklistSlice.reducer;

export const fetchGenerelizedChecklist = createAsyncThunk(
    "GenerelizedChecklist/fetchGenerelizedChecklist",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getGenerelizedChecklistIssueDataCount", {
                project_pin: param.apiParam.project_pin,
                layout_id: param.apiParam.layout_id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
