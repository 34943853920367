import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface TpiIssueState {
    TpiIssue: {
        load: boolean;
        data: [];
    };
}

export const TpiIssueSlice = createSlice({
    name: "TpiIssue",
    initialState: {
        TpiIssue: {
            load: false,
            data: [],
        },
    } as TpiIssueState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchTpiIssue.pending, (state: TpiIssueState) => {
                state.TpiIssue.load = true;
            })
            .addCase(fetchTpiIssue.fulfilled, (state: TpiIssueState, action: any) => {
                state.TpiIssue.data = action.payload;
                state.TpiIssue.load = false;
            })
            .addCase(fetchTpiIssue.rejected, (state: TpiIssueState) => {
                state.TpiIssue.load = false;
            });
    },
});

export default TpiIssueSlice.reducer;

export const fetchTpiIssue = createAsyncThunk(
    "TpiIssue/fetchTpiIssue",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getTpiIssuesForAllViewsData", {
                project_pin: param.apiParam.project_pin,
                layout_id: param.apiParam.layout_id
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
