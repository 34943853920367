import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../sensorconfig.axios";

interface EventsListByMHEState {
  EventsListByMHE: {
    load: boolean;
    data: [];
  };
}

export const EventsListByMHESlice = createSlice({
  name: "EventsListByMHE",
  initialState: {
    EventsListByMHE: {
      load: false,
      data: [],
    },
  } as EventsListByMHEState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEventsListByMHE.pending, (state) => {
        state.EventsListByMHE.load = true;
      })
      .addCase(getEventsListByMHE.fulfilled, (state, action) => {
        state.EventsListByMHE.data = action.payload;
        state.EventsListByMHE.load = false;
      })
      .addCase(getEventsListByMHE.rejected, (state) => {
        state.EventsListByMHE.load = false;
      })
      .addCase(getEventsListByOperator.pending, (state) => {
        state.EventsListByMHE.load = true;
      })
      .addCase(getEventsListByOperator.fulfilled, (state, action) => {
        state.EventsListByMHE.data = action.payload;
        state.EventsListByMHE.load = false;
      })
      .addCase(getEventsListByOperator.rejected, (state) => {
        state.EventsListByMHE.load = false;
      });
  },
});

export default EventsListByMHESlice.reducer;

export const getEventsListByMHE = createAsyncThunk(
  "EventsListByMHE/getEventsListByMHE",
  async (param: any, thunkAPI) => {
    try {
      var data: any; 
      if(param.apiParam.operator_id === "all"){
        data = await DataServer.get("management/mheSummary/v1/getEventsListByMHE?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&event_type=" + param.apiParam.event_type + "&per_page=" + param.apiParam.per_page + "&current_page=" + param.apiParam.current_page);
      } else {
        data = await DataServer.get("management/mheSummary/v1/getEventsListByMHE?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&event_type=" + param.apiParam.event_type + "&per_page=" + param.apiParam.per_page + "&current_page=" + param.apiParam.current_page + "&operator_id=" + param.apiParam.operator_id);
      }
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const getEventsListByOperator = createAsyncThunk(
  "EventsListByMHE/getEventsListByOperator",
  async (param: any, thunkAPI) => {
    try {
      var data = await DataServer.get("management/operatorSummary/v1/getEventsListByOperator?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&event_type=" + param.apiParam.event_type + "&per_page=" + param.apiParam.per_page + "&current_page=" + param.apiParam.current_page + "&operator_id=" + param.apiParam.operator_id);
      
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
