import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../sensorconfig.axios";

interface MHEStatisticsState {
  MHEStatistics: {
    load: boolean;
    data: [];
  };
}

export const MHEStatisticsSlice = createSlice({
  name: "MHEStatistics",
  initialState: {
    MHEStatistics: {
      load: false,
      data: [],
    },
  } as MHEStatisticsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMHEStatistics.pending, (state) => {
        state.MHEStatistics.load = true;
      })
      .addCase(getMHEStatistics.fulfilled, (state, action) => {
        state.MHEStatistics.data = action.payload;
        state.MHEStatistics.load = false;
      })
      .addCase(getMHEStatistics.rejected, (state) => {
        state.MHEStatistics.load = false;
      });
  },
});

export default MHEStatisticsSlice.reducer;

export const getMHEStatistics = createAsyncThunk(
  "MHEStatistics/getMHEStatistics",
  async (param: any, thunkAPI) => {
    console.log("param", param)
    try {
      var data: any; 
      if(param.apiParam.operator_id === "all"){
        data = await DataServer.get("management/mheSummary/v1/getMHEStatistics?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id);
      } else {
        data = await DataServer.get("management/mheSummary/v1/getMHEStatistics?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&operator_id=" + param.apiParam.operator_id);
      }
    //   if (status !== 200) throw "Login Error";
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
