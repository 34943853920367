import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface AnnexureState {
  Annexure: {
    load: boolean;
    data: [];
  };
}

export const AnnexureSlice = createSlice({
  name: "Annexure",
  initialState: {
    Annexure: {
      load: false,
      data: [],
    },
  } as AnnexureState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchAnnexure.pending, (state: AnnexureState) => {
        state.Annexure.load = true;
      })
      .addCase(fetchAnnexure.fulfilled, (state: AnnexureState, action: any) => {
        state.Annexure.data = action.payload;
        state.Annexure.load = false;
      })
      .addCase(fetchAnnexure.rejected, (state: AnnexureState) => {
        state.Annexure.load = false;
      });
  },
});

export default AnnexureSlice.reducer;

export const fetchAnnexure = createAsyncThunk(
  "Annexure/fetchAnnexure",
  async (
    param: { apiParam: { project_pin: any; layout_id: any } },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getAnnextureData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id,
      });
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
