import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface WarehouseDetailsState {
  warehousedetails: {
    load: boolean;
    data: [];
  };
}

export const IssueImageSlice = createSlice({
  name: "warehousedetails",
  initialState: {
    warehousedetails: {
      load: false,
      data: [],
    },
  } as WarehouseDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(
        fetchWarehouseDetails.pending,
        (state: WarehouseDetailsState) => {
          state.warehousedetails.load = true;
        }
      )
      .addCase(
        fetchWarehouseDetails.fulfilled,
        (state: WarehouseDetailsState, action: any) => {
          state.warehousedetails.data = action.payload;
          state.warehousedetails.load = false;
        }
      )
      .addCase(
        fetchWarehouseDetails.rejected,
        (state: WarehouseDetailsState) => {
          state.warehousedetails.load = false;
        }
      );
  },
});

export default IssueImageSlice.reducer;

export const fetchWarehouseDetails = createAsyncThunk(
  "warehousedetails/fetchWarehouseDetails",
  async (param: { apiParam: { warehouseId: any } }, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/warehouseDetails", {
        warehouseId: param.apiParam.warehouseId,
      });
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);

export const updateWarehouseDetails = createAsyncThunk(
  "warehousedetails/updateWarehouseDetails",
  async (param: { apiParam: any }, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/updateWarehouseDetails", {
        ...param.apiParam,
      });

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: "Done",
          message: data.message,
          onConfirm: () => {
            handleonConfirm();
          },
        })
      );

      const handleonConfirm = () => {
        thunkAPI.dispatch(deActivateSweetAlert());
        window.location.reload();
      };

      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);
