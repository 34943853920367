import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface TotalEventDistributionState {
  TotalEventDistribution: {
    load: boolean;
    data: [];
  };
}

export const TotalEventDistributionSlice = createSlice({
  name: "TotalEventDistribution",
  initialState: {
    TotalEventDistribution: {
      load: false,
      data: [],
    },
  } as TotalEventDistributionState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getTotalEventDistribution.pending, (state: TotalEventDistributionState) => {
        state.TotalEventDistribution.load = true;
      })
      .addCase(getTotalEventDistribution.fulfilled, (state: TotalEventDistributionState, action: any) => {
        state.TotalEventDistribution.data = action.payload;
        state.TotalEventDistribution.load = false;
      })
      .addCase(getTotalEventDistribution.rejected, (state: TotalEventDistributionState) => {
        state.TotalEventDistribution.load = false;
      });
  },
});

export default TotalEventDistributionSlice.reducer;

export const getTotalEventDistribution = createAsyncThunk(
  "TotalEventDistribution/getTotalEventDistribution",
  async (param: {apiParam: { company_id: any, warehouse_id: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getTotalEventDistribution?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
  