import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface ReportImagesSummaryState {
  imagessummary: {
    load: boolean;
    data: [];
  };
}

export const imagessummarySlice = createSlice({
  name: "imagessummary",
  initialState: {
    imagessummary: {
      load: false,
      data: [],
    },
  } as ReportImagesSummaryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(
        fetchimagessummary.pending,
        (state: ReportImagesSummaryState) => {
          state.imagessummary.load = true;
        }
      )
      .addCase(
        fetchimagessummary.fulfilled,
        (state: ReportImagesSummaryState, action: any) => {
          state.imagessummary.data = action.payload;
          state.imagessummary.load = false;
        }
      )
      .addCase(
        fetchimagessummary.rejected,
        (state: ReportImagesSummaryState) => {
          state.imagessummary.load = false;
        }
      );
  },
});

export default imagessummarySlice.reducer;

export const fetchimagessummary = createAsyncThunk(
  "imagessummary/fetchimagessummary",
  async (
    param: { apiParam: { project_pin: any; layout_id: any } },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getImportantFlagImagesData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id,
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
