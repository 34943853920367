import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface GetAlertIntervalState {
    getalertinterval: {
        load: boolean;
        data: [];
    };
}

export const GetAlertIntervalSlice = createSlice({
    name: "getalertinterval",
    initialState: {
        getalertinterval: {
            load: false,
            data: [],
        },
    } as GetAlertIntervalState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchAlertInterval.pending, (state: GetAlertIntervalState) => {
                state.getalertinterval.load = true;
            })
            .addCase(fetchAlertInterval.fulfilled, (state: GetAlertIntervalState, action: any) => {
                state.getalertinterval.data = action.payload;
                state.getalertinterval.load = false;
            })
            .addCase(fetchAlertInterval.rejected, (state: GetAlertIntervalState) => {
                state.getalertinterval.load = false;
            });
    },
});

export default GetAlertIntervalSlice.reducer;

export const fetchAlertInterval = createAsyncThunk(
    "getalertintervalcount/fetchAlertInterval",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.get("api/GetNotificationAlertIntervals?warehouse_id=" + param.apiParam.warehouse_id);
            return data.data;
        } catch (error) {
            
        }
    }
);
