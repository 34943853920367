import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TpiGraphState {
  GraphStatistics: {
    load: boolean;
    data: [];
  };
}

export const GraphStatisticsSlice = createSlice({
  name: "GraphStatistics",
  initialState: {
    GraphStatistics: {
      load: false,
      data: [],
    },
  } as TpiGraphState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchGraphStatistics.pending, (state: TpiGraphState) => {
        state.GraphStatistics.load = true;
      })
      .addCase(fetchGraphStatistics.fulfilled, (state: TpiGraphState, action: any) => {
        state.GraphStatistics.data = action.payload;
        state.GraphStatistics.load = false;
      })
      .addCase(fetchGraphStatistics.rejected, (state: TpiGraphState) => {
        state.GraphStatistics.load = false;
      });
  },
});

export default GraphStatisticsSlice.reducer;

export const fetchGraphStatistics = createAsyncThunk(
  "GraphStatistics/fetchGraphStatistics",
  async (param: {apiParam: { project_pin: any }}, thunkAPI) => {
    const tpi_firm_id = localStorage.getItem("_tpi_firm_id");
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getTpiCompletedInspectionGraphData", {
        project_pin: param.apiParam.project_pin
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
