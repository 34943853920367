import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface LayoutListGraphState {
  layoutlistgraph: {
    load: boolean;
    data: [];
  };
}

export const layoutlistgraphgraphSlice = createSlice({
  name: "layoutlistgraph",
  initialState: {
    layoutlistgraph: {
      load: false,
      data: [],
    },
  } as LayoutListGraphState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(getLayoutsListForSchedule.pending, (state: LayoutListGraphState) => {
        state.layoutlistgraph.load = true;
      })
      .addCase(getLayoutsListForSchedule.fulfilled, (state: LayoutListGraphState, action: any) => {
        state.layoutlistgraph.data = action.payload;
        state.layoutlistgraph.load = false;
      })
      .addCase(getLayoutsListForSchedule.rejected, (state: LayoutListGraphState) => {
        state.layoutlistgraph.load = false;
      });
  },
});

export default layoutlistgraphgraphSlice.reducer;

export const getLayoutsListForSchedule = createAsyncThunk(
  "layoutlistgraph/getLayoutsListForSchedule",
  async (param: any) => {
    try {
      const { data } = await DataServer.post("api/getLayoutsListForSchedule", {
        warehouse_id: param.apiParam.warehouse_id
      });

      return data.data;
    } catch (error) {
      return error;
    }
  }
);
