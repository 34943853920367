import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface IssueImagesState {
  issueimage: {
    load: boolean;
    data: [];
  };
}

export const IssueImageSlice = createSlice({
  name: "issueimage",
  initialState: {
    issueimage: {
      load: false,
      data: [],
    },
  } as IssueImagesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchIssueImages.pending, (state: IssueImagesState) => {
        state.issueimage.load = true;
      })
      .addCase(fetchIssueImages.fulfilled, (state: IssueImagesState, action: any) => {
        state.issueimage.data = action.payload;
        state.issueimage.load = false;
      })
      .addCase(fetchIssueImages.rejected, (state: IssueImagesState) => {
        state.issueimage.load = false;
      })
      .addCase(fetchTpiIssueImages.pending, (state: IssueImagesState) => {
        state.issueimage.load = true;
      })
      .addCase(fetchTpiIssueImages.fulfilled, (state: IssueImagesState, action: any) => {
        state.issueimage.data = action.payload;
        state.issueimage.load = false;
      })
      .addCase(fetchTpiIssueImages.rejected, (state: IssueImagesState) => {
        state.issueimage.load = false;
      });
  },
});

export default IssueImageSlice.reducer;

export const fetchIssueImages = createAsyncThunk(
  "issue/fetchIssueImages",
  async (param: {apiParam: {issue_pin: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/issueImages", {
        reported_issue_pin: param.apiParam.issue_pin
      });
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);

export const fetchTpiIssueImages = createAsyncThunk(
  "issue/fetchTpiIssueImages",
  async (param: {apiParam: {id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/postTpiIssueImages", {
        tpi_reported_issue_pin: param.apiParam.id
      });
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);

