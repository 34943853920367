import React, {useState, useEffect} from 'react';
import "../../../assets/css/views/_pagenotfound.scss"
import nointernet from "../../../assets/img/nointernet.png";

const NoInternetConnection = (props: any) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return<div>
        <div className="img-midd text-center pt-1 mt-5">
        <svg width="500" height="348" viewBox="0 0 500 348" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M500 302.399H0V302.649H500V302.399Z" fill="#EBEBEB"/>
            <path d="M449.899 318.49H416.779V318.74H449.899V318.49Z" fill="#EBEBEB"/>
            <path d="M331.219 321.21H322.529V321.46H331.219V321.21Z" fill="#EBEBEB"/>
            <path d="M415.78 309.21H396.59V309.46H415.78V309.21Z" fill="#EBEBEB"/>
            <path d="M95.6509 310.89H52.4609V311.14H95.6509V310.89Z" fill="#EBEBEB"/>
            <path d="M110.891 310.89H104.561V311.14H110.891V310.89Z" fill="#EBEBEB"/>
            <path d="M225.151 315.109H131.471V315.359H225.151V315.109Z" fill="#EBEBEB"/>
            <path d="M434.001 300.79V253.97H430.001V231.67H424.141V154.1H415.681V144.09L397.621 135.67V125.57H395.121V154.1H391.621V231.67H388.111V190.88H378.611V169.86H375.951V231.67H368.731V208.14H363.981V202.14H352.971V181.62H346.941V167.11H339.531V145.97H336.881V167.11H329.471V181.62H323.441V202.14H312.431V208.14H307.681V231.67H302.041V202.14H291.031V138.59H287.531V131.58H264.011V138.59H259.001V184.62H238.991V189.13H231.481V192.63H238.991V210.65H217.971V150.6H213.461V137.84H203.711V92.5498H200.951V137.84H193.951V150.6H152.871V146.4H150.371V174.92H146.871V175.42H144.761V203.94H141.261V211.7H133.861V190.68H131.211V240.73H128.251V219.71H125.601V252.49H123.981V228.97H119.231V222.96H108.221V202.44H102.191V187.93H94.7807V166.79H92.1407V187.93H84.7207V202.44H78.6907V222.96H67.6907V228.97H62.9307V251.99H62.0807V252.49H59.7207V300.79H434.001Z" fill="#E6E6E6"/>
            <path d="M178.629 153.399H175.789V156.239H178.629V153.399Z" fill="#F0F0F0"/>
            <path d="M178.629 158.16H175.789V161H178.629V158.16Z" fill="#F0F0F0"/>
            <path d="M178.629 177.17H175.789V180.01H178.629V177.17Z" fill="#F0F0F0"/>
            <path d="M178.629 181.93H175.789V184.77H178.629V181.93Z" fill="#F0F0F0"/>
            <path d="M178.629 186.68H175.789V189.52H178.629V186.68Z" fill="#F0F0F0"/>
            <path d="M178.629 200.939H175.789V203.779H178.629V200.939Z" fill="#F0F0F0"/>
            <path d="M178.629 205.7H175.789V208.54H178.629V205.7Z" fill="#F0F0F0"/>
            <path d="M183.141 172.42H180.301V175.26H183.141V172.42Z" fill="#F0F0F0"/>
            <path d="M183.141 177.17H180.301V180.01H183.141V177.17Z" fill="#F0F0F0"/>
            <path d="M183.141 181.93H180.301V184.77H183.141V181.93Z" fill="#F0F0F0"/>
            <path d="M183.141 186.68H180.301V189.52H183.141V186.68Z" fill="#F0F0F0"/>
            <path d="M183.141 191.43H180.301V194.27H183.141V191.43Z" fill="#F0F0F0"/>
            <path d="M183.141 200.939H180.301V203.779H183.141V200.939Z" fill="#F0F0F0"/>
            <path d="M183.141 205.7H180.301V208.54H183.141V205.7Z" fill="#F0F0F0"/>
            <path d="M187.641 153.399H184.801V156.239H187.641V153.399Z" fill="#F0F0F0"/>
            <path d="M187.641 158.16H184.801V161H187.641V158.16Z" fill="#F0F0F0"/>
            <path d="M187.641 162.91H184.801V165.75H187.641V162.91Z" fill="#F0F0F0"/>
            <path d="M187.641 167.66H184.801V170.5H187.641V167.66Z" fill="#F0F0F0"/>
            <path d="M187.641 172.42H184.801V175.26H187.641V172.42Z" fill="#F0F0F0"/>
            <path d="M187.641 177.17H184.801V180.01H187.641V177.17Z" fill="#F0F0F0"/>
            <path d="M187.641 181.93H184.801V184.77H187.641V181.93Z" fill="#F0F0F0"/>
            <path d="M187.641 186.68H184.801V189.52H187.641V186.68Z" fill="#F0F0F0"/>
            <path d="M187.641 191.43H184.801V194.27H187.641V191.43Z" fill="#F0F0F0"/>
            <path d="M187.641 196.189H184.801V199.029H187.641V196.189Z" fill="#F0F0F0"/>
            <path d="M187.641 205.7H184.801V208.54H187.641V205.7Z" fill="#F0F0F0"/>
            <path d="M192.141 153.399H189.301V156.239H192.141V153.399Z" fill="#F0F0F0"/>
            <path d="M192.141 158.16H189.301V161H192.141V158.16Z" fill="#F0F0F0"/>
            <path d="M192.141 162.91H189.301V165.75H192.141V162.91Z" fill="#F0F0F0"/>
            <path d="M192.141 167.66H189.301V170.5H192.141V167.66Z" fill="#F0F0F0"/>
            <path d="M192.141 172.42H189.301V175.26H192.141V172.42Z" fill="#F0F0F0"/>
            <path d="M192.141 177.17H189.301V180.01H192.141V177.17Z" fill="#F0F0F0"/>
            <path d="M192.141 181.93H189.301V184.77H192.141V181.93Z" fill="#F0F0F0"/>
            <path d="M192.141 224.71H189.301V227.55H192.141V224.71Z" fill="#F0F0F0"/>
            <path d="M196.651 153.399H193.811V156.239H196.651V153.399Z" fill="#F0F0F0"/>
            <path d="M196.651 158.16H193.811V161H196.651V158.16Z" fill="#F0F0F0"/>
            <path d="M196.651 162.91H193.811V165.75H196.651V162.91Z" fill="#F0F0F0"/>
            <path d="M196.651 167.66H193.811V170.5H196.651V167.66Z" fill="#F0F0F0"/>
            <path d="M196.651 172.42H193.811V175.26H196.651V172.42Z" fill="#F0F0F0"/>
            <path d="M196.651 177.17H193.811V180.01H196.651V177.17Z" fill="#F0F0F0"/>
            <path d="M196.651 181.93H193.811V184.77H196.651V181.93Z" fill="#F0F0F0"/>
            <path d="M196.651 186.68H193.811V189.52H196.651V186.68Z" fill="#F0F0F0"/>
            <path d="M196.651 215.2H193.811V218.04H196.651V215.2Z" fill="#F0F0F0"/>
            <path d="M196.651 219.96H193.811V222.8H196.651V219.96Z" fill="#F0F0F0"/>
            <path d="M196.651 224.71H193.811V227.55H196.651V224.71Z" fill="#F0F0F0"/>
            <path d="M196.651 229.47H193.811V232.31H196.651V229.47Z" fill="#F0F0F0"/>
            <path d="M196.651 248.479H193.811V251.319H196.651V248.479Z" fill="#F0F0F0"/>
            <path d="M201.151 153.399H198.311V156.239H201.151V153.399Z" fill="#F0F0F0"/>
            <path d="M201.151 158.16H198.311V161H201.151V158.16Z" fill="#F0F0F0"/>
            <path d="M201.151 162.91H198.311V165.75H201.151V162.91Z" fill="#F0F0F0"/>
            <path d="M201.151 167.66H198.311V170.5H201.151V167.66Z" fill="#F0F0F0"/>
            <path d="M201.151 172.42H198.311V175.26H201.151V172.42Z" fill="#F0F0F0"/>
            <path d="M201.151 177.17H198.311V180.01H201.151V177.17Z" fill="#F0F0F0"/>
            <path d="M201.151 181.93H198.311V184.77H201.151V181.93Z" fill="#F0F0F0"/>
            <path d="M201.151 186.68H198.311V189.52H201.151V186.68Z" fill="#F0F0F0"/>
            <path d="M201.151 191.43H198.311V194.27H201.151V191.43Z" fill="#F0F0F0"/>
            <path d="M201.151 196.189H198.311V199.029H201.151V196.189Z" fill="#F0F0F0"/>
            <path d="M201.151 210.45H198.311V213.29H201.151V210.45Z" fill="#F0F0F0"/>
            <path d="M201.151 215.2H198.311V218.04H201.151V215.2Z" fill="#F0F0F0"/>
            <path d="M201.151 219.96H198.311V222.8H201.151V219.96Z" fill="#F0F0F0"/>
            <path d="M201.151 224.71H198.311V227.55H201.151V224.71Z" fill="#F0F0F0"/>
            <path d="M201.151 229.47H198.311V232.31H201.151V229.47Z" fill="#F0F0F0"/>
            <path d="M201.151 248.479H198.311V251.319H201.151V248.479Z" fill="#F0F0F0"/>
            <path d="M205.651 153.399H202.811V156.239H205.651V153.399Z" fill="#F0F0F0"/>
            <path d="M205.651 158.16H202.811V161H205.651V158.16Z" fill="#F0F0F0"/>
            <path d="M205.651 162.91H202.811V165.75H205.651V162.91Z" fill="#F0F0F0"/>
            <path d="M205.651 167.66H202.811V170.5H205.651V167.66Z" fill="#F0F0F0"/>
            <path d="M205.651 172.42H202.811V175.26H205.651V172.42Z" fill="#F0F0F0"/>
            <path d="M205.651 177.17H202.811V180.01H205.651V177.17Z" fill="#F0F0F0"/>
            <path d="M205.651 181.93H202.811V184.77H205.651V181.93Z" fill="#F0F0F0"/>
            <path d="M205.651 186.68H202.811V189.52H205.651V186.68Z" fill="#F0F0F0"/>
            <path d="M205.651 191.43H202.811V194.27H205.651V191.43Z" fill="#F0F0F0"/>
            <path d="M205.651 196.189H202.811V199.029H205.651V196.189Z" fill="#F0F0F0"/>
            <path d="M205.651 200.939H202.811V203.779H205.651V200.939Z" fill="#F0F0F0"/>
            <path d="M205.651 205.7H202.811V208.54H205.651V205.7Z" fill="#F0F0F0"/>
            <path d="M205.651 210.45H202.811V213.29H205.651V210.45Z" fill="#F0F0F0"/>
            <path d="M205.651 215.2H202.811V218.04H205.651V215.2Z" fill="#F0F0F0"/>
            <path d="M205.651 219.96H202.811V222.8H205.651V219.96Z" fill="#F0F0F0"/>
            <path d="M205.651 224.71H202.811V227.55H205.651V224.71Z" fill="#F0F0F0"/>
            <path d="M205.651 229.47H202.811V232.31H205.651V229.47Z" fill="#F0F0F0"/>
            <path d="M205.651 234.22H202.811V237.06H205.651V234.22Z" fill="#F0F0F0"/>
            <path d="M210.16 153.399H207.32V156.239H210.16V153.399Z" fill="#F0F0F0"/>
            <path d="M210.16 158.16H207.32V161H210.16V158.16Z" fill="#F0F0F0"/>
            <path d="M210.16 162.91H207.32V165.75H210.16V162.91Z" fill="#F0F0F0"/>
            <path d="M210.16 167.66H207.32V170.5H210.16V167.66Z" fill="#F0F0F0"/>
            <path d="M210.16 172.42H207.32V175.26H210.16V172.42Z" fill="#F0F0F0"/>
            <path d="M210.16 177.17H207.32V180.01H210.16V177.17Z" fill="#F0F0F0"/>
            <path d="M210.16 181.93H207.32V184.77H210.16V181.93Z" fill="#F0F0F0"/>
            <path d="M210.16 186.68H207.32V189.52H210.16V186.68Z" fill="#F0F0F0"/>
            <path d="M210.16 191.43H207.32V194.27H210.16V191.43Z" fill="#F0F0F0"/>
            <path d="M210.16 196.189H207.32V199.029H210.16V196.189Z" fill="#F0F0F0"/>
            <path d="M210.16 210.45H207.32V213.29H210.16V210.45Z" fill="#F0F0F0"/>
            <path d="M210.16 215.2H207.32V218.04H210.16V215.2Z" fill="#F0F0F0"/>
            <path d="M210.16 219.96H207.32V222.8H210.16V219.96Z" fill="#F0F0F0"/>
            <path d="M210.16 224.71H207.32V227.55H210.16V224.71Z" fill="#F0F0F0"/>
            <path d="M214.66 153.399H211.82V156.239H214.66V153.399Z" fill="#F0F0F0"/>
            <path d="M214.66 158.16H211.82V161H214.66V158.16Z" fill="#F0F0F0"/>
            <path d="M214.66 162.91H211.82V165.75H214.66V162.91Z" fill="#F0F0F0"/>
            <path d="M214.66 167.66H211.82V170.5H214.66V167.66Z" fill="#F0F0F0"/>
            <path d="M214.66 172.42H211.82V175.26H214.66V172.42Z" fill="#F0F0F0"/>
            <path d="M214.66 177.17H211.82V180.01H214.66V177.17Z" fill="#F0F0F0"/>
            <path d="M214.66 181.93H211.82V184.77H214.66V181.93Z" fill="#F0F0F0"/>
            <path d="M214.66 186.68H211.82V189.52H214.66V186.68Z" fill="#F0F0F0"/>
            <path d="M214.66 191.43H211.82V194.27H214.66V191.43Z" fill="#F0F0F0"/>
            <path d="M214.66 196.189H211.82V199.029H214.66V196.189Z" fill="#F0F0F0"/>
            <path d="M214.66 210.45H211.82V213.29H214.66V210.45Z" fill="#F0F0F0"/>
            <path d="M214.66 215.2H211.82V218.04H214.66V215.2Z" fill="#F0F0F0"/>
            <path d="M214.66 219.96H211.82V222.8H214.66V219.96Z" fill="#F0F0F0"/>
            <path d="M265.369 141.06H262.529V143.9H265.369V141.06Z" fill="#F0F0F0"/>
            <path d="M265.369 145.81H262.529V148.65H265.369V145.81Z" fill="#F0F0F0"/>
            <path d="M265.369 150.57H262.529V153.41H265.369V150.57Z" fill="#F0F0F0"/>
            <path d="M265.369 155.32H262.529V158.16H265.369V155.32Z" fill="#F0F0F0"/>
            <path d="M265.369 160.07H262.529V162.91H265.369V160.07Z" fill="#F0F0F0"/>
            <path d="M265.369 164.83H262.529V167.67H265.369V164.83Z" fill="#F0F0F0"/>
            <path d="M265.369 169.58H262.529V172.42H265.369V169.58Z" fill="#F0F0F0"/>
            <path d="M265.369 174.34H262.529V177.18H265.369V174.34Z" fill="#F0F0F0"/>
            <path d="M265.369 188.6H262.529V191.44H265.369V188.6Z" fill="#F0F0F0"/>
            <path d="M265.369 193.35H262.529V196.19H265.369V193.35Z" fill="#F0F0F0"/>
            <path d="M269.879 141.06H267.039V143.9H269.879V141.06Z" fill="#F0F0F0"/>
            <path d="M269.879 145.81H267.039V148.65H269.879V145.81Z" fill="#F0F0F0"/>
            <path d="M269.879 150.57H267.039V153.41H269.879V150.57Z" fill="#F0F0F0"/>
            <path d="M269.879 155.32H267.039V158.16H269.879V155.32Z" fill="#F0F0F0"/>
            <path d="M269.879 160.07H267.039V162.91H269.879V160.07Z" fill="#F0F0F0"/>
            <path d="M269.879 164.83H267.039V167.67H269.879V164.83Z" fill="#F0F0F0"/>
            <path d="M269.879 169.58H267.039V172.42H269.879V169.58Z" fill="#F0F0F0"/>
            <path d="M269.879 174.34H267.039V177.18H269.879V174.34Z" fill="#F0F0F0"/>
            <path d="M269.879 193.35H267.039V196.19H269.879V193.35Z" fill="#F0F0F0"/>
            <path d="M274.379 141.06H271.539V143.9H274.379V141.06Z" fill="#F0F0F0"/>
            <path d="M274.379 145.81H271.539V148.65H274.379V145.81Z" fill="#F0F0F0"/>
            <path d="M274.379 150.57H271.539V153.41H274.379V150.57Z" fill="#F0F0F0"/>
            <path d="M274.379 155.32H271.539V158.16H274.379V155.32Z" fill="#F0F0F0"/>
            <path d="M274.379 160.07H271.539V162.91H274.379V160.07Z" fill="#F0F0F0"/>
            <path d="M274.379 164.83H271.539V167.67H274.379V164.83Z" fill="#F0F0F0"/>
            <path d="M274.379 169.58H271.539V172.42H274.379V169.58Z" fill="#F0F0F0"/>
            <path d="M274.379 174.34H271.539V177.18H274.379V174.34Z" fill="#F0F0F0"/>
            <path d="M278.879 141.06H276.039V143.9H278.879V141.06Z" fill="#F0F0F0"/>
            <path d="M278.879 145.81H276.039V148.65H278.879V145.81Z" fill="#F0F0F0"/>
            <path d="M278.879 150.57H276.039V153.41H278.879V150.57Z" fill="#F0F0F0"/>
            <path d="M278.879 155.32H276.039V158.16H278.879V155.32Z" fill="#F0F0F0"/>
            <path d="M278.879 160.07H276.039V162.91H278.879V160.07Z" fill="#F0F0F0"/>
            <path d="M278.879 164.83H276.039V167.67H278.879V164.83Z" fill="#F0F0F0"/>
            <path d="M278.879 174.34H276.039V177.18H278.879V174.34Z" fill="#F0F0F0"/>
            <path d="M278.879 179.09H276.039V181.93H278.879V179.09Z" fill="#F0F0F0"/>
            <path d="M278.879 193.35H276.039V196.19H278.879V193.35Z" fill="#F0F0F0"/>
            <path d="M278.879 198.109H276.039V200.949H278.879V198.109Z" fill="#F0F0F0"/>
            <path d="M278.879 202.859H276.039V205.699H278.879V202.859Z" fill="#F0F0F0"/>
            <path d="M278.879 207.609H276.039V210.449H278.879V207.609Z" fill="#F0F0F0"/>
            <path d="M278.879 212.37H276.039V215.21H278.879V212.37Z" fill="#F0F0F0"/>
            <path d="M278.879 217.12H276.039V219.96H278.879V217.12Z" fill="#F0F0F0"/>
            <path d="M278.879 221.88H276.039V224.72H278.879V221.88Z" fill="#F0F0F0"/>
            <path d="M283.391 141.06H280.551V143.9H283.391V141.06Z" fill="#F0F0F0"/>
            <path d="M283.391 145.81H280.551V148.65H283.391V145.81Z" fill="#F0F0F0"/>
            <path d="M283.391 150.57H280.551V153.41H283.391V150.57Z" fill="#F0F0F0"/>
            <path d="M283.391 155.32H280.551V158.16H283.391V155.32Z" fill="#F0F0F0"/>
            <path d="M283.391 160.07H280.551V162.91H283.391V160.07Z" fill="#F0F0F0"/>
            <path d="M283.391 164.83H280.551V167.67H283.391V164.83Z" fill="#F0F0F0"/>
            <path d="M283.391 179.09H280.551V181.93H283.391V179.09Z" fill="#F0F0F0"/>
            <path d="M283.391 183.84H280.551V186.68H283.391V183.84Z" fill="#F0F0F0"/>
            <path d="M283.391 188.6H280.551V191.44H283.391V188.6Z" fill="#F0F0F0"/>
            <path d="M283.391 193.35H280.551V196.19H283.391V193.35Z" fill="#F0F0F0"/>
            <path d="M283.391 198.109H280.551V200.949H283.391V198.109Z" fill="#F0F0F0"/>
            <path d="M283.391 202.859H280.551V205.699H283.391V202.859Z" fill="#F0F0F0"/>
            <path d="M283.391 207.609H280.551V210.449H283.391V207.609Z" fill="#F0F0F0"/>
            <path d="M283.391 212.37H280.551V215.21H283.391V212.37Z" fill="#F0F0F0"/>
            <path d="M283.391 217.12H280.551V219.96H283.391V217.12Z" fill="#F0F0F0"/>
            <path d="M283.391 221.88H280.551V224.72H283.391V221.88Z" fill="#F0F0F0"/>
            <path d="M283.391 226.63H280.551V229.47H283.391V226.63Z" fill="#F0F0F0"/>
            <path d="M283.391 236.14H280.551V238.98H283.391V236.14Z" fill="#F0F0F0"/>
            <path d="M287.891 141.06H285.051V143.9H287.891V141.06Z" fill="#F0F0F0"/>
            <path d="M287.891 145.81H285.051V148.65H287.891V145.81Z" fill="#F0F0F0"/>
            <path d="M287.891 150.57H285.051V153.41H287.891V150.57Z" fill="#F0F0F0"/>
            <path d="M287.891 155.32H285.051V158.16H287.891V155.32Z" fill="#F0F0F0"/>
            <path d="M287.891 160.07H285.051V162.91H287.891V160.07Z" fill="#F0F0F0"/>
            <path d="M287.891 164.83H285.051V167.67H287.891V164.83Z" fill="#F0F0F0"/>
            <path d="M287.891 179.09H285.051V181.93H287.891V179.09Z" fill="#F0F0F0"/>
            <path d="M287.891 183.84H285.051V186.68H287.891V183.84Z" fill="#F0F0F0"/>
            <path d="M287.891 188.6H285.051V191.44H287.891V188.6Z" fill="#F0F0F0"/>
            <path d="M287.891 193.35H285.051V196.19H287.891V193.35Z" fill="#F0F0F0"/>
            <path d="M287.891 207.609H285.051V210.449H287.891V207.609Z" fill="#F0F0F0"/>
            <path d="M287.891 212.37H285.051V215.21H287.891V212.37Z" fill="#F0F0F0"/>
            <path d="M287.891 217.12H285.051V219.96H287.891V217.12Z" fill="#F0F0F0"/>
            <path d="M287.891 221.88H285.051V224.72H287.891V221.88Z" fill="#F0F0F0"/>
            <path d="M287.891 226.63H285.051V229.47H287.891V226.63Z" fill="#F0F0F0"/>
            <path d="M287.891 236.14H285.051V238.98H287.891V236.14Z" fill="#F0F0F0"/>
            <path d="M287.891 240.89H285.051V243.73H287.891V240.89Z" fill="#F0F0F0"/>
            <path d="M328.76 185.26H325.92V188.1H328.76V185.26Z" fill="#F0F0F0"/>
            <path d="M328.76 190.02H325.92V192.86H328.76V190.02Z" fill="#F0F0F0"/>
            <path d="M328.76 194.77H325.92V197.61H328.76V194.77Z" fill="#F0F0F0"/>
            <path d="M328.76 199.52H325.92V202.36H328.76V199.52Z" fill="#F0F0F0"/>
            <path d="M328.76 204.28H325.92V207.12H328.76V204.28Z" fill="#F0F0F0"/>
            <path d="M328.76 209.03H325.92V211.87H328.76V209.03Z" fill="#F0F0F0"/>
            <path d="M328.76 213.79H325.92V216.63H328.76V213.79Z" fill="#F0F0F0"/>
            <path d="M328.76 228.05H325.92V230.89H328.76V228.05Z" fill="#F0F0F0"/>
            <path d="M328.76 232.8H325.92V235.64H328.76V232.8Z" fill="#F0F0F0"/>
            <path d="M328.76 237.56H325.92V240.4H328.76V237.56Z" fill="#F0F0F0"/>
            <path d="M333.26 185.26H330.42V188.1H333.26V185.26Z" fill="#F0F0F0"/>
            <path d="M333.26 190.02H330.42V192.86H333.26V190.02Z" fill="#F0F0F0"/>
            <path d="M333.26 194.77H330.42V197.61H333.26V194.77Z" fill="#F0F0F0"/>
            <path d="M333.26 199.52H330.42V202.36H333.26V199.52Z" fill="#F0F0F0"/>
            <path d="M333.26 204.28H330.42V207.12H333.26V204.28Z" fill="#F0F0F0"/>
            <path d="M333.26 209.03H330.42V211.87H333.26V209.03Z" fill="#F0F0F0"/>
            <path d="M333.26 213.79H330.42V216.63H333.26V213.79Z" fill="#F0F0F0"/>
            <path d="M337.77 185.26H334.93V188.1H337.77V185.26Z" fill="#F0F0F0"/>
            <path d="M337.77 190.02H334.93V192.86H337.77V190.02Z" fill="#F0F0F0"/>
            <path d="M337.77 194.77H334.93V197.61H337.77V194.77Z" fill="#F0F0F0"/>
            <path d="M337.77 199.52H334.93V202.36H337.77V199.52Z" fill="#F0F0F0"/>
            <path d="M337.77 204.28H334.93V207.12H337.77V204.28Z" fill="#F0F0F0"/>
            <path d="M337.77 209.03H334.93V211.87H337.77V209.03Z" fill="#F0F0F0"/>
            <path d="M337.77 213.79H334.93V216.63H337.77V213.79Z" fill="#F0F0F0"/>
            <path d="M342.27 185.26H339.43V188.1H342.27V185.26Z" fill="#F0F0F0"/>
            <path d="M342.27 190.02H339.43V192.86H342.27V190.02Z" fill="#F0F0F0"/>
            <path d="M342.27 194.77H339.43V197.61H342.27V194.77Z" fill="#F0F0F0"/>
            <path d="M342.27 199.52H339.43V202.36H342.27V199.52Z" fill="#F0F0F0"/>
            <path d="M342.27 204.28H339.43V207.12H342.27V204.28Z" fill="#F0F0F0"/>
            <path d="M342.27 209.03H339.43V211.87H342.27V209.03Z" fill="#F0F0F0"/>
            <path d="M342.27 213.79H339.43V216.63H342.27V213.79Z" fill="#F0F0F0"/>
            <path d="M342.27 218.54H339.43V221.38H342.27V218.54Z" fill="#F0F0F0"/>
            <path d="M342.27 223.29H339.43V226.13H342.27V223.29Z" fill="#F0F0F0"/>
            <path d="M342.27 228.05H339.43V230.89H342.27V228.05Z" fill="#F0F0F0"/>
            <path d="M342.27 232.8H339.43V235.64H342.27V232.8Z" fill="#F0F0F0"/>
            <path d="M346.77 185.26H343.93V188.1H346.77V185.26Z" fill="#F0F0F0"/>
            <path d="M346.77 190.02H343.93V192.86H346.77V190.02Z" fill="#F0F0F0"/>
            <path d="M346.77 194.77H343.93V197.61H346.77V194.77Z" fill="#F0F0F0"/>
            <path d="M346.77 199.52H343.93V202.36H346.77V199.52Z" fill="#F0F0F0"/>
            <path d="M346.77 204.28H343.93V207.12H346.77V204.28Z" fill="#F0F0F0"/>
            <path d="M346.77 209.03H343.93V211.87H346.77V209.03Z" fill="#F0F0F0"/>
            <path d="M346.77 213.79H343.93V216.63H346.77V213.79Z" fill="#F0F0F0"/>
            <path d="M346.77 218.54H343.93V221.38H346.77V218.54Z" fill="#F0F0F0"/>
            <path d="M346.77 223.29H343.93V226.13H346.77V223.29Z" fill="#F0F0F0"/>
            <path d="M346.77 228.05H343.93V230.89H346.77V228.05Z" fill="#F0F0F0"/>
            <path d="M346.77 232.8H343.93V235.64H346.77V232.8Z" fill="#F0F0F0"/>
            <path d="M346.77 237.56H343.93V240.4H346.77V237.56Z" fill="#F0F0F0"/>
            <path d="M346.77 251.82H343.93V254.66H346.77V251.82Z" fill="#F0F0F0"/>
            <path d="M346.77 256.57H343.93V259.41H346.77V256.57Z" fill="#F0F0F0"/>
            <path d="M351.279 185.26H348.439V188.1H351.279V185.26Z" fill="#F0F0F0"/>
            <path d="M351.279 190.02H348.439V192.86H351.279V190.02Z" fill="#F0F0F0"/>
            <path d="M351.279 194.77H348.439V197.61H351.279V194.77Z" fill="#F0F0F0"/>
            <path d="M351.279 209.03H348.439V211.87H351.279V209.03Z" fill="#F0F0F0"/>
            <path d="M351.279 213.79H348.439V216.63H351.279V213.79Z" fill="#F0F0F0"/>
            <path d="M351.279 218.54H348.439V221.38H351.279V218.54Z" fill="#F0F0F0"/>
            <path d="M351.279 223.29H348.439V226.13H351.279V223.29Z" fill="#F0F0F0"/>
            <path d="M351.279 228.05H348.439V230.89H351.279V228.05Z" fill="#F0F0F0"/>
            <path d="M351.279 232.8H348.439V235.64H351.279V232.8Z" fill="#F0F0F0"/>
            <path d="M351.279 237.56H348.439V240.4H351.279V237.56Z" fill="#F0F0F0"/>
            <path d="M351.279 251.82H348.439V254.66H351.279V251.82Z" fill="#F0F0F0"/>
            <path d="M351.279 256.57H348.439V259.41H351.279V256.57Z" fill="#F0F0F0"/>
            <path d="M351.279 261.33H348.439V264.17H351.279V261.33Z" fill="#F0F0F0"/>
            <path d="M398.49 156.74H395.65V159.58H398.49V156.74Z" fill="#F0F0F0"/>
            <path d="M398.49 161.49H395.65V164.33H398.49V161.49Z" fill="#F0F0F0"/>
            <path d="M398.49 166.25H395.65V169.09H398.49V166.25Z" fill="#F0F0F0"/>
            <path d="M398.49 171H395.65V173.84H398.49V171Z" fill="#F0F0F0"/>
            <path d="M398.49 175.75H395.65V178.59H398.49V175.75Z" fill="#F0F0F0"/>
            <path d="M398.49 180.51H395.65V183.35H398.49V180.51Z" fill="#F0F0F0"/>
            <path d="M398.49 185.26H395.65V188.1H398.49V185.26Z" fill="#F0F0F0"/>
            <path d="M398.49 190.02H395.65V192.86H398.49V190.02Z" fill="#F0F0F0"/>
            <path d="M398.49 194.77H395.65V197.61H398.49V194.77Z" fill="#F0F0F0"/>
            <path d="M398.49 199.52H395.65V202.36H398.49V199.52Z" fill="#F0F0F0"/>
            <path d="M398.49 204.28H395.65V207.12H398.49V204.28Z" fill="#F0F0F0"/>
            <path d="M398.49 209.03H395.65V211.87H398.49V209.03Z" fill="#F0F0F0"/>
            <path d="M398.49 213.79H395.65V216.63H398.49V213.79Z" fill="#F0F0F0"/>
            <path d="M398.49 218.54H395.65V221.38H398.49V218.54Z" fill="#F0F0F0"/>
            <path d="M398.49 223.29H395.65V226.13H398.49V223.29Z" fill="#F0F0F0"/>
            <path d="M398.49 228.05H395.65V230.89H398.49V228.05Z" fill="#F0F0F0"/>
            <path d="M398.49 232.8H395.65V235.64H398.49V232.8Z" fill="#F0F0F0"/>
            <path d="M398.49 237.56H395.65V240.4H398.49V237.56Z" fill="#F0F0F0"/>
            <path d="M398.49 242.31H395.65V245.15H398.49V242.31Z" fill="#F0F0F0"/>
            <path d="M398.49 247.06H395.65V249.9H398.49V247.06Z" fill="#F0F0F0"/>
            <path d="M398.49 251.82H395.65V254.66H398.49V251.82Z" fill="#F0F0F0"/>
            <path d="M398.49 256.57H395.65V259.41H398.49V256.57Z" fill="#F0F0F0"/>
            <path d="M398.49 261.33H395.65V264.17H398.49V261.33Z" fill="#F0F0F0"/>
            <path d="M402.99 156.74H400.15V159.58H402.99V156.74Z" fill="#F0F0F0"/>
            <path d="M402.99 161.49H400.15V164.33H402.99V161.49Z" fill="#F0F0F0"/>
            <path d="M402.99 166.25H400.15V169.09H402.99V166.25Z" fill="#F0F0F0"/>
            <path d="M402.99 171H400.15V173.84H402.99V171Z" fill="#F0F0F0"/>
            <path d="M402.99 175.75H400.15V178.59H402.99V175.75Z" fill="#F0F0F0"/>
            <path d="M402.99 180.51H400.15V183.35H402.99V180.51Z" fill="#F0F0F0"/>
            <path d="M402.99 185.26H400.15V188.1H402.99V185.26Z" fill="#F0F0F0"/>
            <path d="M402.99 190.02H400.15V192.86H402.99V190.02Z" fill="#F0F0F0"/>
            <path d="M402.99 194.77H400.15V197.61H402.99V194.77Z" fill="#F0F0F0"/>
            <path d="M402.99 199.52H400.15V202.36H402.99V199.52Z" fill="#F0F0F0"/>
            <path d="M402.99 204.28H400.15V207.12H402.99V204.28Z" fill="#F0F0F0"/>
            <path d="M402.99 209.03H400.15V211.87H402.99V209.03Z" fill="#F0F0F0"/>
            <path d="M402.99 213.79H400.15V216.63H402.99V213.79Z" fill="#F0F0F0"/>
            <path d="M402.99 218.54H400.15V221.38H402.99V218.54Z" fill="#F0F0F0"/>
            <path d="M402.99 223.29H400.15V226.13H402.99V223.29Z" fill="#F0F0F0"/>
            <path d="M402.99 228.05H400.15V230.89H402.99V228.05Z" fill="#F0F0F0"/>
            <path d="M402.99 232.8H400.15V235.64H402.99V232.8Z" fill="#F0F0F0"/>
            <path d="M402.99 237.56H400.15V240.4H402.99V237.56Z" fill="#F0F0F0"/>
            <path d="M402.99 242.31H400.15V245.15H402.99V242.31Z" fill="#F0F0F0"/>
            <path d="M407.49 156.74H404.65V159.58H407.49V156.74Z" fill="#F0F0F0"/>
            <path d="M407.49 161.49H404.65V164.33H407.49V161.49Z" fill="#F0F0F0"/>
            <path d="M407.49 166.25H404.65V169.09H407.49V166.25Z" fill="#F0F0F0"/>
            <path d="M407.49 171H404.65V173.84H407.49V171Z" fill="#F0F0F0"/>
            <path d="M407.49 175.75H404.65V178.59H407.49V175.75Z" fill="#F0F0F0"/>
            <path d="M407.49 180.51H404.65V183.35H407.49V180.51Z" fill="#F0F0F0"/>
            <path d="M407.49 185.26H404.65V188.1H407.49V185.26Z" fill="#F0F0F0"/>
            <path d="M407.49 190.02H404.65V192.86H407.49V190.02Z" fill="#F0F0F0"/>
            <path d="M407.49 194.77H404.65V197.61H407.49V194.77Z" fill="#F0F0F0"/>
            <path d="M407.49 199.52H404.65V202.36H407.49V199.52Z" fill="#F0F0F0"/>
            <path d="M407.49 204.28H404.65V207.12H407.49V204.28Z" fill="#F0F0F0"/>
            <path d="M407.49 209.03H404.65V211.87H407.49V209.03Z" fill="#F0F0F0"/>
            <path d="M407.49 213.79H404.65V216.63H407.49V213.79Z" fill="#F0F0F0"/>
            <path d="M407.49 218.54H404.65V221.38H407.49V218.54Z" fill="#F0F0F0"/>
            <path d="M407.49 223.29H404.65V226.13H407.49V223.29Z" fill="#F0F0F0"/>
            <path d="M407.49 228.05H404.65V230.89H407.49V228.05Z" fill="#F0F0F0"/>
            <path d="M407.49 232.8H404.65V235.64H407.49V232.8Z" fill="#F0F0F0"/>
            <path d="M407.49 237.56H404.65V240.4H407.49V237.56Z" fill="#F0F0F0"/>
            <path d="M407.49 242.31H404.65V245.15H407.49V242.31Z" fill="#F0F0F0"/>
            <path d="M412 156.74H409.16V159.58H412V156.74Z" fill="#F0F0F0"/>
            <path d="M412 161.49H409.16V164.33H412V161.49Z" fill="#F0F0F0"/>
            <path d="M412 166.25H409.16V169.09H412V166.25Z" fill="#F0F0F0"/>
            <path d="M412 171H409.16V173.84H412V171Z" fill="#F0F0F0"/>
            <path d="M412 175.75H409.16V178.59H412V175.75Z" fill="#F0F0F0"/>
            <path d="M412 180.51H409.16V183.35H412V180.51Z" fill="#F0F0F0"/>
            <path d="M412 185.26H409.16V188.1H412V185.26Z" fill="#F0F0F0"/>
            <path d="M412 190.02H409.16V192.86H412V190.02Z" fill="#F0F0F0"/>
            <path d="M412 194.77H409.16V197.61H412V194.77Z" fill="#F0F0F0"/>
            <path d="M412 199.52H409.16V202.36H412V199.52Z" fill="#F0F0F0"/>
            <path d="M412 204.28H409.16V207.12H412V204.28Z" fill="#F0F0F0"/>
            <path d="M412 209.03H409.16V211.87H412V209.03Z" fill="#F0F0F0"/>
            <path d="M412 213.79H409.16V216.63H412V213.79Z" fill="#F0F0F0"/>
            <path d="M412 218.54H409.16V221.38H412V218.54Z" fill="#F0F0F0"/>
            <path d="M412 223.29H409.16V226.13H412V223.29Z" fill="#F0F0F0"/>
            <path d="M416.5 156.74H413.66V159.58H416.5V156.74Z" fill="#F0F0F0"/>
            <path d="M416.5 161.49H413.66V164.33H416.5V161.49Z" fill="#F0F0F0"/>
            <path d="M416.5 166.25H413.66V169.09H416.5V166.25Z" fill="#F0F0F0"/>
            <path d="M416.5 171H413.66V173.84H416.5V171Z" fill="#F0F0F0"/>
            <path d="M416.5 175.75H413.66V178.59H416.5V175.75Z" fill="#F0F0F0"/>
            <path d="M416.5 180.51H413.66V183.35H416.5V180.51Z" fill="#F0F0F0"/>
            <path d="M416.5 185.26H413.66V188.1H416.5V185.26Z" fill="#F0F0F0"/>
            <path d="M416.5 190.02H413.66V192.86H416.5V190.02Z" fill="#F0F0F0"/>
            <path d="M416.5 194.77H413.66V197.61H416.5V194.77Z" fill="#F0F0F0"/>
            <path d="M416.5 199.52H413.66V202.36H416.5V199.52Z" fill="#F0F0F0"/>
            <path d="M416.5 204.28H413.66V207.12H416.5V204.28Z" fill="#F0F0F0"/>
            <path d="M416.5 209.03H413.66V211.87H416.5V209.03Z" fill="#F0F0F0"/>
            <path d="M416.5 213.79H413.66V216.63H416.5V213.79Z" fill="#F0F0F0"/>
            <path d="M421 156.74H418.16V159.58H421V156.74Z" fill="#F0F0F0"/>
            <path d="M421 161.49H418.16V164.33H421V161.49Z" fill="#F0F0F0"/>
            <path d="M421 166.25H418.16V169.09H421V166.25Z" fill="#F0F0F0"/>
            <path d="M421 171H418.16V173.84H421V171Z" fill="#F0F0F0"/>
            <path d="M421 175.75H418.16V178.59H421V175.75Z" fill="#F0F0F0"/>
            <path d="M421 180.51H418.16V183.35H421V180.51Z" fill="#F0F0F0"/>
            <path d="M421 185.26H418.16V188.1H421V185.26Z" fill="#F0F0F0"/>
            <path d="M421 190.02H418.16V192.86H421V190.02Z" fill="#F0F0F0"/>
            <path d="M421 194.77H418.16V197.61H421V194.77Z" fill="#F0F0F0"/>
            <path d="M421 199.52H418.16V202.36H421V199.52Z" fill="#F0F0F0"/>
            <path d="M421 204.28H418.16V207.12H421V204.28Z" fill="#F0F0F0"/>
            <path d="M421 209.03H418.16V211.87H421V209.03Z" fill="#F0F0F0"/>
            <path d="M90.0607 191.43H87.2207V194.27H90.0607V191.43Z" fill="#F0F0F0"/>
            <path d="M90.0607 196.189H87.2207V199.029H90.0607V196.189Z" fill="#F0F0F0"/>
            <path d="M90.0607 200.939H87.2207V203.779H90.0607V200.939Z" fill="#F0F0F0"/>
            <path d="M90.0607 205.7H87.2207V208.54H90.0607V205.7Z" fill="#F0F0F0"/>
            <path d="M90.0607 210.45H87.2207V213.29H90.0607V210.45Z" fill="#F0F0F0"/>
            <path d="M90.0607 215.2H87.2207V218.04H90.0607V215.2Z" fill="#F0F0F0"/>
            <path d="M90.0607 219.96H87.2207V222.8H90.0607V219.96Z" fill="#F0F0F0"/>
            <path d="M90.0607 224.71H87.2207V227.55H90.0607V224.71Z" fill="#F0F0F0"/>
            <path d="M90.0607 229.47H87.2207V232.31H90.0607V229.47Z" fill="#F0F0F0"/>
            <path d="M90.0607 234.22H87.2207V237.06H90.0607V234.22Z" fill="#F0F0F0"/>
            <path d="M90.0607 238.97H87.2207V241.81H90.0607V238.97Z" fill="#F0F0F0"/>
            <path d="M90.0607 243.729H87.2207V246.569H90.0607V243.729Z" fill="#F0F0F0"/>
            <path d="M90.0607 248.479H87.2207V251.319H90.0607V248.479Z" fill="#F0F0F0"/>
            <path d="M90.0607 253.24H87.2207V256.08H90.0607V253.24Z" fill="#F0F0F0"/>
            <path d="M90.0607 257.99H87.2207V260.83H90.0607V257.99Z" fill="#F0F0F0"/>
            <path d="M90.0607 262.74H87.2207V265.58H90.0607V262.74Z" fill="#F0F0F0"/>
            <path d="M90.0607 267.5H87.2207V270.34H90.0607V267.5Z" fill="#F0F0F0"/>
            <path d="M90.0607 272.25H87.2207V275.09H90.0607V272.25Z" fill="#F0F0F0"/>
            <path d="M90.0607 277.01H87.2207V279.85H90.0607V277.01Z" fill="#F0F0F0"/>
            <path d="M90.0607 281.76H87.2207V284.6H90.0607V281.76Z" fill="#F0F0F0"/>
            <path d="M94.5607 191.43H91.7207V194.27H94.5607V191.43Z" fill="#F0F0F0"/>
            <path d="M94.5607 196.189H91.7207V199.029H94.5607V196.189Z" fill="#F0F0F0"/>
            <path d="M94.5607 200.939H91.7207V203.779H94.5607V200.939Z" fill="#F0F0F0"/>
            <path d="M94.5607 205.7H91.7207V208.54H94.5607V205.7Z" fill="#F0F0F0"/>
            <path d="M94.5607 210.45H91.7207V213.29H94.5607V210.45Z" fill="#F0F0F0"/>
            <path d="M94.5607 215.2H91.7207V218.04H94.5607V215.2Z" fill="#F0F0F0"/>
            <path d="M94.5607 219.96H91.7207V222.8H94.5607V219.96Z" fill="#F0F0F0"/>
            <path d="M94.5607 224.71H91.7207V227.55H94.5607V224.71Z" fill="#F0F0F0"/>
            <path d="M94.5607 229.47H91.7207V232.31H94.5607V229.47Z" fill="#F0F0F0"/>
            <path d="M94.5607 234.22H91.7207V237.06H94.5607V234.22Z" fill="#F0F0F0"/>
            <path d="M94.5607 238.97H91.7207V241.81H94.5607V238.97Z" fill="#F0F0F0"/>
            <path d="M94.5607 243.729H91.7207V246.569H94.5607V243.729Z" fill="#F0F0F0"/>
            <path d="M94.5607 248.479H91.7207V251.319H94.5607V248.479Z" fill="#F0F0F0"/>
            <path d="M94.5607 253.24H91.7207V256.08H94.5607V253.24Z" fill="#F0F0F0"/>
            <path d="M94.5607 257.99H91.7207V260.83H94.5607V257.99Z" fill="#F0F0F0"/>
            <path d="M94.5607 262.74H91.7207V265.58H94.5607V262.74Z" fill="#F0F0F0"/>
            <path d="M94.5607 267.5H91.7207V270.34H94.5607V267.5Z" fill="#F0F0F0"/>
            <path d="M94.5607 272.25H91.7207V275.09H94.5607V272.25Z" fill="#F0F0F0"/>
            <path d="M99.0607 191.43H96.2207V194.27H99.0607V191.43Z" fill="#F0F0F0"/>
            <path d="M99.0607 196.189H96.2207V199.029H99.0607V196.189Z" fill="#F0F0F0"/>
            <path d="M99.0607 200.939H96.2207V203.779H99.0607V200.939Z" fill="#F0F0F0"/>
            <path d="M99.0607 205.7H96.2207V208.54H99.0607V205.7Z" fill="#F0F0F0"/>
            <path d="M99.0607 210.45H96.2207V213.29H99.0607V210.45Z" fill="#F0F0F0"/>
            <path d="M99.0607 215.2H96.2207V218.04H99.0607V215.2Z" fill="#F0F0F0"/>
            <path d="M99.0607 219.96H96.2207V222.8H99.0607V219.96Z" fill="#F0F0F0"/>
            <path d="M99.0607 224.71H96.2207V227.55H99.0607V224.71Z" fill="#F0F0F0"/>
            <path d="M99.0607 229.47H96.2207V232.31H99.0607V229.47Z" fill="#F0F0F0"/>
            <path d="M99.0607 234.22H96.2207V237.06H99.0607V234.22Z" fill="#F0F0F0"/>
            <path d="M99.0607 238.97H96.2207V241.81H99.0607V238.97Z" fill="#F0F0F0"/>
            <path d="M99.0607 243.729H96.2207V246.569H99.0607V243.729Z" fill="#F0F0F0"/>
            <path d="M99.0607 248.479H96.2207V251.319H99.0607V248.479Z" fill="#F0F0F0"/>
            <path d="M99.0607 253.24H96.2207V256.08H99.0607V253.24Z" fill="#F0F0F0"/>
            <path d="M99.0607 257.99H96.2207V260.83H99.0607V257.99Z" fill="#F0F0F0"/>
            <path d="M99.0607 262.74H96.2207V265.58H99.0607V262.74Z" fill="#F0F0F0"/>
            <path d="M99.0607 267.5H96.2207V270.34H99.0607V267.5Z" fill="#F0F0F0"/>
            <path d="M99.0607 272.25H96.2207V275.09H99.0607V272.25Z" fill="#F0F0F0"/>
            <path d="M73.2091 226.13H70.3691V228.97H73.2091V226.13Z" fill="#F0F0F0"/>
            <path d="M73.2091 230.88H70.3691V233.72H73.2091V230.88Z" fill="#F0F0F0"/>
            <path d="M73.2091 235.64H70.3691V238.48H73.2091V235.64Z" fill="#F0F0F0"/>
            <path d="M73.2091 240.39H70.3691V243.23H73.2091V240.39Z" fill="#F0F0F0"/>
            <path d="M73.2091 245.149H70.3691V247.989H73.2091V245.149Z" fill="#F0F0F0"/>
            <path d="M73.2091 249.899H70.3691V252.739H73.2091V249.899Z" fill="#F0F0F0"/>
            <path d="M73.2091 254.649H70.3691V257.489H73.2091V254.649Z" fill="#F0F0F0"/>
            <path d="M73.2091 259.41H70.3691V262.25H73.2091V259.41Z" fill="#F0F0F0"/>
            <path d="M73.2091 264.16H70.3691V267H73.2091V264.16Z" fill="#F0F0F0"/>
            <path d="M73.2091 268.92H70.3691V271.76H73.2091V268.92Z" fill="#F0F0F0"/>
            <path d="M73.2091 273.67H70.3691V276.51H73.2091V273.67Z" fill="#F0F0F0"/>
            <path d="M73.2091 278.42H70.3691V281.26H73.2091V278.42Z" fill="#F0F0F0"/>
            <path d="M73.2091 283.18H70.3691V286.02H73.2091V283.18Z" fill="#F0F0F0"/>
            <path d="M77.7091 226.13H74.8691V228.97H77.7091V226.13Z" fill="#F0F0F0"/>
            <path d="M77.7091 230.88H74.8691V233.72H77.7091V230.88Z" fill="#F0F0F0"/>
            <path d="M77.7091 235.64H74.8691V238.48H77.7091V235.64Z" fill="#F0F0F0"/>
            <path d="M77.7091 240.39H74.8691V243.23H77.7091V240.39Z" fill="#F0F0F0"/>
            <path d="M77.7091 245.149H74.8691V247.989H77.7091V245.149Z" fill="#F0F0F0"/>
            <path d="M77.7091 249.899H74.8691V252.739H77.7091V249.899Z" fill="#F0F0F0"/>
            <path d="M77.7091 254.649H74.8691V257.489H77.7091V254.649Z" fill="#F0F0F0"/>
            <path d="M77.7091 259.41H74.8691V262.25H77.7091V259.41Z" fill="#F0F0F0"/>
            <path d="M77.7091 264.16H74.8691V267H77.7091V264.16Z" fill="#F0F0F0"/>
            <path d="M77.7091 268.92H74.8691V271.76H77.7091V268.92Z" fill="#F0F0F0"/>
            <path d="M77.7091 273.67H74.8691V276.51H77.7091V273.67Z" fill="#F0F0F0"/>
            <path d="M77.7091 278.42H74.8691V281.26H77.7091V278.42Z" fill="#F0F0F0"/>
            <path d="M77.7091 283.18H74.8691V286.02H77.7091V283.18Z" fill="#F0F0F0"/>
            <path d="M82.2209 226.13H79.3809V228.97H82.2209V226.13Z" fill="#F0F0F0"/>
            <path d="M82.2209 230.88H79.3809V233.72H82.2209V230.88Z" fill="#F0F0F0"/>
            <path d="M82.2209 235.64H79.3809V238.48H82.2209V235.64Z" fill="#F0F0F0"/>
            <path d="M82.2209 240.39H79.3809V243.23H82.2209V240.39Z" fill="#F0F0F0"/>
            <path d="M82.2209 245.149H79.3809V247.989H82.2209V245.149Z" fill="#F0F0F0"/>
            <path d="M82.2209 249.899H79.3809V252.739H82.2209V249.899Z" fill="#F0F0F0"/>
            <path d="M82.2209 254.649H79.3809V257.489H82.2209V254.649Z" fill="#F0F0F0"/>
            <path d="M82.2209 259.41H79.3809V262.25H82.2209V259.41Z" fill="#F0F0F0"/>
            <path d="M82.2209 264.16H79.3809V267H82.2209V264.16Z" fill="#F0F0F0"/>
            <path d="M82.2209 268.92H79.3809V271.76H82.2209V268.92Z" fill="#F0F0F0"/>
            <path d="M82.2209 273.67H79.3809V276.51H82.2209V273.67Z" fill="#F0F0F0"/>
            <path d="M82.2209 278.42H79.3809V281.26H82.2209V278.42Z" fill="#F0F0F0"/>
            <path d="M82.2209 283.18H79.3809V286.02H82.2209V283.18Z" fill="#F0F0F0"/>
            <path d="M82.2209 287.93H79.3809V290.77H82.2209V287.93Z" fill="#F0F0F0"/>
            <path d="M107.24 227.55H104.4V230.39H107.24V227.55Z" fill="#F0F0F0"/>
            <path d="M107.24 232.3H104.4V235.14H107.24V232.3Z" fill="#F0F0F0"/>
            <path d="M107.24 237.06H104.4V239.9H107.24V237.06Z" fill="#F0F0F0"/>
            <path d="M107.24 241.81H104.4V244.65H107.24V241.81Z" fill="#F0F0F0"/>
            <path d="M107.24 246.56H104.4V249.4H107.24V246.56Z" fill="#F0F0F0"/>
            <path d="M107.24 251.32H104.4V254.16H107.24V251.32Z" fill="#F0F0F0"/>
            <path d="M107.24 256.07H104.4V258.91H107.24V256.07Z" fill="#F0F0F0"/>
            <path d="M107.24 260.83H104.4V263.67H107.24V260.83Z" fill="#F0F0F0"/>
            <path d="M107.24 265.58H104.4V268.42H107.24V265.58Z" fill="#F0F0F0"/>
            <path d="M107.24 270.33H104.4V273.17H107.24V270.33Z" fill="#F0F0F0"/>
            <path d="M107.24 284.6H104.4V287.44H107.24V284.6Z" fill="#F0F0F0"/>
            <path d="M111.74 227.55H108.9V230.39H111.74V227.55Z" fill="#F0F0F0"/>
            <path d="M111.74 232.3H108.9V235.14H111.74V232.3Z" fill="#F0F0F0"/>
            <path d="M111.74 237.06H108.9V239.9H111.74V237.06Z" fill="#F0F0F0"/>
            <path d="M111.74 241.81H108.9V244.65H111.74V241.81Z" fill="#F0F0F0"/>
            <path d="M111.74 246.56H108.9V249.4H111.74V246.56Z" fill="#F0F0F0"/>
            <path d="M111.74 251.32H108.9V254.16H111.74V251.32Z" fill="#F0F0F0"/>
            <path d="M111.74 256.07H108.9V258.91H111.74V256.07Z" fill="#F0F0F0"/>
            <path d="M111.74 260.83H108.9V263.67H111.74V260.83Z" fill="#F0F0F0"/>
            <path d="M111.74 265.58H108.9V268.42H111.74V265.58Z" fill="#F0F0F0"/>
            <path d="M111.74 279.84H108.9V282.68H111.74V279.84Z" fill="#F0F0F0"/>
            <path d="M111.74 284.6H108.9V287.44H111.74V284.6Z" fill="#F0F0F0"/>
            <path d="M116.24 227.55H113.4V230.39H116.24V227.55Z" fill="#F0F0F0"/>
            <path d="M116.24 232.3H113.4V235.14H116.24V232.3Z" fill="#F0F0F0"/>
            <path d="M116.24 237.06H113.4V239.9H116.24V237.06Z" fill="#F0F0F0"/>
            <path d="M116.24 241.81H113.4V244.65H116.24V241.81Z" fill="#F0F0F0"/>
            <path d="M116.24 246.56H113.4V249.4H116.24V246.56Z" fill="#F0F0F0"/>
            <path d="M116.24 251.32H113.4V254.16H116.24V251.32Z" fill="#F0F0F0"/>
            <path d="M116.24 256.07H113.4V258.91H116.24V256.07Z" fill="#F0F0F0"/>
            <path d="M116.24 260.83H113.4V263.67H116.24V260.83Z" fill="#F0F0F0"/>
            <path d="M116.24 275.09H113.4V277.93H116.24V275.09Z" fill="#F0F0F0"/>
            <path d="M116.24 279.84H113.4V282.68H116.24V279.84Z" fill="#F0F0F0"/>
            <path d="M116.24 284.6H113.4V287.44H116.24V284.6Z" fill="#F0F0F0"/>
            <path d="M158.11 155.32H155.27V158.16H158.11V155.32Z" fill="#F0F0F0"/>
            <path d="M158.11 160.07H155.27V162.91H158.11V160.07Z" fill="#F0F0F0"/>
            <path d="M158.11 164.83H155.27V167.67H158.11V164.83Z" fill="#F0F0F0"/>
            <path d="M158.11 169.58H155.27V172.42H158.11V169.58Z" fill="#F0F0F0"/>
            <path d="M158.11 174.34H155.27V177.18H158.11V174.34Z" fill="#F0F0F0"/>
            <path d="M158.11 179.09H155.27V181.93H158.11V179.09Z" fill="#F0F0F0"/>
            <path d="M158.11 183.84H155.27V186.68H158.11V183.84Z" fill="#F0F0F0"/>
            <path d="M158.11 188.6H155.27V191.44H158.11V188.6Z" fill="#F0F0F0"/>
            <path d="M158.11 193.35H155.27V196.19H158.11V193.35Z" fill="#F0F0F0"/>
            <path d="M158.11 198.109H155.27V200.949H158.11V198.109Z" fill="#F0F0F0"/>
            <path d="M158.11 202.859H155.27V205.699H158.11V202.859Z" fill="#F0F0F0"/>
            <path d="M158.11 207.609H155.27V210.449H158.11V207.609Z" fill="#F0F0F0"/>
            <path d="M158.11 212.37H155.27V215.21H158.11V212.37Z" fill="#F0F0F0"/>
            <path d="M158.11 217.12H155.27V219.96H158.11V217.12Z" fill="#F0F0F0"/>
            <path d="M158.11 221.88H155.27V224.72H158.11V221.88Z" fill="#F0F0F0"/>
            <path d="M158.11 226.63H155.27V229.47H158.11V226.63Z" fill="#F0F0F0"/>
            <path d="M158.11 231.38H155.27V234.22H158.11V231.38Z" fill="#F0F0F0"/>
            <path d="M158.11 240.89H155.27V243.73H158.11V240.89Z" fill="#F0F0F0"/>
            <path d="M158.11 245.649H155.27V248.489H158.11V245.649Z" fill="#F0F0F0"/>
            <path d="M158.11 250.399H155.27V253.239H158.11V250.399Z" fill="#F0F0F0"/>
            <path d="M158.11 255.149H155.27V257.989H158.11V255.149Z" fill="#F0F0F0"/>
            <path d="M158.11 259.91H155.27V262.75H158.11V259.91Z" fill="#F0F0F0"/>
            <path d="M162.619 155.32H159.779V158.16H162.619V155.32Z" fill="#F0F0F0"/>
            <path d="M162.619 160.07H159.779V162.91H162.619V160.07Z" fill="#F0F0F0"/>
            <path d="M162.619 164.83H159.779V167.67H162.619V164.83Z" fill="#F0F0F0"/>
            <path d="M162.619 169.58H159.779V172.42H162.619V169.58Z" fill="#F0F0F0"/>
            <path d="M162.619 174.34H159.779V177.18H162.619V174.34Z" fill="#F0F0F0"/>
            <path d="M162.619 179.09H159.779V181.93H162.619V179.09Z" fill="#F0F0F0"/>
            <path d="M162.619 183.84H159.779V186.68H162.619V183.84Z" fill="#F0F0F0"/>
            <path d="M162.619 188.6H159.779V191.44H162.619V188.6Z" fill="#F0F0F0"/>
            <path d="M162.619 193.35H159.779V196.19H162.619V193.35Z" fill="#F0F0F0"/>
            <path d="M162.619 198.109H159.779V200.949H162.619V198.109Z" fill="#F0F0F0"/>
            <path d="M162.619 202.859H159.779V205.699H162.619V202.859Z" fill="#F0F0F0"/>
            <path d="M162.619 207.609H159.779V210.449H162.619V207.609Z" fill="#F0F0F0"/>
            <path d="M162.619 212.37H159.779V215.21H162.619V212.37Z" fill="#F0F0F0"/>
            <path d="M162.619 217.12H159.779V219.96H162.619V217.12Z" fill="#F0F0F0"/>
            <path d="M162.619 221.88H159.779V224.72H162.619V221.88Z" fill="#F0F0F0"/>
            <path d="M162.619 226.63H159.779V229.47H162.619V226.63Z" fill="#F0F0F0"/>
            <path d="M162.619 255.149H159.779V257.989H162.619V255.149Z" fill="#F0F0F0"/>
            <path d="M162.619 259.91H159.779V262.75H162.619V259.91Z" fill="#F0F0F0"/>
            <path d="M167.119 155.32H164.279V158.16H167.119V155.32Z" fill="#F0F0F0"/>
            <path d="M167.119 160.07H164.279V162.91H167.119V160.07Z" fill="#F0F0F0"/>
            <path d="M167.119 164.83H164.279V167.67H167.119V164.83Z" fill="#F0F0F0"/>
            <path d="M167.119 169.58H164.279V172.42H167.119V169.58Z" fill="#F0F0F0"/>
            <path d="M167.119 174.34H164.279V177.18H167.119V174.34Z" fill="#F0F0F0"/>
            <path d="M167.119 179.09H164.279V181.93H167.119V179.09Z" fill="#F0F0F0"/>
            <path d="M167.119 183.84H164.279V186.68H167.119V183.84Z" fill="#F0F0F0"/>
            <path d="M167.119 188.6H164.279V191.44H167.119V188.6Z" fill="#F0F0F0"/>
            <path d="M167.119 193.35H164.279V196.19H167.119V193.35Z" fill="#F0F0F0"/>
            <path d="M167.119 198.109H164.279V200.949H167.119V198.109Z" fill="#F0F0F0"/>
            <path d="M167.119 202.859H164.279V205.699H167.119V202.859Z" fill="#F0F0F0"/>
            <path d="M167.119 207.609H164.279V210.449H167.119V207.609Z" fill="#F0F0F0"/>
            <path d="M167.119 212.37H164.279V215.21H167.119V212.37Z" fill="#F0F0F0"/>
            <path d="M167.119 217.12H164.279V219.96H167.119V217.12Z" fill="#F0F0F0"/>
            <path d="M167.119 221.88H164.279V224.72H167.119V221.88Z" fill="#F0F0F0"/>
            <path d="M167.119 226.63H164.279V229.47H167.119V226.63Z" fill="#F0F0F0"/>
            <path d="M167.119 236.14H164.279V238.98H167.119V236.14Z" fill="#F0F0F0"/>
            <path d="M167.119 240.89H164.279V243.73H167.119V240.89Z" fill="#F0F0F0"/>
            <path d="M167.119 245.649H164.279V248.489H167.119V245.649Z" fill="#F0F0F0"/>
            <path d="M167.119 250.399H164.279V253.239H167.119V250.399Z" fill="#F0F0F0"/>
            <path d="M167.119 259.91H164.279V262.75H167.119V259.91Z" fill="#F0F0F0"/>
            <path d="M141.77 215.2H138.93V218.04H141.77V215.2Z" fill="#F0F0F0"/>
            <path d="M141.77 219.96H138.93V222.8H141.77V219.96Z" fill="#F0F0F0"/>
            <path d="M141.77 224.71H138.93V227.55H141.77V224.71Z" fill="#F0F0F0"/>
            <path d="M141.77 229.47H138.93V232.31H141.77V229.47Z" fill="#F0F0F0"/>
            <path d="M141.77 234.22H138.93V237.06H141.77V234.22Z" fill="#F0F0F0"/>
            <path d="M141.77 238.97H138.93V241.81H141.77V238.97Z" fill="#F0F0F0"/>
            <path d="M141.77 243.729H138.93V246.569H141.77V243.729Z" fill="#F0F0F0"/>
            <path d="M141.77 253.24H138.93V256.08H141.77V253.24Z" fill="#F0F0F0"/>
            <path d="M141.77 257.99H138.93V260.83H141.77V257.99Z" fill="#F0F0F0"/>
            <path d="M141.77 262.74H138.93V265.58H141.77V262.74Z" fill="#F0F0F0"/>
            <path d="M141.77 267.5H138.93V270.34H141.77V267.5Z" fill="#F0F0F0"/>
            <path d="M141.77 272.25H138.93V275.09H141.77V272.25Z" fill="#F0F0F0"/>
            <path d="M141.77 277.01H138.93V279.85H141.77V277.01Z" fill="#F0F0F0"/>
            <path d="M141.77 281.76H138.93V284.6H141.77V281.76Z" fill="#F0F0F0"/>
            <path d="M141.77 286.51H138.93V289.35H141.77V286.51Z" fill="#F0F0F0"/>
            <path d="M146.27 215.2H143.43V218.04H146.27V215.2Z" fill="#F0F0F0"/>
            <path d="M146.27 219.96H143.43V222.8H146.27V219.96Z" fill="#F0F0F0"/>
            <path d="M146.27 224.71H143.43V227.55H146.27V224.71Z" fill="#F0F0F0"/>
            <path d="M146.27 229.47H143.43V232.31H146.27V229.47Z" fill="#F0F0F0"/>
            <path d="M146.27 234.22H143.43V237.06H146.27V234.22Z" fill="#F0F0F0"/>
            <path d="M146.27 238.97H143.43V241.81H146.27V238.97Z" fill="#F0F0F0"/>
            <path d="M146.27 253.24H143.43V256.08H146.27V253.24Z" fill="#F0F0F0"/>
            <path d="M146.27 257.99H143.43V260.83H146.27V257.99Z" fill="#F0F0F0"/>
            <path d="M146.27 262.74H143.43V265.58H146.27V262.74Z" fill="#F0F0F0"/>
            <path d="M146.27 267.5H143.43V270.34H146.27V267.5Z" fill="#F0F0F0"/>
            <path d="M146.27 272.25H143.43V275.09H146.27V272.25Z" fill="#F0F0F0"/>
            <path d="M146.27 277.01H143.43V279.85H146.27V277.01Z" fill="#F0F0F0"/>
            <path d="M146.27 281.76H143.43V284.6H146.27V281.76Z" fill="#F0F0F0"/>
            <path d="M146.27 286.51H143.43V289.35H146.27V286.51Z" fill="#F0F0F0"/>
            <path d="M150.77 215.2H147.93V218.04H150.77V215.2Z" fill="#F0F0F0"/>
            <path d="M150.77 219.96H147.93V222.8H150.77V219.96Z" fill="#F0F0F0"/>
            <path d="M150.77 224.71H147.93V227.55H150.77V224.71Z" fill="#F0F0F0"/>
            <path d="M150.77 229.47H147.93V232.31H150.77V229.47Z" fill="#F0F0F0"/>
            <path d="M150.77 234.22H147.93V237.06H150.77V234.22Z" fill="#F0F0F0"/>
            <path d="M150.77 238.97H147.93V241.81H150.77V238.97Z" fill="#F0F0F0"/>
            <path d="M150.77 248.479H147.93V251.319H150.77V248.479Z" fill="#F0F0F0"/>
            <path d="M150.77 253.24H147.93V256.08H150.77V253.24Z" fill="#F0F0F0"/>
            <path d="M150.77 257.99H147.93V260.83H150.77V257.99Z" fill="#F0F0F0"/>
            <path d="M150.77 262.74H147.93V265.58H150.77V262.74Z" fill="#F0F0F0"/>
            <path d="M150.77 267.5H147.93V270.34H150.77V267.5Z" fill="#F0F0F0"/>
            <path d="M150.77 272.25H147.93V275.09H150.77V272.25Z" fill="#F0F0F0"/>
            <path d="M150.77 277.01H147.93V279.85H150.77V277.01Z" fill="#F0F0F0"/>
            <path d="M150.77 281.76H147.93V284.6H150.77V281.76Z" fill="#F0F0F0"/>
            <path d="M150.77 286.51H147.93V289.35H150.77V286.51Z" fill="#F0F0F0"/>
            <path d="M245.69 188.6H242.85V191.44H245.69V188.6Z" fill="#F0F0F0"/>
            <path d="M245.69 193.35H242.85V196.19H245.69V193.35Z" fill="#F0F0F0"/>
            <path d="M245.69 198.109H242.85V200.949H245.69V198.109Z" fill="#F0F0F0"/>
            <path d="M245.69 202.859H242.85V205.699H245.69V202.859Z" fill="#F0F0F0"/>
            <path d="M245.69 207.609H242.85V210.449H245.69V207.609Z" fill="#F0F0F0"/>
            <path d="M245.69 212.37H242.85V215.21H245.69V212.37Z" fill="#F0F0F0"/>
            <path d="M245.69 217.12H242.85V219.96H245.69V217.12Z" fill="#F0F0F0"/>
            <path d="M245.69 221.88H242.85V224.72H245.69V221.88Z" fill="#F0F0F0"/>
            <path d="M245.69 226.63H242.85V229.47H245.69V226.63Z" fill="#F0F0F0"/>
            <path d="M245.69 231.38H242.85V234.22H245.69V231.38Z" fill="#F0F0F0"/>
            <path d="M245.69 236.14H242.85V238.98H245.69V236.14Z" fill="#F0F0F0"/>
            <path d="M245.69 240.89H242.85V243.73H245.69V240.89Z" fill="#F0F0F0"/>
            <path d="M245.69 245.649H242.85V248.489H245.69V245.649Z" fill="#F0F0F0"/>
            <path d="M245.69 255.149H242.85V257.989H245.69V255.149Z" fill="#F0F0F0"/>
            <path d="M245.69 259.91H242.85V262.75H245.69V259.91Z" fill="#F0F0F0"/>
            <path d="M245.69 264.66H242.85V267.5H245.69V264.66Z" fill="#F0F0F0"/>
            <path d="M245.69 269.42H242.85V272.26H245.69V269.42Z" fill="#F0F0F0"/>
            <path d="M245.69 278.93H242.85V281.77H245.69V278.93Z" fill="#F0F0F0"/>
            <path d="M245.69 283.68H242.85V286.52H245.69V283.68Z" fill="#F0F0F0"/>
            <path d="M245.69 288.43H242.85V291.27H245.69V288.43Z" fill="#F0F0F0"/>
            <path d="M250.19 188.6H247.35V191.44H250.19V188.6Z" fill="#F0F0F0"/>
            <path d="M250.19 193.35H247.35V196.19H250.19V193.35Z" fill="#F0F0F0"/>
            <path d="M250.19 198.109H247.35V200.949H250.19V198.109Z" fill="#F0F0F0"/>
            <path d="M250.19 202.859H247.35V205.699H250.19V202.859Z" fill="#F0F0F0"/>
            <path d="M250.19 207.609H247.35V210.449H250.19V207.609Z" fill="#F0F0F0"/>
            <path d="M250.19 212.37H247.35V215.21H250.19V212.37Z" fill="#F0F0F0"/>
            <path d="M250.19 217.12H247.35V219.96H250.19V217.12Z" fill="#F0F0F0"/>
            <path d="M250.19 221.88H247.35V224.72H250.19V221.88Z" fill="#F0F0F0"/>
            <path d="M250.19 226.63H247.35V229.47H250.19V226.63Z" fill="#F0F0F0"/>
            <path d="M250.19 231.38H247.35V234.22H250.19V231.38Z" fill="#F0F0F0"/>
            <path d="M250.19 236.14H247.35V238.98H250.19V236.14Z" fill="#F0F0F0"/>
            <path d="M250.19 240.89H247.35V243.73H250.19V240.89Z" fill="#F0F0F0"/>
            <path d="M250.19 245.649H247.35V248.489H250.19V245.649Z" fill="#F0F0F0"/>
            <path d="M250.19 259.91H247.35V262.75H250.19V259.91Z" fill="#F0F0F0"/>
            <path d="M250.19 264.66H247.35V267.5H250.19V264.66Z" fill="#F0F0F0"/>
            <path d="M250.19 274.17H247.35V277.01H250.19V274.17Z" fill="#F0F0F0"/>
            <path d="M250.19 278.93H247.35V281.77H250.19V278.93Z" fill="#F0F0F0"/>
            <path d="M250.19 283.68H247.35V286.52H250.19V283.68Z" fill="#F0F0F0"/>
            <path d="M250.19 288.43H247.35V291.27H250.19V288.43Z" fill="#F0F0F0"/>
            <path d="M254.699 188.6H251.859V191.44H254.699V188.6Z" fill="#F0F0F0"/>
            <path d="M254.699 193.35H251.859V196.19H254.699V193.35Z" fill="#F0F0F0"/>
            <path d="M254.699 198.109H251.859V200.949H254.699V198.109Z" fill="#F0F0F0"/>
            <path d="M254.699 202.859H251.859V205.699H254.699V202.859Z" fill="#F0F0F0"/>
            <path d="M254.699 207.609H251.859V210.449H254.699V207.609Z" fill="#F0F0F0"/>
            <path d="M254.699 212.37H251.859V215.21H254.699V212.37Z" fill="#F0F0F0"/>
            <path d="M254.699 217.12H251.859V219.96H254.699V217.12Z" fill="#F0F0F0"/>
            <path d="M254.699 221.88H251.859V224.72H254.699V221.88Z" fill="#F0F0F0"/>
            <path d="M254.699 226.63H251.859V229.47H254.699V226.63Z" fill="#F0F0F0"/>
            <path d="M254.699 231.38H251.859V234.22H254.699V231.38Z" fill="#F0F0F0"/>
            <path d="M254.699 236.14H251.859V238.98H254.699V236.14Z" fill="#F0F0F0"/>
            <path d="M254.699 240.89H251.859V243.73H254.699V240.89Z" fill="#F0F0F0"/>
            <path d="M254.699 245.649H251.859V248.489H254.699V245.649Z" fill="#F0F0F0"/>
            <path d="M254.699 250.399H251.859V253.239H254.699V250.399Z" fill="#F0F0F0"/>
            <path d="M254.699 259.91H251.859V262.75H254.699V259.91Z" fill="#F0F0F0"/>
            <path d="M254.699 269.42H251.859V272.26H254.699V269.42Z" fill="#F0F0F0"/>
            <path d="M254.699 274.17H251.859V277.01H254.699V274.17Z" fill="#F0F0F0"/>
            <path d="M254.699 278.93H251.859V281.77H254.699V278.93Z" fill="#F0F0F0"/>
            <path d="M254.699 283.68H251.859V286.52H254.699V283.68Z" fill="#F0F0F0"/>
            <path d="M254.699 288.43H251.859V291.27H254.699V288.43Z" fill="#F0F0F0"/>
            <path d="M227.34 215.7H224.5V218.54H227.34V215.7Z" fill="#F0F0F0"/>
            <path d="M227.34 220.46H224.5V223.3H227.34V220.46Z" fill="#F0F0F0"/>
            <path d="M227.34 225.21H224.5V228.05H227.34V225.21Z" fill="#F0F0F0"/>
            <path d="M227.34 229.97H224.5V232.81H227.34V229.97Z" fill="#F0F0F0"/>
            <path d="M227.34 234.72H224.5V237.56H227.34V234.72Z" fill="#F0F0F0"/>
            <path d="M227.34 239.479H224.5V242.319H227.34V239.479Z" fill="#F0F0F0"/>
            <path d="M227.34 253.74H224.5V256.58H227.34V253.74Z" fill="#F0F0F0"/>
            <path d="M227.34 258.49H224.5V261.33H227.34V258.49Z" fill="#F0F0F0"/>
            <path d="M227.34 263.25H224.5V266.09H227.34V263.25Z" fill="#F0F0F0"/>
            <path d="M227.34 268H224.5V270.84H227.34V268Z" fill="#F0F0F0"/>
            <path d="M227.34 272.75H224.5V275.59H227.34V272.75Z" fill="#F0F0F0"/>
            <path d="M227.34 277.51H224.5V280.35H227.34V277.51Z" fill="#F0F0F0"/>
            <path d="M227.34 282.26H224.5V285.1H227.34V282.26Z" fill="#F0F0F0"/>
            <path d="M231.84 215.7H229V218.54H231.84V215.7Z" fill="#F0F0F0"/>
            <path d="M231.84 220.46H229V223.3H231.84V220.46Z" fill="#F0F0F0"/>
            <path d="M231.84 225.21H229V228.05H231.84V225.21Z" fill="#F0F0F0"/>
            <path d="M231.84 229.97H229V232.81H231.84V229.97Z" fill="#F0F0F0"/>
            <path d="M231.84 234.72H229V237.56H231.84V234.72Z" fill="#F0F0F0"/>
            <path d="M231.84 239.479H229V242.319H231.84V239.479Z" fill="#F0F0F0"/>
            <path d="M231.84 253.74H229V256.58H231.84V253.74Z" fill="#F0F0F0"/>
            <path d="M231.84 258.49H229V261.33H231.84V258.49Z" fill="#F0F0F0"/>
            <path d="M231.84 263.25H229V266.09H231.84V263.25Z" fill="#F0F0F0"/>
            <path d="M231.84 268H229V270.84H231.84V268Z" fill="#F0F0F0"/>
            <path d="M231.84 272.75H229V275.59H231.84V272.75Z" fill="#F0F0F0"/>
            <path d="M231.84 277.51H229V280.35H231.84V277.51Z" fill="#F0F0F0"/>
            <path d="M231.84 287.02H229V289.86H231.84V287.02Z" fill="#F0F0F0"/>
            <path d="M381.471 194.77H378.631V197.61H381.471V194.77Z" fill="#F0F0F0"/>
            <path d="M381.471 199.52H378.631V202.36H381.471V199.52Z" fill="#F0F0F0"/>
            <path d="M381.471 204.28H378.631V207.12H381.471V204.28Z" fill="#F0F0F0"/>
            <path d="M381.471 209.03H378.631V211.87H381.471V209.03Z" fill="#F0F0F0"/>
            <path d="M381.471 213.79H378.631V216.63H381.471V213.79Z" fill="#F0F0F0"/>
            <path d="M381.471 218.54H378.631V221.38H381.471V218.54Z" fill="#F0F0F0"/>
            <path d="M381.471 223.29H378.631V226.13H381.471V223.29Z" fill="#F0F0F0"/>
            <path d="M381.471 228.05H378.631V230.89H381.471V228.05Z" fill="#F0F0F0"/>
            <path d="M381.471 232.8H378.631V235.64H381.471V232.8Z" fill="#F0F0F0"/>
            <path d="M381.471 237.56H378.631V240.4H381.471V237.56Z" fill="#F0F0F0"/>
            <path d="M381.471 242.31H378.631V245.15H381.471V242.31Z" fill="#F0F0F0"/>
            <path d="M381.471 251.82H378.631V254.66H381.471V251.82Z" fill="#F0F0F0"/>
            <path d="M381.471 256.57H378.631V259.41H381.471V256.57Z" fill="#F0F0F0"/>
            <path d="M381.471 261.33H378.631V264.17H381.471V261.33Z" fill="#F0F0F0"/>
            <path d="M381.471 266.08H378.631V268.92H381.471V266.08Z" fill="#F0F0F0"/>
            <path d="M381.471 270.84H378.631V273.68H381.471V270.84Z" fill="#F0F0F0"/>
            <path d="M381.471 275.59H378.631V278.43H381.471V275.59Z" fill="#F0F0F0"/>
            <path d="M381.471 280.34H378.631V283.18H381.471V280.34Z" fill="#F0F0F0"/>
            <path d="M381.471 285.1H378.631V287.94H381.471V285.1Z" fill="#F0F0F0"/>
            <path d="M385.971 194.77H383.131V197.61H385.971V194.77Z" fill="#F0F0F0"/>
            <path d="M385.971 199.52H383.131V202.36H385.971V199.52Z" fill="#F0F0F0"/>
            <path d="M385.971 204.28H383.131V207.12H385.971V204.28Z" fill="#F0F0F0"/>
            <path d="M385.971 209.03H383.131V211.87H385.971V209.03Z" fill="#F0F0F0"/>
            <path d="M385.971 213.79H383.131V216.63H385.971V213.79Z" fill="#F0F0F0"/>
            <path d="M385.971 218.54H383.131V221.38H385.971V218.54Z" fill="#F0F0F0"/>
            <path d="M385.971 223.29H383.131V226.13H385.971V223.29Z" fill="#F0F0F0"/>
            <path d="M385.971 228.05H383.131V230.89H385.971V228.05Z" fill="#F0F0F0"/>
            <path d="M385.971 232.8H383.131V235.64H385.971V232.8Z" fill="#F0F0F0"/>
            <path d="M385.971 237.56H383.131V240.4H385.971V237.56Z" fill="#F0F0F0"/>
            <path d="M385.971 242.31H383.131V245.15H385.971V242.31Z" fill="#F0F0F0"/>
            <path d="M385.971 247.06H383.131V249.9H385.971V247.06Z" fill="#F0F0F0"/>
            <path d="M385.971 251.82H383.131V254.66H385.971V251.82Z" fill="#F0F0F0"/>
            <path d="M385.971 256.57H383.131V259.41H385.971V256.57Z" fill="#F0F0F0"/>
            <path d="M385.971 261.33H383.131V264.17H385.971V261.33Z" fill="#F0F0F0"/>
            <path d="M385.971 266.08H383.131V268.92H385.971V266.08Z" fill="#F0F0F0"/>
            <path d="M385.971 270.84H383.131V273.68H385.971V270.84Z" fill="#F0F0F0"/>
            <path d="M385.971 275.59H383.131V278.43H385.971V275.59Z" fill="#F0F0F0"/>
            <path d="M385.971 280.34H383.131V283.18H385.971V280.34Z" fill="#F0F0F0"/>
            <path d="M385.971 285.1H383.131V287.94H385.971V285.1Z" fill="#F0F0F0"/>
            <path d="M359.289 213.29H356.449V216.13H359.289V213.29Z" fill="#F0F0F0"/>
            <path d="M359.289 218.04H356.449V220.88H359.289V218.04Z" fill="#F0F0F0"/>
            <path d="M359.289 222.79H356.449V225.63H359.289V222.79Z" fill="#F0F0F0"/>
            <path d="M359.289 227.55H356.449V230.39H359.289V227.55Z" fill="#F0F0F0"/>
            <path d="M359.289 232.3H356.449V235.14H359.289V232.3Z" fill="#F0F0F0"/>
            <path d="M359.289 237.06H356.449V239.9H359.289V237.06Z" fill="#F0F0F0"/>
            <path d="M359.289 241.81H356.449V244.65H359.289V241.81Z" fill="#F0F0F0"/>
            <path d="M359.289 246.56H356.449V249.4H359.289V246.56Z" fill="#F0F0F0"/>
            <path d="M359.289 251.32H356.449V254.16H359.289V251.32Z" fill="#F0F0F0"/>
            <path d="M359.289 256.07H356.449V258.91H359.289V256.07Z" fill="#F0F0F0"/>
            <path d="M363.789 213.29H360.949V216.13H363.789V213.29Z" fill="#F0F0F0"/>
            <path d="M363.789 218.04H360.949V220.88H363.789V218.04Z" fill="#F0F0F0"/>
            <path d="M363.789 222.79H360.949V225.63H363.789V222.79Z" fill="#F0F0F0"/>
            <path d="M363.789 227.55H360.949V230.39H363.789V227.55Z" fill="#F0F0F0"/>
            <path d="M363.789 232.3H360.949V235.14H363.789V232.3Z" fill="#F0F0F0"/>
            <path d="M363.789 237.06H360.949V239.9H363.789V237.06Z" fill="#F0F0F0"/>
            <path d="M363.789 241.81H360.949V244.65H363.789V241.81Z" fill="#F0F0F0"/>
            <path d="M363.789 246.56H360.949V249.4H363.789V246.56Z" fill="#F0F0F0"/>
            <path d="M363.789 251.32H360.949V254.16H363.789V251.32Z" fill="#F0F0F0"/>
            <path d="M137 95.6498C137.005 94.2739 136.687 92.916 136.071 91.6855C135.455 90.455 134.559 89.3863 133.455 88.5652C132.351 87.7442 131.07 87.1938 129.714 86.9584C128.358 86.723 126.966 86.8091 125.65 87.2098C126.082 84.2677 125.805 81.265 124.842 78.4517C123.879 75.6383 122.257 73.0958 120.113 71.0356C117.969 68.9754 115.363 67.4572 112.514 66.6074C109.664 65.7575 106.653 65.6007 103.73 66.1498C101.89 63.1254 99.1157 60.7814 95.8266 59.4716C92.5374 58.1617 88.9115 57.9569 85.4957 58.8882C82.08 59.8194 79.0597 61.8362 76.8905 64.6342C74.7213 67.4323 73.5208 70.8598 73.4702 74.3998H73.2002C69.2113 74.3998 65.3858 75.9844 62.5653 78.805C59.7447 81.6255 58.1602 85.451 58.1602 89.4398C58.1602 93.4287 59.7447 97.2542 62.5653 100.075C65.3858 102.895 69.2113 104.48 73.2002 104.48H128.44C130.737 104.415 132.917 103.455 134.516 101.806C136.115 100.156 137.006 97.9472 137 95.6498Z" fill="#F0F0F0"/>
            <path d="M369.66 76.2596C369.66 74.7664 370.253 73.3344 371.309 72.2786C372.365 71.2228 373.797 70.6296 375.29 70.6296C375.835 70.6289 376.377 70.7064 376.9 70.8596C376.82 70.2898 376.78 69.7151 376.78 69.1396C376.779 67.5734 377.086 66.0223 377.685 64.575C378.283 63.1276 379.161 61.8124 380.268 60.7044C381.375 59.5965 382.689 58.7176 384.136 58.1179C385.583 57.5183 387.134 57.2096 388.7 57.2096C389.435 57.2131 390.168 57.2834 390.89 57.4196C392.065 55.4886 393.837 53.9921 395.937 53.156C398.037 52.3199 400.352 52.1894 402.533 52.7843C404.714 53.3792 406.642 54.6671 408.027 56.4539C409.412 58.2407 410.178 60.4293 410.21 62.6896H410.39C412.936 62.6896 415.378 63.701 417.178 65.5014C418.979 67.3017 419.99 69.7435 419.99 72.2896C419.99 74.8357 418.979 77.2775 417.178 79.0779C415.378 80.8782 412.936 81.8896 410.39 81.8896H375.13C373.665 81.848 372.274 81.2365 371.252 80.1851C370.231 79.1338 369.66 77.7255 369.66 76.2596Z" fill="#F0F0F0"/>
            <path d="M257.539 15.5098C257.539 15.0559 257.629 14.6065 257.803 14.1872C257.977 13.768 258.232 13.3872 258.553 13.0668C258.874 12.7463 259.256 12.4923 259.676 12.3196C260.095 12.1468 260.545 12.0585 260.999 12.0598C261.338 12.0624 261.674 12.1129 261.999 12.2098C261.947 11.8588 261.92 11.5046 261.919 11.1498C261.919 9.21373 262.688 7.35695 264.057 5.98793C265.426 4.61892 267.283 3.84981 269.219 3.84981C269.672 3.84751 270.125 3.89107 270.569 3.97981C271.286 2.79293 272.371 1.87203 273.658 1.35655C274.945 0.841081 276.366 0.759105 277.704 1.12303C279.042 1.48695 280.225 2.27695 281.074 3.37344C281.923 4.46992 282.392 5.81317 282.409 7.19981H282.509C284.069 7.19981 285.564 7.81931 286.667 8.92202C287.77 10.0247 288.389 11.5203 288.389 13.0798C288.389 14.6393 287.77 16.1349 286.667 17.2376C285.564 18.3403 284.069 18.9598 282.509 18.9598H260.909C260.009 18.9364 259.153 18.5629 258.524 17.9187C257.895 17.2745 257.541 16.4103 257.539 15.5098Z" fill="#F0F0F0"/>
            <path d="M249.999 347.56C357.082 347.56 443.889 342.492 443.889 336.24C443.889 329.988 357.082 324.92 249.999 324.92C142.917 324.92 56.1094 329.988 56.1094 336.24C56.1094 342.492 142.917 347.56 249.999 347.56Z" fill="#F5F5F5"/>
            <path d="M70.9994 303.37C70.9994 303.37 66.3094 287.63 75.8094 284.5C85.3094 281.37 90.8894 274 90.5794 265.69C90.2694 257.38 100.259 249.27 107.759 257.07C115.259 264.87 114.259 251.07 124.259 247.72C134.259 244.37 142.729 252.5 139.999 259.93C137.269 267.36 147.129 272.01 158.939 265.69C170.749 259.37 183.759 270.87 181.259 279.87C178.759 288.87 180.799 289.87 188.259 286.87C195.719 283.87 209.109 292.37 204.419 303.37C199.729 314.37 70.9994 303.37 70.9994 303.37Z" fill="#215A9D"/>
            <path opacity="0.3" d="M70.9994 303.37C70.9994 303.37 66.3094 287.63 75.8094 284.5C85.3094 281.37 90.8894 274 90.5794 265.69C90.2694 257.38 100.259 249.27 107.759 257.07C115.259 264.87 114.259 251.07 124.259 247.72C134.259 244.37 142.729 252.5 139.999 259.93C137.269 267.36 147.129 272.01 158.939 265.69C170.749 259.37 183.759 270.87 181.259 279.87C178.759 288.87 180.799 289.87 188.259 286.87C195.719 283.87 209.109 292.37 204.419 303.37C199.729 314.37 70.9994 303.37 70.9994 303.37Z" fill="white"/>
            <path d="M322.259 304.869C317.259 299.869 321.759 291.369 326.759 290.369C331.759 289.369 336.759 280.569 337.019 274.969C337.279 269.369 346.259 252.139 360.259 262.779C374.259 273.419 369.999 264.339 380.129 261.359C390.259 258.379 396.649 266.969 392.199 273.419C392.199 273.419 402.379 270.869 406.569 280.369C410.759 289.869 406.569 295.769 406.569 295.769C406.569 295.769 425.699 295.199 423.999 306.999C422.299 318.799 322.259 304.869 322.259 304.869Z" fill="#215A9D"/>
            <path opacity="0.3" d="M322.259 304.869C317.259 299.869 321.759 291.369 326.759 290.369C331.759 289.369 336.759 280.569 337.019 274.969C337.279 269.369 346.259 252.139 360.259 262.779C374.259 273.419 369.999 264.339 380.129 261.359C390.259 258.379 396.649 266.969 392.199 273.419C392.199 273.419 402.379 270.869 406.569 280.369C410.759 289.869 406.569 295.769 406.569 295.769C406.569 295.769 425.699 295.199 423.999 306.999C422.299 318.799 322.259 304.869 322.259 304.869Z" fill="white"/>
            <path d="M53.7598 305.169V318.869H439.76V305.169C439.789 304.622 439.709 304.074 439.522 303.559C439.335 303.043 439.046 302.571 438.673 302.17C438.3 301.768 437.849 301.446 437.349 301.223C436.848 300.999 436.308 300.879 435.76 300.869H57.7999C57.2484 300.874 56.7036 300.99 56.1984 301.211C55.6932 301.432 55.238 301.753 54.8604 302.155C54.4828 302.557 54.1906 303.031 54.0014 303.549C53.8123 304.068 53.7301 304.618 53.7598 305.169Z" fill="#263238"/>
            <path d="M417.76 300.869H393.76V318.869H417.76V300.869Z" fill="#215A9D"/>
            <path d="M371.76 300.869H348.76V318.869H371.76V300.869Z" fill="#215A9D"/>
            <path d="M326.76 300.869H302.76V318.869H326.76V300.869Z" fill="#215A9D"/>
            <path d="M280.76 300.869H257.76V318.869H280.76V300.869Z" fill="#215A9D"/>
            <path d="M235.76 300.869H212.76V318.869H235.76V300.869Z" fill="#215A9D"/>
            <path d="M190.76 300.869H166.76V318.869H190.76V300.869Z" fill="#215A9D"/>
            <path d="M144.76 300.869H121.76V318.869H144.76V300.869Z" fill="#215A9D"/>
            <path d="M99.7598 300.869H75.7598V318.869H99.7598V300.869Z" fill="#215A9D"/>
            <g opacity="0.6">
            <path d="M417.76 300.869H393.76V318.869H417.76V300.869Z" fill="white"/>
            <path d="M371.76 300.869H348.76V318.869H371.76V300.869Z" fill="white"/>
            <path d="M326.76 300.869H302.76V318.869H326.76V300.869Z" fill="white"/>
            <path d="M280.76 300.869H257.76V318.869H280.76V300.869Z" fill="white"/>
            <path d="M235.76 300.869H212.76V318.869H235.76V300.869Z" fill="white"/>
            <path d="M190.76 300.869H166.76V318.869H190.76V300.869Z" fill="white"/>
            <path d="M144.76 300.869H121.76V318.869H144.76V300.869Z" fill="white"/>
            <path d="M99.7598 300.869H75.7598V318.869H99.7598V300.869Z" fill="white"/>
            </g>
            <path opacity="0.1" d="M53.7598 305.169V318.869H439.76V305.169C439.789 304.622 439.709 304.074 439.522 303.559C439.335 303.043 439.046 302.571 438.673 302.17C438.3 301.768 437.849 301.446 437.349 301.223C436.848 300.999 436.308 300.879 435.76 300.869H57.7999C57.2484 300.874 56.7036 300.99 56.1984 301.211C55.6932 301.432 55.238 301.753 54.8604 302.155C54.4828 302.557 54.1906 303.031 54.0014 303.549C53.8123 304.068 53.7301 304.618 53.7598 305.169Z" fill="white"/>
            <path d="M263.331 25.5596H110.531C104.787 25.5596 100.131 30.2158 100.131 35.9596V327.41C100.131 333.153 104.787 337.81 110.531 337.81H263.331C269.075 337.81 273.731 333.153 273.731 327.41V35.9596C273.731 30.2158 269.075 25.5596 263.331 25.5596Z" fill="#215A9D"/>
            <path opacity="0.4" d="M263.331 25.5596H110.531C104.787 25.5596 100.131 30.2158 100.131 35.9596V327.41C100.131 333.153 104.787 337.81 110.531 337.81H263.331C269.075 337.81 273.731 333.153 273.731 327.41V35.9596C273.731 30.2158 269.075 25.5596 263.331 25.5596Z" fill="white"/>
            <path d="M267.881 35.9996V326.79C267.88 327.353 267.766 327.911 267.548 328.431C267.33 328.95 267.01 329.421 266.608 329.817C266.207 330.212 265.73 330.524 265.207 330.734C264.684 330.944 264.124 331.048 263.561 331.04H109.841C109.329 331.045 108.822 330.948 108.349 330.753C107.876 330.558 107.448 330.27 107.088 329.906C106.729 329.543 106.447 329.11 106.258 328.635C106.07 328.159 105.979 327.651 105.991 327.14V35.6696C105.956 34.7588 106.283 33.8712 106.902 33.2018C107.521 32.5325 108.38 32.136 109.291 32.0996H147.721C148.259 32.1102 148.782 32.2803 149.223 32.5882C149.665 32.8962 150.005 33.3282 150.201 33.8296L151.791 37.7496C151.987 38.2472 152.326 38.6754 152.766 38.9799C153.206 39.2843 153.726 39.4513 154.261 39.4596H219.691C220.223 39.4577 220.743 39.2967 221.183 38.9974C221.623 38.698 221.963 38.2739 222.161 37.7796L223.751 33.8396C223.946 33.3371 224.286 32.9037 224.727 32.594C225.169 32.2844 225.692 32.1123 226.231 32.0996H264.101C265.115 32.1283 266.078 32.5521 266.784 33.2807C267.49 34.0093 267.884 34.985 267.881 35.9996Z" fill="#263238"/>
            <path opacity="0.3" d="M267.88 50.2695H105.99V311.77H267.88V50.2695Z" fill="white"/>
            <path opacity="0.6" d="M123.83 43.8193H125.83V41.1493H123.83V43.8193Z" fill="white"/>
            <path opacity="0.6" d="M120.57 43.8193H122.57V40.2593H120.57V43.8193Z" fill="white"/>
            <path opacity="0.6" d="M117.32 43.8193H119.32V39.3793H117.32V43.8193Z" fill="white"/>
            <path opacity="0.6" d="M114.061 43.8193H116.061V38.4893H114.061V43.8193Z" fill="white"/>
            <path opacity="0.6" d="M249.551 40.1797H248.371V42.8497H249.551V40.1797Z" fill="white"/>
            <path opacity="0.6" d="M250.371 43.8193H259.811V39.2093H250.371V43.8193Z" fill="white"/>
            <path d="M263.88 55.3994H109.98V81.7694H263.88V55.3994Z" fill="#215A9D"/>
            <path opacity="0.8" d="M263.88 84.0293H109.98V308.069H263.88V84.0293Z" fill="white"/>
            <g opacity="0.1">
            <path d="M259.811 261.06H114.051C113.918 261.06 113.791 261.007 113.697 260.913C113.603 260.819 113.551 260.692 113.551 260.56C113.551 260.427 113.603 260.3 113.697 260.206C113.791 260.112 113.918 260.06 114.051 260.06H259.811C259.943 260.06 260.071 260.112 260.164 260.206C260.258 260.3 260.311 260.427 260.311 260.56C260.311 260.692 260.258 260.819 260.164 260.913C260.071 261.007 259.943 261.06 259.811 261.06Z" fill="black"/>
            </g>
            <path opacity="0.3" d="M149.001 244.05H123.461C123.146 244.05 122.843 243.925 122.62 243.701C122.397 243.478 122.271 243.176 122.271 242.86C122.271 242.544 122.397 242.242 122.62 242.018C122.843 241.795 123.146 241.67 123.461 241.67H149.001C149.317 241.67 149.62 241.795 149.843 242.018C150.066 242.242 150.191 242.544 150.191 242.86C150.191 243.176 150.066 243.478 149.843 243.701C149.62 243.925 149.317 244.05 149.001 244.05Z" fill="black"/>
            <path opacity="0.3" d="M179.001 249.18H123.461C123.306 249.181 123.151 249.151 123.007 249.093C122.863 249.034 122.731 248.947 122.621 248.838C122.51 248.728 122.422 248.597 122.362 248.453C122.302 248.31 122.271 248.155 122.271 248C122.271 247.684 122.397 247.381 122.62 247.158C122.843 246.935 123.146 246.81 123.461 246.81H179.001C179.317 246.81 179.62 246.935 179.843 247.158C180.066 247.381 180.191 247.684 180.191 248C180.191 248.155 180.161 248.31 180.101 248.453C180.041 248.597 179.953 248.728 179.842 248.838C179.732 248.947 179.6 249.034 179.456 249.093C179.312 249.151 179.157 249.181 179.001 249.18Z" fill="black"/>
            <g opacity="0.1">
            <path d="M259.811 290.26H114.051C113.918 290.26 113.791 290.207 113.697 290.113C113.603 290.02 113.551 289.892 113.551 289.76C113.553 289.628 113.607 289.502 113.7 289.409C113.793 289.316 113.919 289.262 114.051 289.26H259.811C259.943 289.26 260.071 289.312 260.164 289.406C260.258 289.5 260.311 289.627 260.311 289.76C260.311 289.892 260.258 290.02 260.164 290.113C260.071 290.207 259.943 290.26 259.811 290.26Z" fill="black"/>
            </g>
            <path opacity="0.3" d="M149.001 273.25H123.461C123.306 273.251 123.151 273.222 123.007 273.163C122.863 273.104 122.731 273.018 122.621 272.908C122.51 272.798 122.422 272.668 122.362 272.524C122.302 272.38 122.271 272.226 122.271 272.07C122.271 271.754 122.397 271.452 122.62 271.228C122.843 271.005 123.146 270.88 123.461 270.88H149.001C149.317 270.88 149.62 271.005 149.843 271.228C150.066 271.452 150.191 271.754 150.191 272.07C150.191 272.226 150.161 272.38 150.101 272.524C150.041 272.668 149.953 272.798 149.842 272.908C149.732 273.018 149.6 273.104 149.456 273.163C149.312 273.222 149.157 273.251 149.001 273.25Z" fill="black"/>
            <path opacity="0.3" d="M179.001 278.39H123.461C123.146 278.39 122.843 278.264 122.62 278.041C122.397 277.818 122.271 277.515 122.271 277.2C122.271 276.884 122.397 276.581 122.62 276.358C122.843 276.135 123.146 276.01 123.461 276.01H179.001C179.317 276.01 179.62 276.135 179.843 276.358C180.066 276.581 180.191 276.884 180.191 277.2C180.191 277.515 180.066 277.818 179.843 278.041C179.62 278.264 179.317 278.39 179.001 278.39Z" fill="black"/>
            <path d="M188.211 323.939H185.661C185.014 323.937 184.395 323.679 183.938 323.222C183.481 322.765 183.223 322.146 183.221 321.499V318.999C183.223 318.354 183.482 317.736 183.939 317.28C184.396 316.825 185.015 316.569 185.661 316.569H188.211C188.855 316.569 189.473 316.825 189.929 317.281C190.385 317.737 190.641 318.355 190.641 318.999V321.549C190.628 322.186 190.366 322.792 189.912 323.239C189.458 323.686 188.848 323.937 188.211 323.939ZM185.661 317.519C185.281 317.519 184.916 317.67 184.646 317.938C184.376 318.205 184.223 318.569 184.221 318.949V321.499C184.223 321.88 184.376 322.245 184.645 322.515C184.915 322.784 185.28 322.937 185.661 322.939H188.211C188.591 322.937 188.955 322.784 189.222 322.514C189.49 322.244 189.641 321.88 189.641 321.499V318.999C189.641 318.62 189.49 318.256 189.222 317.988C188.954 317.72 188.59 317.569 188.211 317.569L185.661 317.519Z" fill="white"/>
            <path opacity="0.6" d="M186.911 32.4895C186.913 32.931 186.784 33.3631 186.54 33.7312C186.296 34.0992 185.949 34.3866 185.541 34.5569C185.134 34.7272 184.686 34.7728 184.252 34.6879C183.819 34.603 183.421 34.3914 183.108 34.0799C182.795 33.7685 182.582 33.3711 182.495 32.9383C182.408 32.5054 182.451 32.0565 182.62 31.6485C182.788 31.2404 183.074 30.8915 183.441 30.6461C183.808 30.4006 184.24 30.2695 184.681 30.2695C185.271 30.2695 185.837 30.5031 186.254 30.9191C186.672 31.3352 186.909 31.8998 186.911 32.4895Z" fill="black"/>
            <path opacity="0.6" d="M191.401 32.4896C191.399 32.77 191.314 33.0435 191.156 33.2757C190.999 33.5079 190.777 33.6884 190.517 33.7943C190.257 33.9003 189.972 33.927 189.697 33.8711C189.423 33.8151 189.171 33.6791 188.973 33.4801C188.775 33.2812 188.641 33.0281 188.587 32.7529C188.533 32.4778 188.562 32.1928 188.67 31.9339C188.777 31.675 188.959 31.4538 189.193 31.2982C189.426 31.1426 189.7 31.0596 189.981 31.0596C190.168 31.0596 190.353 31.0966 190.526 31.1686C190.699 31.2406 190.856 31.3461 190.988 31.479C191.12 31.6119 191.225 31.7697 191.295 31.9431C191.366 32.1165 191.402 32.3023 191.401 32.4896Z" fill="black"/>
            <path d="M186.93 195.579C211.844 195.579 232.04 175.383 232.04 150.469C232.04 125.556 211.844 105.359 186.93 105.359C162.017 105.359 141.82 125.556 141.82 150.469C141.82 175.383 162.017 195.579 186.93 195.579Z" fill="#263238"/>
            <path d="M161.31 146.789C160.845 146.792 160.39 146.656 160.003 146.399C159.616 146.141 159.315 145.773 159.139 145.343C158.963 144.913 158.919 144.44 159.014 143.985C159.108 143.53 159.337 143.113 159.67 142.789C166.905 135.566 176.711 131.509 186.935 131.509C197.159 131.509 206.965 135.566 214.2 142.789C214.417 143.006 214.589 143.263 214.706 143.546C214.823 143.829 214.884 144.133 214.884 144.439C214.884 144.746 214.823 145.049 214.706 145.332C214.589 145.615 214.417 145.873 214.2 146.089C213.983 146.306 213.726 146.478 213.443 146.595C213.16 146.712 212.857 146.773 212.55 146.773C212.244 146.773 211.94 146.712 211.657 146.595C211.374 146.478 211.117 146.306 210.9 146.089C204.539 139.74 195.918 136.174 186.93 136.174C177.942 136.174 169.321 139.74 162.96 146.089C162.745 146.309 162.488 146.484 162.205 146.604C161.922 146.724 161.618 146.787 161.31 146.789Z" fill="white"/>
            <path d="M169.231 154.71C168.768 154.71 168.315 154.572 167.929 154.314C167.544 154.057 167.244 153.69 167.067 153.262C166.891 152.833 166.845 152.362 166.937 151.908C167.028 151.453 167.253 151.036 167.581 150.71C172.718 145.586 179.677 142.709 186.931 142.709C194.186 142.709 201.145 145.586 206.281 150.71C206.663 151.155 206.863 151.728 206.841 152.314C206.818 152.899 206.576 153.456 206.162 153.871C205.748 154.286 205.193 154.53 204.607 154.554C204.021 154.578 203.448 154.38 203.001 154C198.74 149.748 192.966 147.36 186.946 147.36C180.927 147.36 175.153 149.748 170.891 154C170.676 154.223 170.418 154.401 170.133 154.523C169.848 154.645 169.541 154.708 169.231 154.71Z" fill="white"/>
            <path d="M177.15 162.62C176.685 162.625 176.228 162.49 175.84 162.233C175.451 161.976 175.149 161.609 174.971 161.178C174.793 160.748 174.749 160.274 174.843 159.818C174.938 159.362 175.166 158.944 175.5 158.62C178.535 155.591 182.648 153.891 186.935 153.891C191.223 153.891 195.335 155.591 198.37 158.62C198.587 158.836 198.759 159.093 198.876 159.377C198.993 159.66 199.054 159.963 199.054 160.27C199.054 160.576 198.993 160.879 198.876 161.163C198.759 161.446 198.587 161.703 198.37 161.92C198.154 162.136 197.896 162.308 197.613 162.425C197.33 162.543 197.027 162.603 196.72 162.603C196.414 162.603 196.11 162.543 195.827 162.425C195.544 162.308 195.287 162.136 195.07 161.92C194.002 160.851 192.734 160.003 191.338 159.424C189.942 158.846 188.446 158.548 186.935 158.548C185.424 158.548 183.928 158.846 182.532 159.424C181.136 160.003 179.868 160.851 178.8 161.92C178.363 162.36 177.771 162.611 177.15 162.62Z" fill="white"/>
            <path d="M189.292 167.71C189.759 168.177 190.078 168.772 190.207 169.42C190.337 170.068 190.271 170.74 190.018 171.35C189.765 171.961 189.337 172.483 188.788 172.85C188.239 173.217 187.593 173.413 186.932 173.413C186.271 173.413 185.625 173.217 185.076 172.85C184.526 172.483 184.098 171.961 183.846 171.35C183.593 170.74 183.527 170.068 183.656 169.42C183.786 168.772 184.104 168.177 184.572 167.71C185.198 167.085 186.047 166.733 186.932 166.733C187.817 166.733 188.666 167.085 189.292 167.71Z" fill="white"/>
            <path d="M122.751 74.9393C122.619 74.9401 122.489 74.9148 122.367 74.8651C122.245 74.8153 122.134 74.742 122.041 74.6493L116.681 69.2893C116.588 69.1959 116.515 69.0851 116.465 68.9632C116.415 68.8414 116.39 68.7109 116.391 68.5793C116.392 68.317 116.496 68.0656 116.681 67.8793L122.041 62.5193C122.228 62.3331 122.481 62.2285 122.746 62.2285C123.01 62.2285 123.263 62.3331 123.451 62.5193C123.637 62.7067 123.741 62.9601 123.741 63.2243C123.741 63.4885 123.637 63.7419 123.451 63.9293L118.801 68.5793L123.451 73.2293C123.544 73.3223 123.619 73.4329 123.67 73.5547C123.72 73.6766 123.746 73.8073 123.746 73.9393C123.746 74.0713 123.72 74.202 123.67 74.3239C123.619 74.4457 123.544 74.5563 123.451 74.6493C123.264 74.8341 123.013 74.9382 122.751 74.9393Z" fill="white"/>
            <path d="M256.452 64.0794C256.452 64.3128 256.383 64.541 256.253 64.735C256.123 64.9291 255.939 65.0803 255.723 65.1696C255.508 65.2589 255.27 65.2823 255.042 65.2367C254.813 65.1912 254.602 65.0788 254.437 64.9138C254.272 64.7488 254.16 64.5385 254.114 64.3096C254.069 64.0807 254.092 63.8435 254.182 63.6279C254.271 63.4122 254.422 63.2279 254.616 63.0983C254.81 62.9686 255.038 62.8994 255.272 62.8994C255.585 62.8994 255.885 63.0237 256.106 63.245C256.327 63.4663 256.452 63.7665 256.452 64.0794Z" fill="white"/>
            <path d="M256.452 68.5794C256.452 68.8128 256.383 69.041 256.253 69.235C256.123 69.4291 255.939 69.5803 255.723 69.6696C255.508 69.7589 255.27 69.7823 255.042 69.7367C254.813 69.6912 254.602 69.5788 254.437 69.4138C254.272 69.2488 254.16 69.0385 254.114 68.8096C254.069 68.5807 254.092 68.3435 254.182 68.1279C254.271 67.9122 254.422 67.7279 254.616 67.5983C254.81 67.4686 255.038 67.3994 255.272 67.3994C255.585 67.3994 255.885 67.5237 256.106 67.745C256.327 67.9663 256.452 68.2665 256.452 68.5794Z" fill="white"/>
            <path d="M256.452 73.0794C256.452 73.3128 256.383 73.541 256.253 73.735C256.123 73.9291 255.939 74.0803 255.723 74.1696C255.508 74.2589 255.27 74.2823 255.042 74.2367C254.813 74.1912 254.602 74.0788 254.437 73.9138C254.272 73.7488 254.16 73.5385 254.114 73.3096C254.069 73.0807 254.092 72.8435 254.182 72.6279C254.271 72.4122 254.422 72.2279 254.616 72.0983C254.81 71.9686 255.038 71.8994 255.272 71.8994C255.585 71.8994 255.885 72.0237 256.106 72.245C256.327 72.4663 256.452 72.7665 256.452 73.0794Z" fill="white"/>
            <path d="M223.121 195.58C231.538 195.58 238.361 188.756 238.361 180.34C238.361 171.923 231.538 165.1 223.121 165.1C214.704 165.1 207.881 171.923 207.881 180.34C207.881 188.756 214.704 195.58 223.121 195.58Z" fill="#215A9D"/>
            <path d="M229.241 187.46C229.109 187.461 228.979 187.435 228.857 187.386C228.735 187.336 228.624 187.262 228.531 187.17L216.301 174.93C216.137 174.738 216.052 174.492 216.061 174.241C216.071 173.989 216.175 173.75 216.353 173.572C216.532 173.394 216.77 173.29 217.022 173.28C217.274 173.27 217.52 173.356 217.711 173.52L230.001 185.75C230.095 185.843 230.169 185.953 230.22 186.075C230.271 186.197 230.297 186.328 230.297 186.46C230.297 186.592 230.271 186.722 230.22 186.844C230.169 186.966 230.095 187.077 230.001 187.17C229.902 187.269 229.783 187.346 229.652 187.396C229.521 187.445 229.381 187.467 229.241 187.46Z" fill="white"/>
            <path d="M217 187.459C216.801 187.461 216.607 187.403 216.441 187.293C216.276 187.183 216.147 187.026 216.071 186.843C215.994 186.659 215.975 186.457 216.014 186.263C216.053 186.068 216.149 185.889 216.29 185.749L228.52 173.519C228.613 173.426 228.724 173.351 228.845 173.301C228.967 173.25 229.098 173.224 229.23 173.224C229.362 173.224 229.493 173.25 229.615 173.301C229.736 173.351 229.847 173.426 229.94 173.519C230.126 173.707 230.231 173.96 230.231 174.224C230.231 174.489 230.126 174.742 229.94 174.929L217.7 187.169C217.514 187.354 217.262 187.458 217 187.459Z" fill="white"/>
            <g opacity="0.1">
            <path d="M259.811 231.859H114.051C113.919 231.857 113.793 231.803 113.7 231.71C113.607 231.617 113.553 231.491 113.551 231.359C113.551 231.227 113.603 231.1 113.697 231.006C113.791 230.912 113.918 230.859 114.051 230.859H259.811C259.943 230.859 260.071 230.912 260.164 231.006C260.258 231.1 260.311 231.227 260.311 231.359C260.311 231.492 260.258 231.619 260.164 231.713C260.071 231.807 259.943 231.859 259.811 231.859Z" fill="black"/>
            </g>
            <g opacity="0.3">
            <path d="M233.74 244.609C233.583 244.607 233.431 244.559 233.301 244.471C233.172 244.383 233.071 244.259 233.011 244.114C232.952 243.969 232.936 243.81 232.966 243.656C232.996 243.502 233.07 243.361 233.18 243.249C235.647 240.791 238.987 239.41 242.47 239.41C245.953 239.41 249.293 240.791 251.76 243.249C251.908 243.398 251.992 243.599 251.992 243.809C251.992 244.019 251.908 244.221 251.76 244.369C251.611 244.518 251.41 244.601 251.2 244.601C250.99 244.601 250.788 244.518 250.64 244.369C248.47 242.209 245.532 240.996 242.47 240.996C239.408 240.996 236.47 242.209 234.3 244.369C234.228 244.446 234.141 244.506 234.045 244.548C233.949 244.589 233.845 244.61 233.74 244.609Z" fill="black"/>
            <path d="M236.439 247.299C236.335 247.301 236.232 247.282 236.135 247.242C236.039 247.203 235.952 247.144 235.879 247.069C235.805 246.996 235.747 246.909 235.707 246.812C235.667 246.716 235.646 246.613 235.646 246.509C235.646 246.405 235.667 246.302 235.707 246.206C235.747 246.11 235.805 246.023 235.879 245.949C237.627 244.202 239.998 243.22 242.469 243.22C244.941 243.22 247.311 244.202 249.059 245.949C249.208 246.098 249.291 246.299 249.291 246.509C249.291 246.719 249.208 246.921 249.059 247.069C248.911 247.218 248.709 247.301 248.499 247.301C248.289 247.301 248.088 247.218 247.939 247.069C246.487 245.622 244.52 244.809 242.469 244.809C240.419 244.809 238.452 245.622 236.999 247.069C236.927 247.144 236.839 247.203 236.743 247.242C236.647 247.282 236.543 247.301 236.439 247.299Z" fill="black"/>
            <path d="M239.14 249.999C238.927 249.998 238.723 249.915 238.57 249.769C238.42 249.619 238.336 249.416 238.336 249.204C238.336 248.992 238.42 248.789 238.57 248.639C239.606 247.608 241.008 247.029 242.47 247.029C243.931 247.029 245.333 247.608 246.37 248.639C246.507 248.791 246.582 248.99 246.576 249.196C246.571 249.401 246.488 249.596 246.342 249.742C246.197 249.887 246.002 249.971 245.796 249.976C245.591 249.981 245.392 249.907 245.24 249.769C244.876 249.404 244.445 249.115 243.969 248.917C243.494 248.72 242.984 248.618 242.47 248.618C241.955 248.618 241.445 248.72 240.97 248.917C240.495 249.115 240.063 249.404 239.7 249.769C239.549 249.914 239.349 249.996 239.14 249.999Z" fill="black"/>
            <path d="M242.47 253.679C243.1 253.679 243.61 253.169 243.61 252.539C243.61 251.91 243.1 251.399 242.47 251.399C241.84 251.399 241.33 251.91 241.33 252.539C241.33 253.169 241.84 253.679 242.47 253.679Z" fill="black"/>
            </g>
            <path d="M254.371 249.31C254.369 250.174 254.111 251.017 253.63 251.735C253.148 252.452 252.465 253.011 251.666 253.34C250.868 253.669 249.989 253.754 249.142 253.585C248.295 253.415 247.517 252.998 246.907 252.386C246.297 251.775 245.882 250.996 245.714 250.149C245.546 249.301 245.633 248.423 245.964 247.625C246.296 246.827 246.856 246.145 247.574 245.665C248.292 245.186 249.137 244.93 250.001 244.93C250.576 244.93 251.145 245.043 251.675 245.263C252.206 245.484 252.689 245.806 253.094 246.213C253.5 246.62 253.822 247.103 254.041 247.634C254.26 248.166 254.372 248.735 254.371 249.31Z" fill="#263238"/>
            <path d="M251.751 251.56C251.619 251.558 251.493 251.504 251.401 251.41L247.891 247.9C247.799 247.806 247.748 247.681 247.748 247.55C247.748 247.419 247.799 247.293 247.891 247.2C247.937 247.153 247.993 247.116 248.054 247.09C248.115 247.065 248.18 247.052 248.246 247.052C248.312 247.052 248.377 247.065 248.438 247.09C248.499 247.116 248.555 247.153 248.601 247.2L252.111 250.71C252.203 250.803 252.254 250.929 252.254 251.06C252.254 251.191 252.203 251.316 252.111 251.41C252.016 251.505 251.886 251.559 251.751 251.56Z" fill="white"/>
            <path d="M248.251 251.56C248.116 251.559 247.986 251.505 247.891 251.41C247.799 251.316 247.748 251.191 247.748 251.06C247.748 250.929 247.799 250.803 247.891 250.71L251.401 247.2C251.447 247.153 251.503 247.116 251.564 247.09C251.625 247.065 251.69 247.052 251.756 247.052C251.822 247.052 251.887 247.065 251.948 247.09C252.009 247.116 252.064 247.153 252.111 247.2C252.203 247.293 252.254 247.419 252.254 247.55C252.254 247.681 252.203 247.806 252.111 247.9L248.601 251.41C248.509 251.504 248.383 251.558 248.251 251.56Z" fill="white"/>
            <g opacity="0.3">
            <path d="M233.741 273.609C233.532 273.607 233.331 273.524 233.181 273.379C233.031 273.229 232.947 273.026 232.947 272.814C232.947 272.602 233.031 272.399 233.181 272.249C235.648 269.791 238.988 268.41 242.471 268.41C245.954 268.41 249.294 269.791 251.761 272.249C251.911 272.399 251.995 272.602 251.995 272.814C251.995 273.026 251.911 273.229 251.761 273.379C251.687 273.453 251.6 273.511 251.504 273.551C251.408 273.591 251.305 273.612 251.201 273.612C251.097 273.612 250.994 273.591 250.898 273.551C250.802 273.511 250.714 273.453 250.641 273.379C248.473 271.214 245.535 269.997 242.471 269.997C239.407 269.997 236.468 271.214 234.301 273.379C234.227 273.452 234.14 273.51 234.044 273.55C233.948 273.589 233.845 273.609 233.741 273.609Z" fill="black"/>
            <path d="M236.441 276.309C236.336 276.309 236.233 276.288 236.136 276.247C236.04 276.206 235.953 276.145 235.881 276.069C235.807 275.996 235.749 275.909 235.709 275.812C235.669 275.716 235.648 275.613 235.648 275.509C235.648 275.405 235.669 275.302 235.709 275.206C235.749 275.11 235.807 275.023 235.881 274.949C237.629 273.202 240 272.22 242.471 272.22C244.943 272.22 247.313 273.202 249.061 274.949C249.21 275.098 249.293 275.299 249.293 275.509C249.293 275.719 249.21 275.921 249.061 276.069C248.913 276.218 248.711 276.301 248.501 276.301C248.291 276.301 248.09 276.218 247.941 276.069C246.489 274.622 244.522 273.809 242.471 273.809C240.421 273.809 238.454 274.622 237.001 276.069C236.929 276.145 236.842 276.206 236.746 276.247C236.65 276.288 236.546 276.309 236.441 276.309Z" fill="black"/>
            <path d="M239.141 278.999C238.928 278.998 238.724 278.916 238.571 278.769C238.497 278.696 238.438 278.609 238.398 278.512C238.358 278.416 238.338 278.313 238.338 278.209C238.338 278.105 238.358 278.002 238.398 277.906C238.438 277.81 238.497 277.723 238.571 277.649C239.082 277.135 239.689 276.727 240.359 276.449C241.028 276.171 241.746 276.027 242.471 276.027C243.196 276.027 243.913 276.171 244.583 276.449C245.252 276.727 245.86 277.135 246.371 277.649C246.444 277.723 246.503 277.81 246.543 277.906C246.583 278.002 246.603 278.105 246.603 278.209C246.603 278.313 246.583 278.416 246.543 278.512C246.503 278.609 246.444 278.696 246.371 278.769C246.221 278.919 246.018 279.003 245.806 279.003C245.594 279.003 245.391 278.919 245.241 278.769C244.877 278.405 244.446 278.115 243.97 277.918C243.495 277.72 242.985 277.619 242.471 277.619C241.956 277.619 241.446 277.72 240.971 277.918C240.496 278.115 240.064 278.405 239.701 278.769C239.55 278.914 239.35 278.997 239.141 278.999Z" fill="black"/>
            <path d="M242.47 282.679C243.1 282.679 243.61 282.169 243.61 281.539C243.61 280.91 243.1 280.399 242.47 280.399C241.84 280.399 241.33 280.91 241.33 281.539C241.33 282.169 241.84 282.679 242.47 282.679Z" fill="black"/>
            </g>
            <path d="M254.371 278.31C254.369 279.174 254.111 280.017 253.63 280.735C253.148 281.452 252.465 282.011 251.666 282.34C250.868 282.669 249.989 282.754 249.142 282.585C248.295 282.415 247.517 281.998 246.907 281.386C246.297 280.775 245.882 279.996 245.714 279.149C245.546 278.301 245.633 277.423 245.964 276.625C246.296 275.827 246.856 275.145 247.574 274.665C248.292 274.186 249.137 273.93 250.001 273.93C250.576 273.93 251.145 274.043 251.675 274.263C252.206 274.484 252.689 274.806 253.094 275.213C253.5 275.62 253.822 276.103 254.041 276.634C254.26 277.166 254.372 277.735 254.371 278.31Z" fill="#263238"/>
            <path d="M251.75 280.56C251.62 280.557 251.496 280.507 251.4 280.42L247.89 276.91C247.798 276.815 247.746 276.687 247.746 276.555C247.746 276.422 247.798 276.295 247.89 276.2C247.936 276.153 247.992 276.116 248.053 276.09C248.114 276.065 248.179 276.052 248.245 276.052C248.311 276.052 248.376 276.065 248.437 276.09C248.498 276.116 248.553 276.153 248.6 276.2L252.11 279.71C252.202 279.805 252.254 279.932 252.254 280.065C252.254 280.197 252.202 280.325 252.11 280.42C252.011 280.509 251.883 280.559 251.75 280.56Z" fill="white"/>
            <path d="M248.25 280.56C248.117 280.559 247.989 280.509 247.89 280.42C247.798 280.325 247.746 280.197 247.746 280.065C247.746 279.932 247.798 279.805 247.89 279.71L251.4 276.2C251.446 276.153 251.502 276.116 251.563 276.09C251.624 276.065 251.689 276.052 251.755 276.052C251.821 276.052 251.886 276.065 251.947 276.09C252.008 276.116 252.063 276.153 252.11 276.2C252.202 276.295 252.254 276.422 252.254 276.555C252.254 276.687 252.202 276.815 252.11 276.91L248.6 280.42C248.504 280.507 248.38 280.557 248.25 280.56Z" fill="white"/>
            <path opacity="0.2" d="M259.141 32.0996L106.001 185.25V35.6696C105.965 34.7588 106.293 33.8712 106.912 33.2018C107.531 32.5325 108.39 32.136 109.301 32.0996H147.731C148.269 32.1102 148.792 32.2803 149.233 32.5882C149.675 32.8962 150.015 33.3282 150.211 33.8296L151.801 37.7496C151.997 38.2472 152.336 38.6754 152.776 38.9799C153.216 39.2843 153.736 39.4513 154.271 39.4596H219.701C220.233 39.4577 220.752 39.2967 221.193 38.9974C221.633 38.698 221.973 38.2739 222.171 37.7796L223.761 33.8396C223.956 33.3371 224.296 32.9037 224.737 32.594C225.178 32.2844 225.702 32.1123 226.241 32.0996H259.141Z" fill="white"/>
            <path opacity="0.2" d="M267.88 60.4893V73.0592L105.99 234.939V222.379L267.88 60.4893Z" fill="white"/>
            <path d="M293.061 133.09C293.061 133.09 287.741 139.68 286.951 142.09C286.161 144.5 287.741 143.01 287.741 143.01C287.741 143.01 285.581 161.65 286.961 166.8C288.341 171.95 305.741 172.46 310.691 169.64C315.641 166.82 322.991 112.81 318.691 108.85C314.391 104.89 296.391 105.14 293.061 133.09Z" fill="#263238"/>
            <path d="M271.72 104.08L270.72 97.7698C269.968 96.5423 269.108 95.3843 268.15 94.3098C267.36 93.6398 266.15 87.7198 267.15 85.1398C268.15 82.5598 273.87 80.5098 273.87 80.5098L276.63 94.3098L275.22 96.7998L276.34 102.01L271.72 104.08Z" fill="#EBB376"/>
            <path d="M304.901 86.1699C298.391 87.0799 287.001 118.92 283.041 118.45C281.711 118.29 276.001 96.9999 276.001 96.9999L270.141 100.85C270.141 100.85 275.001 127 279.821 129.1C287.441 132.45 303.361 105.75 303.361 105.75L304.901 86.1699Z" fill="#215A9D"/>
            <path d="M303.25 83.9004C299.6 84.1904 286.35 107.78 286.35 107.78L294.8 121.38L299.8 115.84L303.25 83.9004Z" fill="#215A9D"/>
            <path opacity="0.6" d="M303.25 83.9004L301.55 99.6804L299.8 115.84L294.8 121.38L292.7 118L286.35 107.79C286.35 107.79 299.6 84.1904 303.25 83.9004Z" fill="white"/>
            <path opacity="0.2" d="M301.549 99.6807L299.799 115.841L294.799 121.381L292.699 118.001C293.089 108.671 298.669 102.341 301.549 99.6807Z" fill="black"/>
            <path d="M282.14 91.9301C282.165 92.0251 282.17 92.1243 282.153 92.2211C282.137 92.3179 282.1 92.41 282.045 92.4915C281.99 92.5729 281.919 92.6416 281.835 92.6931C281.751 92.7446 281.658 92.7777 281.56 92.7901L273.94 93.8601C273.723 93.8947 273.5 93.8475 273.315 93.7274C273.131 93.6073 272.997 93.423 272.94 93.2101L269.13 78.4101C269.096 78.3071 269.083 78.1979 269.093 78.0896C269.104 77.9814 269.137 77.8766 269.191 77.7821C269.245 77.6876 269.318 77.6057 269.406 77.5415C269.494 77.4774 269.594 77.4326 269.7 77.4101L277.32 76.3501C277.548 76.3429 277.77 76.4133 277.952 76.5496C278.134 76.6859 278.264 76.8801 278.32 77.1001L282.14 91.9301Z" fill="#263238"/>
            <path opacity="0.5" d="M277.471 77.1498L281.321 92.0898C281.356 92.175 281.37 92.2675 281.363 92.3594C281.355 92.4513 281.325 92.5401 281.277 92.6183C281.228 92.6965 281.161 92.762 281.082 92.8092C281.002 92.8564 280.913 92.884 280.821 92.8898L274.361 93.7998C273.931 93.8598 273.101 93.7298 272.991 93.3098L269.131 78.3098C269.031 77.8898 269.721 77.4398 270.131 77.3798L276.591 76.4798C276.796 76.4541 277.003 76.5098 277.168 76.6351C277.332 76.7604 277.441 76.9451 277.471 77.1498Z" fill="white"/>
            <path d="M337.299 328.53H347.199C347.361 328.534 347.517 328.591 347.643 328.692C347.77 328.794 347.86 328.933 347.899 329.09L349.499 336.35C349.533 336.504 349.536 336.663 349.508 336.818C349.479 336.973 349.421 337.12 349.335 337.252C349.25 337.384 349.139 337.498 349.009 337.587C348.879 337.676 348.733 337.738 348.579 337.77C348.489 337.78 348.399 337.78 348.309 337.77C345.109 337.72 342.779 337.52 338.749 337.52C336.279 337.52 328.799 337.78 325.379 337.78C321.959 337.78 321.519 334.4 322.919 334.09C329.199 332.72 333.919 330.83 335.919 329.02C336.301 328.688 336.794 328.513 337.299 328.53Z" fill="#263238"/>
            <path d="M295.32 328.27H305.22C305.382 328.274 305.539 328.331 305.665 328.432C305.791 328.533 305.881 328.673 305.921 328.83L307.52 336.09C307.554 336.245 307.557 336.404 307.529 336.56C307.501 336.715 307.443 336.864 307.357 336.996C307.272 337.129 307.161 337.244 307.031 337.334C306.901 337.424 306.755 337.487 306.6 337.52H306.33C303.13 337.47 300.8 337.27 296.77 337.27C294.3 337.27 286.82 337.53 283.4 337.53C279.98 337.53 279.54 334.15 280.94 333.84C287.22 332.47 291.94 330.58 293.94 328.77C294.321 328.435 294.814 328.256 295.32 328.27Z" fill="#263238"/>
            <path d="M342.55 145H299.3C295.19 167.46 283.43 258.33 292.67 329.14L307.11 330.72C307.11 330.72 304.84 222 317.77 187.2C319.25 205.79 321.02 225.58 322.55 236.11C326.32 262.02 334.8 329.56 334.8 329.56L349.43 329.96C349.43 329.96 346.23 264.45 344.82 238.96C343.28 211.33 342.61 150.71 342.55 145Z" fill="#263238"/>
            <path opacity="0.2" d="M342.55 145H299.3C295.19 167.46 283.43 258.33 292.67 329.14L307.11 330.72C307.11 330.72 304.84 222 317.77 187.2C319.25 205.79 321.02 225.58 322.55 236.11C326.32 262.02 334.8 329.56 334.8 329.56L349.43 329.96C349.43 329.96 346.23 264.45 344.82 238.96C343.28 211.33 342.61 150.71 342.55 145Z" fill="white"/>
            <path opacity="0.2" d="M317.761 187.201C315.637 193.293 314.022 199.551 312.931 205.911C311.721 189.361 316.561 171.811 316.561 171.811L317.761 187.201Z" fill="black"/>
            <path d="M298.941 145.55H342.481V150.74L298.381 150.2L298.941 145.55Z" fill="#263238"/>
            <path d="M328.48 143.22H326.93V152.42H328.48V143.22Z" fill="#263238"/>
            <path d="M306.45 143.22H304.9V152.42H306.45V143.22Z" fill="#263238"/>
            <path d="M318.78 144.65H310.73V150.45H318.78V144.65Z" fill="white"/>
            <path opacity="0.2" d="M328.48 143.22H326.93V152.42H328.48V143.22Z" fill="white"/>
            <path opacity="0.2" d="M306.45 143.22H304.9V152.42H306.45V143.22Z" fill="white"/>
            <path d="M303.25 83.9C303.25 83.9 330.76 82.84 351.25 87.08C351.25 87.08 342.2 124.35 342.54 145.55H298.94C298.94 145.55 296.11 105.78 303.25 83.9Z" fill="#215A9D"/>
            <path d="M335.19 85.0004C331.24 88.5904 321.57 87.2604 321.57 87.2604C321.57 87.2604 315.52 85.6104 316.79 83.7204C320.35 83.1304 322.07 81.4704 322.79 79.3404C323.176 78.0239 323.299 76.6442 323.15 75.2804C323.09 74.519 322.99 73.7613 322.85 73.0104L334.85 64.1504C333.24 70.0004 331.33 80.7804 335.19 85.0004Z" fill="#EBB376"/>
            <path opacity="0.2" d="M332.049 69.7998C331.569 76.9798 325.669 78.9598 322.789 79.3598C323.175 78.0433 323.297 76.6636 323.149 75.2998L332.049 69.7998Z" fill="black"/>
            <path d="M314.56 45.5703C314.56 45.5703 311.22 56.3703 316.45 61.5003C321.68 66.6303 324.76 46.0603 324.76 46.0603L314.56 45.5703Z" fill="#263238"/>
            <path d="M332.16 72.8306C330.659 74.4673 328.662 75.5652 326.476 75.9555C324.289 76.3458 322.035 76.0068 320.06 74.9906C317.481 73.7284 315.481 71.5284 314.47 68.8406C314.321 68.4685 314.195 68.0878 314.09 67.7006C312.22 60.5206 313.09 48.5706 320.88 45.2106C322.601 44.4606 324.484 44.1632 326.352 44.3466C328.22 44.5301 330.009 45.1883 331.551 46.2587C333.092 47.3291 334.334 48.7761 335.159 50.462C335.983 52.1479 336.362 54.0167 336.26 55.8906C336 63.9206 336.15 68.6506 332.16 72.8306Z" fill="#EBB376"/>
            <path d="M324.891 58.3098C324.951 58.9598 324.581 59.4998 324.141 59.5098C323.701 59.5198 323.361 58.9898 323.341 58.3398C323.321 57.6898 323.671 57.1498 324.091 57.1398C324.511 57.1298 324.871 57.6498 324.891 58.3098Z" fill="#263238"/>
            <path d="M316.5 58.4602C316.56 59.1102 316.17 59.6502 315.75 59.6602C315.33 59.6702 314.97 59.1402 314.95 58.4902C314.93 57.8402 315.28 57.3002 315.7 57.2902C316.12 57.2802 316.49 57.8402 316.5 58.4602Z" fill="#263238"/>
            <path d="M319.08 58.8105C318.343 60.8254 317.334 62.7297 316.08 64.4706C316.535 64.7818 317.053 64.9896 317.598 65.0794C318.142 65.1693 318.699 65.1389 319.23 64.9906L319.08 58.8105Z" fill="#D58745"/>
            <path d="M326.361 56.0003C326.29 56.0087 326.218 55.9995 326.152 55.9733C326.085 55.9471 326.027 55.9048 325.981 55.8503C325.692 55.4659 325.317 55.1545 324.887 54.9409C324.456 54.7274 323.981 54.6175 323.501 54.6203C323.451 54.6249 323.4 54.6196 323.353 54.6047C323.305 54.5899 323.26 54.5657 323.222 54.5337C323.183 54.5017 323.151 54.4624 323.128 54.418C323.105 54.3737 323.09 54.3252 323.086 54.2753C323.081 54.2254 323.086 54.175 323.101 54.1272C323.116 54.0793 323.14 54.0348 323.172 53.9963C323.204 53.9578 323.243 53.9259 323.288 53.9026C323.332 53.8792 323.381 53.8649 323.431 53.8603C324.039 53.8411 324.644 53.9694 325.192 54.234C325.74 54.4987 326.217 54.8919 326.581 55.3803C326.614 55.4173 326.64 55.461 326.656 55.5086C326.672 55.5561 326.677 55.6064 326.673 55.6563C326.668 55.7062 326.653 55.7545 326.628 55.7983C326.604 55.842 326.571 55.8802 326.531 55.9103L326.361 56.0003Z" fill="#263238"/>
            <path d="M313.499 56.0498C313.424 56.0743 313.344 56.0743 313.269 56.0498C313.178 56.0082 313.106 55.933 313.069 55.8399C313.032 55.7468 313.032 55.6429 313.069 55.5498C313.311 54.9897 313.685 54.4963 314.158 54.1115C314.632 53.7268 315.191 53.462 315.789 53.3398C315.888 53.3255 315.99 53.3511 316.07 53.4111C316.151 53.4711 316.204 53.5605 316.219 53.6598C316.233 53.7592 316.208 53.8603 316.148 53.9408C316.088 54.0214 315.998 54.075 315.899 54.0898C315.43 54.203 314.993 54.4236 314.623 54.7342C314.254 55.0449 313.961 55.437 313.769 55.8798C313.738 55.9252 313.698 55.9637 313.652 55.9929C313.605 56.0222 313.553 56.0415 313.499 56.0498Z" fill="#263238"/>
            <path d="M330.161 46L329.631 49.52C330.222 49.8216 330.678 50.3349 330.908 50.9576C331.138 51.5802 331.124 52.2666 330.871 52.88C329.641 55.88 331.201 60.49 333.441 62.39C333.441 62.39 333.541 61.11 334.621 61.44C335.701 61.77 335.221 68.44 333.011 71.63C335.831 68.7159 337.843 65.1179 338.851 61.19C340.711 54.71 340.521 49.92 339.251 47.36L330.161 46Z" fill="#263238"/>
            <path d="M339.9 62.4105C339.052 64.2601 337.506 65.6985 335.6 66.4105C333.07 67.3105 331.6 65.1004 332.16 62.6204C332.65 60.4104 334.55 57.2205 337.16 57.4105C339.77 57.6005 340.92 60.1405 339.9 62.4105Z" fill="#EBB376"/>
            <path d="M320.771 69.3107H320.681C320.65 69.2997 320.622 69.2825 320.598 69.2602C320.574 69.2378 320.555 69.2108 320.542 69.1809C320.529 69.1509 320.522 69.1186 320.521 69.0859C320.521 69.0532 320.528 69.0208 320.541 68.9907C320.541 68.9907 321.931 65.3507 325.341 65.4707C325.407 65.4707 325.471 65.497 325.517 65.5439C325.564 65.5908 325.591 65.6544 325.591 65.7207C325.592 65.7548 325.586 65.7888 325.574 65.8205C325.561 65.8522 325.542 65.8809 325.517 65.9045C325.493 65.9282 325.463 65.9463 325.431 65.9577C325.399 65.9691 325.365 65.9736 325.331 65.9707C322.331 65.8707 321.021 69.1407 321.001 69.1707C320.98 69.2135 320.947 69.2495 320.906 69.2743C320.865 69.2991 320.818 69.3117 320.771 69.3107Z" fill="#263238"/>
            <path d="M317.639 46.0002C319.139 49.0502 328.479 45.8202 331.059 47.8002C333.639 49.7802 340.109 50.5702 340.109 50.5702C340.109 50.5702 343.599 44.2702 336.269 40.8702C323.339 34.8702 308.069 39.8702 312.739 49.2602C312.739 49.2602 311.909 45.3302 314.739 44.2602C314.739 44.2602 312.049 45.7602 314.619 49.2602L317.639 46.0002Z" fill="#263238"/>
            <path d="M335.191 82.8105L329.631 85.5706L332.411 154.411L349.441 152.421L351.261 87.0706L335.191 82.8105Z" fill="#215A9D"/>
            <path opacity="0.6" d="M351.261 87.0806L350.801 103.671L350.451 115.951L349.431 152.421L332.411 154.411L329.631 85.5806L335.191 82.8105L351.261 87.0806Z" fill="white"/>
            <path d="M329.631 85.5707L336.161 80.4307L340.221 83.7807L335.191 90.2207L329.631 85.5707Z" fill="#215A9D"/>
            <path opacity="0.7" d="M329.631 85.5707L336.161 80.4307L340.221 83.7807L335.191 90.2207L329.631 85.5707Z" fill="white"/>
            <path d="M316.789 83.7402C314.039 87.5502 302.369 154.41 302.369 154.41L293.869 153.41C293.869 153.41 297.799 88.3502 303.249 83.8902L316.789 83.7402Z" fill="#215A9D"/>
            <path opacity="0.6" d="M316.789 83.7402C314.039 87.5502 302.369 154.41 302.369 154.41L293.869 153.41C293.869 153.41 297.799 88.3502 303.249 83.8902L316.789 83.7402Z" fill="white"/>
            <path d="M295.92 126.66C295.92 126.66 296.62 93.0502 310.73 83.7402H314.73C314.73 83.7402 298.83 101 295.92 126.66Z" fill="#263238"/>
            <path d="M318.78 80.4307L313.04 83.7407L312.74 87.5207L317.76 83.9807L318.78 80.4307Z" fill="#215A9D"/>
            <path opacity="0.7" d="M318.78 80.4307L313.04 83.7407L312.74 87.5207L317.76 83.9807L318.78 80.4307Z" fill="white"/>
            <path d="M351.259 135.82L347.259 139.67C347.259 139.67 344.109 141.37 343.649 142.67C342.569 145.67 345.649 153.53 346.389 154.44C347.389 155.73 355.969 154.44 355.969 154.44C355.969 154.44 353.899 146.09 352.699 143.96C352.253 143.144 351.683 142.402 351.009 141.76L354.779 138.22L351.259 135.82Z" fill="#EBB376"/>
            <path opacity="0.2" d="M350.801 103.67L350.451 116C346.811 109.46 349.141 99.7305 349.141 99.7305C349.141 99.7305 349.851 101.32 350.801 103.67Z" fill="black"/>
            <path d="M353.21 92.29C359.14 92.78 375.47 114.54 374.86 119.29C374.25 124.04 353.59 141.41 353.59 141.41L348.43 136.85C348.43 136.85 365.2 120.44 365.43 118.61C365.66 116.78 350.8 100.91 350.8 100.91L353.21 92.29Z" fill="#215A9D"/>
            <path d="M351.261 87.0801C353.411 88.0801 360.031 93.8801 368.341 105.26L358.151 114.67L350.621 109.81C350.621 109.81 349.351 106.15 349.061 97.9401C348.771 89.7301 351.261 87.0801 351.261 87.0801Z" fill="#215A9D"/>
            <path opacity="0.6" d="M351.261 87.0801C353.411 88.0801 360.031 93.8801 368.341 105.26L358.151 114.67L350.621 109.81C350.621 109.81 349.351 106.15 349.061 97.9401C348.771 89.7301 351.261 87.0801 351.261 87.0801Z" fill="white"/>
            </svg>


          <h1>No Internet Connection</h1>
          <h5>Please check your internet connection and try again</h5>
        </div>
        {/* <div className="text-center pt-4">
          <a href="/"><button className="btn primary_btn" type="submit">Return to home</button></a>
        </div> */}
      </div>
    }
}

export default NoInternetConnection;