import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface RackList {
  rackList: {
    load: boolean;
    data: [];
  };
}

export const rackListSlice = createSlice({
  name: "rackList",
  initialState: {
    rackList: {
      load: false,
      data: [],
    },
  } as RackList,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackList.pending, (state: RackList) => {
        state.rackList.load = true;
      })
      .addCase(fetchRackList.fulfilled, (state: RackList, action: any) => {
        state.rackList.data = action.payload;
        state.rackList.load = false;
      })
      .addCase(fetchRackList.rejected, (state: RackList) => {
        state.rackList.load = false;
      })
  },
});

export default rackListSlice.reducer;

export const fetchRackList = createAsyncThunk(
  "PalleteManagment/fetchRackList",
  async (param: {apiParam: {layout_id: any, warehouse_id: any}}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getRacksListData", {
        warehouse_id: param.apiParam.warehouse_id,
        layout_id: param.apiParam.layout_id
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
