import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface CountriesState {
  countries: {
    load: boolean;
    data: [];
  };
}

export const CountriesSlice = createSlice({
  name: "countries",
  initialState: {
    countries: {
      load: false,
      data: [],
    },
  } as CountriesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.countries.load = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries.data = action.payload;
        state.countries.load = false;
      })
      .addCase(fetchCountries.rejected, (state) => {
        state.countries.load = false;
      });
  },
});

export default CountriesSlice.reducer;

export const fetchCountries = createAsyncThunk(
  "users/fetchCountries",
  async (param, thunkAPI) => {
    try {
      const { data, status } = await DataServer.post("api/admin/countries");
      if (status !== 200) throw "Login Error";
      return data.data;
    } catch (error) {
    }
  }
);
