import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface GetElementChecklistState {
    GetElementChecklist: {
        load: boolean;
        data: [];
    };
}

export const GetElementChecklistSlice = createSlice({
    name: "GetElementChecklist",
    initialState: {
        GetElementChecklist: {
            load: false,
            data: [],
        },
    } as GetElementChecklistState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchElementChecklist.pending, (state: GetElementChecklistState) => {
                state.GetElementChecklist.load = true;
            })
            .addCase(fetchElementChecklist.fulfilled, (state: GetElementChecklistState, action: any) => {
                state.GetElementChecklist.data = action.payload;
                state.GetElementChecklist.load = false;
            })
            .addCase(fetchElementChecklist.rejected, (state: GetElementChecklistState) => {
                state.GetElementChecklist.load = false;
            });
    },
});

export default GetElementChecklistSlice.reducer;

export const fetchElementChecklist = createAsyncThunk(
    "GetElementChecklist/fetchElementChecklist",
    async (param: { apiParam: { element_pin: any } }, thunkAPI) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/postEditElementChecklist", {
                warehouse_id: warehouse_id,
                element_pin: param.apiParam.element_pin, 
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return(error);
        }
    }
);