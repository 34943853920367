import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface EmailNotificationUserDataState {
    emailnotificationuserdata: {
        load: boolean;
        data: [];
    };
}

export const NotificationSlice = createSlice({
    name: "emailnotificationuserdata",
    initialState: {
        emailnotificationuserdata: {
            load: false,
            data: [],
        },
    } as EmailNotificationUserDataState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchEmailNotificationUserData.pending, (state: EmailNotificationUserDataState) => {
                state.emailnotificationuserdata.load = true;
            })
            .addCase(fetchEmailNotificationUserData.fulfilled, (state: EmailNotificationUserDataState, action: any) => {
                state.emailnotificationuserdata.data = action.payload;
                state.emailnotificationuserdata.load = false;
            })
            .addCase(fetchEmailNotificationUserData.rejected, (state: EmailNotificationUserDataState) => {
                state.emailnotificationuserdata.load = false;
            });
    },
});

export default NotificationSlice.reducer;

export const fetchEmailNotificationUserData = createAsyncThunk(
    "emailnotificationuserdatacount/fetchEmailNotificationUserData",
    async (param: { apiParam: {id: any}}, thunkAPI) => {
        const warehouse_id = localStorage.getItem('_warehouse_id');
        try {
            const { data } = await DataServer.get("api/GetUserDetailById?id=" + param.apiParam.id );
            return data.data;
        } catch (error) {
            // console.log("Error", error);
        }
    }
);