import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface ProjectYearListState {
  ProjectYearList: {
    load: boolean;
    data: [];
  };
}

export const ProjectYearListSlice = createSlice({
  name: "ProjectYearList",
  initialState: {
    ProjectYearList: {
      load: false,
      data: [],
    },
  } as ProjectYearListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchProjectYearList.pending, (state: ProjectYearListState) => {
        state.ProjectYearList.load = true;
      })
      .addCase(fetchProjectYearList.fulfilled, (state: ProjectYearListState, action: any) => {
        state.ProjectYearList.data = action.payload;
        state.ProjectYearList.load = false;
      })
      .addCase(fetchProjectYearList.rejected, (state: ProjectYearListState) => {
        state.ProjectYearList.load = false;
      });
  },
});

export default ProjectYearListSlice.reducer;

export const fetchProjectYearList = createAsyncThunk(
  "ProjectYearList/fetchProjectYearList",
  async (param: any, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getProjectYearsList", {
        tpi_firm_id: param.apiParam.tpi_firm_id,
        login_user_id: param.apiParam.login_user_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
