import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface WarehouseReportState {
  WarehouseReport: {
    load: boolean;
    data: [];
  };
}

export const WarehouseReportSlice = createSlice({
  name: "WarehouseReport",
  initialState: {
    WarehouseReport: {
      load: false,
      data: [],
    },
  } as WarehouseReportState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(
        getDetailedInspectionMergePdfFile.pending,
        (state: WarehouseReportState) => {
          state.WarehouseReport.load = true;
        }
      )
      .addCase(
        getDetailedInspectionMergePdfFile.fulfilled,
        (state: WarehouseReportState, action: any) => {
          state.WarehouseReport.data = action.payload;
          state.WarehouseReport.load = false;
        }
      )
      .addCase(
        getDetailedInspectionMergePdfFile.rejected,
        (state: WarehouseReportState) => {
          state.WarehouseReport.load = false;
        }
      );
  },
});

export default WarehouseReportSlice.reducer;

export const postDetailedInspectionReportPdf = createAsyncThunk(
  "WarehouseReport/postDetailedInspectionReportPdf ",
  async (
    param: {
      apiParam: { warehouse_id: any; inspection_pin: any; report_file: any };
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(
        "api/postDetailedInspectionReportPdf",
        {
          ...param.apiParam,
        }
      );
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);

export const generateDetailedInspectionPdf = createAsyncThunk(
  "WarehouseReport/generateDetailedInspectionPdf",
  async (
    param: {
      apiParam: { warehouse_id: any; inspection_pin: any; login_user_id: any };
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(
        "api/generateDetailedInspectionPdf",
        {
          ...param.apiParam,
        }
      );
      thunkAPI.dispatch(toggleLoading());

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: "Done",
          message: data.message,
          onConfirm: () => {
            handleonConfirm();
          },
        })
      );

      const handleonConfirm = () => {
        window.location.reload();
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);

export const mergeDetailedInspectionPdf = createAsyncThunk(
  "WarehouseReport/mergeDetailedInspectionPdf  ",
  async (
    param: {
      apiParam: { warehouse_id: any; inspection_pin: any; login_user_id: any };
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/mergeDetailedInspectionPdf", {
        ...param.apiParam,
      });
      thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: "Done",
          message: data.message,
          onConfirm: () => {
            handleonConfirm();
          },
        })
      );

      const handleonConfirm = () => {
        window.location.reload();
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);

export const getDetailedInspectionMergePdfFile = createAsyncThunk(
  "WarehouseReport/getDetailedInspectionMergePdfFile   ",
  async (
    param: { apiParam: { warehouse_id: any; inspection_pin: any } },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(
        "api/getDetailedInspectionMergePdfFile ",
        {
          ...param.apiParam,
        }
      );
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
