import { createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import { ROLES } from "../../../utils/constant";
import { getItem, setItem, removeAll } from "../../../utils/getLocalStorage";
import { Navigate } from "react-router-dom";
import { toggleLoading } from "../LoadingSlice";
import CryptoJS from "crypto-js";
import encryptData from "../../../reusable/encryptData";
import decryptData from "../../../reusable/decryptData";

export const authenticationSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      load: false,
      data: {
        id: 0,
        role_id: 0,
        company_id: null,
        created_by: 0,
        member_id: null,
        first_name: "",
        last_name: "",
        email: "",
        email_verified_at: null,
        contact_number: "",
        address_line1: "",
        address_line2: "",
        city: "",
        district_id: 0,
        state_id: 0,
        country_id: 0,
        pin_code: 0,
        profile_image: null,
        status: "",
        retrive_count: null,
        created_at: null,
        updated_at: null,
        joining_date: null,
      },
      token: "",
      message: "",
      status_code: null,
      role_id: 1,
      role: "",
      isAuthenticated: false,
    },
  },
  reducers: {
    userLoginRequest: (state) => {
      state.user.load = true;
    },
    userLoginSuccess: (state, action) => {
      state.user = action.payload;
      state.user.isAuthenticated = action.payload.isAuthenticated;
      state.user.load = false;
      state.user.role = action.payload.role;
      state.user.message = action.payload.message || "";
    },
    userLoginFail: (state, action) => {
      state.user.isAuthenticated = false;
      state.user.load = false;
      state.user.message = action.payload.message || "";
    },
    userSessionSet: (state, action) => {
      state.user.isAuthenticated = action.payload.isAuthenticated;
      state.user.data.id = action.payload.user_id;
      state.user.role = action.payload.role;
      state.user.data.profile_image = action.payload.profile_image;
      state.user.data.first_name = action.payload.first_name;
      state.user.data.last_name = action.payload.last_name;
      state.user.data.email = action.payload.email;
      state.user.data.contact_number = action.payload.contact_number;
      state.user.data.address_line1 = action.payload.address_line1;
      state.user.data.address_line2 = action.payload.address_line2;
      state.user.data.city = action.payload.city;
      state.user.data.country_id = action.payload.country_id;
      state.user.data.district_id = action.payload.district_id;
      state.user.data.state_id = action.payload.state_id;
      state.user.data.district_id = action.payload.district_id;
      state.user.data.pin_code = action.payload.pin_code;
      state.user.message = action.payload.message || "";
    },
    loginUser: (state, action) => {
      state.user = action.payload;
      state.user.message = action.payload.message || "";
    },
  },
});

export const {
  loginUser,
  userLoginRequest,
  userLoginSuccess,
  userLoginFail,
  userSessionSet,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const LoginUser =
  ({ email = "", password = "", otp = "" }) =>
  async (dispatch: Function) => {
    try {
      dispatch(toggleLoading());
      dispatch(userLoginRequest());
      const { data, status } = await DataServer.post("api/login", {
        email: email,
        password: password,
        otp: otp,
      });

      if (status === 200) {
        setItem("_isAuthenticated", true);
        setItem("data", encryptData(data));
        dispatch(toggleLoading());
        dispatch(
          userLoginSuccess({
            ...data,
            isAuthenticated: true,
            load: false,
            role: ROLES[data.role_id],
            message: data.message
          })
        );
        window.location.reload();
      }

    } catch (error) {
      dispatch(toggleLoading());
      dispatch(
        userLoginFail({
          isAuthenticated: false,
          load: false,
          message: error,
        })
      );
    }
  };

export const LogoutUser = (navigate?: Function) => async (dispatch: Function) => {
  const loginUserData = decryptData();

  if (loginUserData?.token) {
    try {
      dispatch(toggleLoading());
      const { data, status } = await DataServer.post("api/logout");

      if (status === 200) {
        if (navigate) {
          removeAll();
          navigate("/");
          window.location.reload();
        }
      }
      // return data;
    } catch (error) {
      // console.log(error)
    }
  }
};

export const VerifyOtp =
  ({ email = "", password = "" }, navigation: any) =>
  async (dispatch: Function) => {
    try {
      dispatch(toggleLoading());
      const { data, status } = await DataServer.post("api/loginOtpGeneration", {
        email: email,
        password: password,
      });

      if (data.message === "OTP send successfully on registerd email") {
        navigation(`/verify-otp`, {
          state: { email: email, password: password },
        });
      }

      dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      dispatch(toggleLoading());
      // dispatch(userLoginFail(error));
    }
  };

export const VerifyEmail =
  ({ token = "" }, navigation: any) =>
  async (dispatch: Function) => {
    try {
      dispatch(toggleLoading());
      const { data, status } = await DataServer.post("api/emailVerification", {
        token: token,
      });

      if (status === 200) {
        navigation(`/`);
      }

      dispatch(toggleLoading());
    } catch (error) {
      dispatch(toggleLoading());
    }
  };
