import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface AllCreditPointsState {
    AllCreditPoint: {
        load: boolean;
        data: [];
    };
}

export const inspectionSlice = createSlice({
    name: "AllCreditPoint",
    initialState: {
        AllCreditPoint: {
            load: false,
            data: [],
        },
    } as AllCreditPointsState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchAllCreditPoint.pending, (state: AllCreditPointsState) => {
                state.AllCreditPoint.load = true;
            })
            .addCase(fetchAllCreditPoint.fulfilled, (state: AllCreditPointsState, action: any) => {
                state.AllCreditPoint.data = action.payload;
                state.AllCreditPoint.load = false;
            })
            .addCase(fetchAllCreditPoint.rejected, (state: AllCreditPointsState) => {
                state.AllCreditPoint.load = false;
            })
            .addCase(fetchAllCloudeStorage.pending, (state: AllCreditPointsState) => {
                state.AllCreditPoint.load = true;
            })
            .addCase(fetchAllCloudeStorage.fulfilled, (state: AllCreditPointsState, action: any) => {
                state.AllCreditPoint.data = action.payload;
                state.AllCreditPoint.load = false;
            })
            .addCase(fetchAllCloudeStorage.rejected, (state: AllCreditPointsState) => {
                state.AllCreditPoint.load = false;
            })
            .addCase(fetchAllUsers.pending, (state: AllCreditPointsState) => {
                state.AllCreditPoint.load = true;
            })
            .addCase(fetchAllUsers.fulfilled, (state: AllCreditPointsState, action: any) => {
                state.AllCreditPoint.data = action.payload;
                state.AllCreditPoint.load = false;
            })
            .addCase(fetchAllUsers.rejected, (state: AllCreditPointsState) => {
                state.AllCreditPoint.load = false;
            });
    },
});

export default inspectionSlice.reducer;

export const fetchAllCreditPoint = createAsyncThunk(
    "AllCreditPoint/fetchAllCreditPoint",
    async (param, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/creditPoints");
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchAllCloudeStorage = createAsyncThunk(
    "AllCloudeStorage/fetchAllCloudeStorage",
    async (param, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/cloudStoragePoint");
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchAllUsers = createAsyncThunk(
    "AllUsers/fetchAllUsers",
    async (param, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/addonUserPoint");
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);