import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface EditHealthParamterState {
    EditHealthParamter: {
        load: boolean;
        data: [];
    };
}

export const EditHealthParamterSlice = createSlice({
    name: "EditHealthParamter",
    initialState: {
        EditHealthParamter: {
            load: false,
            data: [],
        },
    } as EditHealthParamterState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(EditHealthParamterData.pending, (state: EditHealthParamterState) => {
                state.EditHealthParamter.load = true;
            })
            .addCase(EditHealthParamterData.fulfilled, (state: EditHealthParamterState, action: any) => {
                state.EditHealthParamter.data = action.payload;
                state.EditHealthParamter.load = false;
            })
            .addCase(EditHealthParamterData.rejected, (state: EditHealthParamterState) => {
                state.EditHealthParamter.load = false;
            });
    },
});

export default EditHealthParamterSlice.reducer;

export const EditHealthParamterData = createAsyncThunk(
    "EditHealthParamter/EditHealthParamterData",
    async (param: { apiParam: { probability_of_event: string, effect_of_event: string, element_name: any, element_issue_name: any, sub_issue_name: any, warehouse_id: any }, navigation: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/editElementHealthData", {
                probability_of_event: param.apiParam.probability_of_event,
                effect_of_event: param.apiParam.effect_of_event,
                element_name: param.apiParam.element_name,
                element_issue_name: param.apiParam.element_issue_name,
                sub_issue_name: param.apiParam.sub_issue_name,
                warehouse_id: param.apiParam.warehouse_id
            });

            // thunkAPI.dispatch(
            //     activateSweetAlert({
            //         show: true,
            //         type: "success",
            //         title: "Success!",
            //         confirmBtnText: 'Done',
            //         message: data.message,
            //         onConfirm: () => {
            //             handleonConfirm()
            //         },
            //     })
            // );

            // const handleonConfirm = () => {
            //     param.navigation("/element-summary");
            //     thunkAPI.dispatch(deActivateSweetAlert());
            // };

            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);
