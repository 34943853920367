import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface ReportIssueData {
  assigned_to: string;
  assigned_by: string;
  action_required: string;
  comment: string;
  reported_issue_id: any;
  warehouse_id: number;
}

interface ReportIssueState {
  reportissue: {
    load: boolean;
    data: Array<ReportIssueData>;
  };
  reportIssueData?: ReportIssueData;
  // editUser: {
  //   load: boolean;
  // };
}

const initialState = {
  reportissue: {
    load: false,
    data: [],
  },
  reportIssueData: {
    assigned_to: "",
    assigned_by: "",
    action_required: "",
    comment: "",
    reported_issue_id: "",
    warehouse_id: 0,
  },
  // editUser: {
  //   load: false,
  // },
} as ReportIssueState;

export const reportIssueSlice = createSlice({
  name: "reportissue",
  initialState,
  reducers: {
    resetIssueData: (state: ReportIssueState) => {
      state.reportIssueData = initialState.reportIssueData;
    },
    updateIssueData: (state: ReportIssueState, action: { payload: ReportIssueData }) => {
      state.reportIssueData = action.payload;
    },
  },
});

export const { resetIssueData, updateIssueData } = reportIssueSlice.actions;

export default reportIssueSlice.reducer;


export const ReportIssue = createAsyncThunk(
  "reportissue/ReportIssue",
  async (param: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());

      const { data } = await DataServer.post(`api/postReportedIssueVerify`, {
        reported_issue_pin: param.apiParam.id,
        reported_by: param.apiParam.reported_by,
        action_required: param.apiParam.reportIssueData.action_required,
        assigned_to: param.apiParam.reportIssueData.assigned_to,
        warehouse_id: param.apiParam.reportIssueData.warehouse_id,
        assigned_by: param.apiParam.reportIssueData.assigned_by,
      });
      thunkAPI.dispatch(toggleLoading());

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => { 
            param.apiParam.reported_by === "TPI" ? handleonConfirm1() : handleonConfirm() 
          },
        })
      );
      
      const handleonConfirm = () => {
        param.navigation("/reported-issue-summary");
        thunkAPI.dispatch(deActivateSweetAlert());
        thunkAPI.dispatch(resetIssueData());
        window.location.reload();
      };
      
      const handleonConfirm1 = () => {
        param.navigation("/compare-statistics");
        thunkAPI.dispatch(deActivateSweetAlert());
        thunkAPI.dispatch(resetIssueData());
      };

    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(resetIssueData());
      return error;

    }
});


  
export const ReportTpiIssue = createAsyncThunk(
  "reportissue/ReportIssue",
  async (param: any, thunkAPI) => {
    try {

      const { data } = await DataServer.post(`api/tpiReportedIssueVerify`, {
        tpi_reported_issue_pin: param.apiParam.id,
        reported_by: param.apiParam.reported_by,
        action_required: param.apiParam.action,
        comment: param.apiParam.comment,
        severity: param.apiParam.severity,
        flag: param.apiParam.flag
      });

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => {
            handleonConfirm()
          },
        })
      );

      const handleonConfirm = () => {
        localStorage.removeItem("state");
        param.navigation(`/projects-details`, {state: {WarehouseTimeZone: param.apiParam.timezone, ActiveTab: param.apiParam.ActiveTab, countPerPage: param.countPerPage, Page: param.Page, debouncedSearchValue: param?.apiParam?.debouncedSearchValue, project_pin: param.apiParam.project_pin}});
        thunkAPI.dispatch(deActivateSweetAlert());
        thunkAPI.dispatch(resetIssueData());
      };

    } catch (error) {
      return error;

    }
});

export const UpdateReportIssue = createAsyncThunk(
  "reportissue/UpdateReportIssue",
  async (param: { apiParam: {id: any, reportIssueData: any}, navigation: any }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());

      const { data } = await DataServer.post(`api/updateAction`, {
        reported_issue_pin: param.apiParam.id,
        action_required: param.apiParam.reportIssueData.action_required,
        assigned_to: param.apiParam.reportIssueData.assigned_to,
        warehouse_id: param.apiParam.reportIssueData.warehouse_id,
        assigned_by: param.apiParam.reportIssueData.assigned_by,
      });
      thunkAPI.dispatch(toggleLoading());

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => {
            handleonConfirm()
          },
        })
      );

      const handleonConfirm = () => {
        param.navigation("/verified-issue-summary");
        thunkAPI.dispatch(deActivateSweetAlert());
      };


    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
});
