import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface InspectorListState {
  inspectorList: {
    load: boolean;
    data: [];
  };
}

export const inspectorListSlice = createSlice({
  name: "inspectorList",
  initialState: {
    inspectorList: {
      load: false,
      data: [],
    },
  } as InspectorListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchInspectorList.pending, (state: InspectorListState) => {
        state.inspectorList.load = true;
      })
      .addCase(fetchInspectorList.fulfilled, (state: InspectorListState, action: any) => {
        state.inspectorList.data = action.payload;
        state.inspectorList.load = false;
      })
      .addCase(fetchInspectorList.rejected, (state: InspectorListState) => {
        state.inspectorList.load = false;
      })
      .addCase(fetchTpiInspectorList.pending, (state: InspectorListState) => {
        state.inspectorList.load = true;
      })
      .addCase(fetchTpiInspectorList.fulfilled, (state: InspectorListState, action: any) => {
        state.inspectorList.data = action.payload;
        state.inspectorList.load = false;
      })
      .addCase(fetchTpiInspectorList.rejected, (state: InspectorListState) => {
        state.inspectorList.load = false;
      });
  },
});

export default inspectorListSlice.reducer;

export const fetchInspectorList = createAsyncThunk(
  "users/fetchInspectorList",
  async (param: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("api/getInspectorList?warehouse_id=" + param.apiParam.warehouse_id);
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);

export const fetchTpiInspectorList = createAsyncThunk(
  "users/fetchTpiInspectorList",
  async (param: { apiParam: {tpi_firm_id: any, login_user_id: any } }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getTpiInspectorList", {
        tpi_firm_id: param.apiParam.tpi_firm_id,
        login_user_id: param.apiParam.login_user_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);

