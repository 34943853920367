import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface FrameWiseDataState {
  frameWiseData: {
    load: boolean;
    data: [];
  };
}

export const frameWiseDataSlice = createSlice({
  name: "frameWiseData",
  initialState: {
    frameWiseData: {
      load: false,
      data: [],
    },
  } as FrameWiseDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchFrameWiseData.pending, (state: FrameWiseDataState) => {
        state.frameWiseData.load = true;
      })
      .addCase(fetchFrameWiseData.fulfilled, (state: FrameWiseDataState, action: any) => {
        state.frameWiseData.data = action.payload;
        state.frameWiseData.load = false;
      })
      .addCase(fetchFrameWiseData.rejected, (state: FrameWiseDataState) => {
        state.frameWiseData.load = false;
      });
  },
});

export default frameWiseDataSlice.reducer;


export const fetchFrameWiseData = createAsyncThunk(
    "report/fetchFrameWiseData",
    async (param: {apiParam: {id: any, warehouse_id: any}}) => {
      try {
        const { data } = await DataServer.post(`api/getFrameWiseRackHealthData`, {
          warehouse_id: param.apiParam.warehouse_id
        });
        return data.data;
      } catch (error) {
        return error;
      }
    }
  );
