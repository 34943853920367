import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../sensorconfig.axios";

interface MHEUtilizationState {
  MHEUtilization: {
    load: boolean;
    data: [];
  };
}

export const MHEUtilizationSlice = createSlice({
  name: "MHEUtilization",
  initialState: {
    MHEUtilization: {
      load: false,
      data: [],
    },
  } as MHEUtilizationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMHEUtilization.pending, (state) => {
        state.MHEUtilization.load = true;
      })
      .addCase(getMHEUtilization.fulfilled, (state, action) => {
        state.MHEUtilization.data = action.payload;
        state.MHEUtilization.load = false;
      })
      .addCase(getMHEUtilization.rejected, (state) => {
        state.MHEUtilization.load = false;
      });
  },
});

export default MHEUtilizationSlice.reducer;

export const getMHEUtilization = createAsyncThunk(
  "MHEUtilization/getMHEUtilization",
  async (param: any, thunkAPI) => {
    try {
      var data: any; 
      if(param.apiParam.operator_id === "all"){
        data = await DataServer.get("management/mheSummary/v1/getMHEUtilization?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&duration=" + param.apiParam.duration);
      } else {
        data = await DataServer.get("management/mheSummary/v1/getMHEUtilization?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&duration=" + param.apiParam.duration + "&operator_id=" + param.apiParam.operator_id);
      }
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
