import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface RackHealthGraphDataState {
    rackhealthgraph: {
        load: boolean;
        data: [];
    };
}

export const RackHealthGraphDataSlice = createSlice({
    name: "rackhealthgraph",
    initialState: {
        rackhealthgraph: {
            load: false,
            data: [],
        },
    } as RackHealthGraphDataState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchrackhealthgraph.pending, (state: RackHealthGraphDataState) => {
                state.rackhealthgraph.load = true;
            })
            .addCase(fetchrackhealthgraph.fulfilled, (state: RackHealthGraphDataState, action: any) => {
                state.rackhealthgraph.data = action.payload;
                state.rackhealthgraph.load = false;
            })
            .addCase(fetchrackhealthgraph.rejected, (state: RackHealthGraphDataState) => {
                state.rackhealthgraph.load = false;
            });
    },
});

export default RackHealthGraphDataSlice.reducer;

export const fetchrackhealthgraph = createAsyncThunk(
    "rackhealthgraph/fetchrackhealthgraph",
    async (param: { apiParam: { layout_pin: any, warehouse_id: any } }) => {
        try {
            const { data } = await DataServer.post("api/rackHealthData", {
                warehouse_id: param.apiParam.warehouse_id,
                layout_id: param.apiParam.layout_pin,
                rack_id: "0"
            });

            return data.data;
        } catch (error) {
            return error;
        }

    }

);
