import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface ProjectInformationState {
  ProjectInformation: {
    load: boolean;
    data: [];
  };
}

export const ProjectInformationSlice = createSlice({
  name: "ProjectInformation",
  initialState: {
    ProjectInformation: {
      load: false,
      data: [],
    },
  } as ProjectInformationState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(
        fetchProjectInformation.pending,
        (state: ProjectInformationState) => {
          state.ProjectInformation.load = true;
        }
      )
      .addCase(
        fetchProjectInformation.fulfilled,
        (state: ProjectInformationState, action: any) => {
          state.ProjectInformation.data = action.payload;
          state.ProjectInformation.load = false;
        }
      )
      .addCase(
        fetchProjectInformation.rejected,
        (state: ProjectInformationState) => {
          state.ProjectInformation.load = false;
        }
      );
  },
});

export default ProjectInformationSlice.reducer;

export const fetchProjectInformation = createAsyncThunk(
  "ProjectInformation/fetchProjectInformation",
  async (
    param: { apiParam: { project_pin: any; layout_id: any } },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getProjectInformation", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);

export const postProjectInformation = createAsyncThunk(
  "ProjectInformation/postProjectInformation",
  async (
    param: { apiParam: any; NextTab: any },
    thunkAPI
  ) => {
    try {
      const { data } = await DataServer.post("api/postProjectsInformation",  param.apiParam);

      thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: "Done",
          message: data.message,
          onConfirm: () => {
            handleonConfirm();
          },
        })
      );

      const handleonConfirm = () => {
        // param.navigation(`/view-tpi-report/${param.apiParam.project_pin}`);
        // window.location.reload();
        param.NextTab("tab_2");
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
