import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { LogoutUser } from '../../redux/slice/LogoutUser';

const InactivateUser = () => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
  let idleTimer = null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => setIsIdle(true), idleTimeout);
    setIsIdle(false); // User is active
  };

  const handleActivity = () => {
    if (!document.hidden) {
      resetTimer();
    }
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // clearTimeout(idleTimer); // Clear timer when tab is inactive
    } else {
      resetTimer(); // Reset timer when tab becomes active
    }
  };

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Listen for changes in visibility (tab becoming active/inactive)
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Set the initial timer
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(idleTimer);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const currentTime = new Date().toISOString();
      localStorage.setItem('appClosedTime', currentTime); // Store the time in localStorage
      // event.preventDefault(); // Cancel the event if necessary
      // event.returnValue = ''; // Some browsers require this to trigger the alert
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);

  useEffect(() => {
    const appClosedTime = localStorage.getItem("appClosedTime");
    const currentTime = new Date().toISOString();
    // if()
    const firstTime = new Date(appClosedTime);
    const secondTime = new Date(currentTime);

    // Check if both dates are valid
    if (isNaN(firstTime.getTime()) || isNaN(secondTime.getTime())) {
      return;
    }

    // Calculate the difference in milliseconds
    const differenceInMs = secondTime - firstTime;

    // Convert milliseconds to minutes
    const differenceInMinutes = differenceInMs / (1000 * 60);

    // Check if the difference is greater than 15 minutes
    if (differenceInMinutes > 15) {
      dispatch(LogoutUser()).then((response) => {
        if(response?.message === "logged out!"){
          localStorage.clear();
          // navigate("/");
          window.location.href = "/";
          window.location.reload();
        }
      });
    } else {
    }
  }, []);

  useEffect(() => {
    if (isIdle) {
      dispatch(LogoutUser()).then((response) => {
        if(response?.message === "logged out!"){
          localStorage.clear();
          // navigate("/");
          window.location.href = "/";
          window.location.reload();
        }
      });
      // navigate('/'); // Navigate to login after logout
    }
  }, [isIdle, dispatch, navigate]);

  return <div></div>;
};

export default InactivateUser;
