import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface LoaderProgressState {
  LoaderProgress: {
    load: boolean;
    data: [];
  };
}

export const LoaderProgressSlice = createSlice({
  name: "LoaderProgress",
  initialState: {
    LoaderProgress: {
      load: false,
      data: [],
    },
  } as LoaderProgressState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchLoaderProgress.pending, (state: LoaderProgressState) => {
        state.LoaderProgress.load = true;
      })
      .addCase(fetchLoaderProgress.fulfilled, (state: LoaderProgressState, action: any) => {
        state.LoaderProgress.data = action.payload;
        state.LoaderProgress.load = false;
      })
      .addCase(fetchLoaderProgress.rejected, (state: LoaderProgressState) => {
        state.LoaderProgress.load = false;
      });
  },
});

export default LoaderProgressSlice.reducer;

export const fetchLoaderProgress = createAsyncThunk(
  "LoaderProgress/fetchLoaderProgress",
  async (
    param: { apiParam: { project_pin: any } },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getPdfProgressData", {
        project_pin: param.apiParam.project_pin,
      });
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
