import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";

interface CompleteAssessmentState {
  CompleteAssessment: {
    load: boolean;
    data: [];
  };
}

export const CompleteAssessmentSlice = createSlice({
  name: "CompleteAssessment",
  initialState: {
    CompleteAssessment: {
      load: false,
      data: [],
    },
  } as CompleteAssessmentState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchCompleteAssessment.pending, (state: CompleteAssessmentState) => {
        state.CompleteAssessment.load = true;
      })
      .addCase(fetchCompleteAssessment.fulfilled, (state: CompleteAssessmentState, action: any) => {
        state.CompleteAssessment.data = action.payload;
        state.CompleteAssessment.load = false;
      })
      .addCase(fetchCompleteAssessment.rejected, (state: CompleteAssessmentState) => {
        state.CompleteAssessment.load = false;
      });
  },
});

export default CompleteAssessmentSlice.reducer;

export const fetchCompleteAssessment = createAsyncThunk(
  "CompleteAssessment/fetchCompleteAssessment",
  async (param: { apiParam: { project_pin: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/completeAssessment", {
        project_pin: param.apiParam.project_pin
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error in Login", error);
    }
  }
);
