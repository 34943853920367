import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
  } from "../../SweetAlert";

interface CloseIssueState {
  CloseIssue: {
    load: boolean;
    data: [];
  };
}

export const CloseIssueSlice = createSlice({
  name: "closeissue",
  initialState: {
    CloseIssue: {
      load: false,
      data: [],
    },
  } as CloseIssueState,
  reducers: {},
});

export default CloseIssueSlice.reducer;

export const closeissue = createAsyncThunk(
  "closeissue/closeissue",
  async (param: {apiParam: { issue_pin: any, comment: any, warehouse_id: any }, navigation: any}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/issueClosed", {
        verified_issue_pin: param.apiParam.issue_pin,
        warehouse_id: param.apiParam.warehouse_id,
        comment: param.apiParam.comment
      });
      thunkAPI.dispatch(toggleLoading());

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => {
            handleonConfirm()
          },
        })
      );

      const handleonConfirm = () => {
        param.navigation("/verified-issue-summary");
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);


