import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface PaymentHistoryListState {
  PaymentHistoryList: {
    load: boolean;
    data: [];
  };
}

export const inspectionSlice = createSlice({
  name: "PaymentHistoryList",
  initialState: {
    PaymentHistoryList: {
      load: false,
      data: [],
    },
  } as PaymentHistoryListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchPaymentHistoryList.pending, (state: PaymentHistoryListState) => {
        state.PaymentHistoryList.load = true;
      })
      .addCase(fetchPaymentHistoryList.fulfilled, (state: PaymentHistoryListState, action: any) => {
        state.PaymentHistoryList.data = action.payload;
        state.PaymentHistoryList.load = false;
      })
      .addCase(fetchPaymentHistoryList.rejected, (state: PaymentHistoryListState) => {
        state.PaymentHistoryList.load = false;
      });
  },
});

export default inspectionSlice.reducer;

export const fetchPaymentHistoryList = createAsyncThunk(
  "PaymentHistoryList/fetchPaymentHistoryList",
  async (param: {apiParam : {page: any,per_page: any, search: any}}, thunkAPI) => {
    const company_id = localStorage.getItem("_tpi_firm_id");
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("api/GetCreditSystemPaymentHistory?company_id=" + company_id + "&per_page=" + param.apiParam.per_page + "&page=" + param.apiParam.page + "&search=" + param.apiParam.search );
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
