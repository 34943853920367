import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface OperatorIncidentTrendsState {
  OperatorIncidentTrends: {
    load: boolean;
    data: [];
  };
}

export const OperatorIncidentTrendsSlice = createSlice({
  name: "OperatorIncidentTrends",
  initialState: {
    OperatorIncidentTrends: {
      load: false,
      data: [],
    },
  } as OperatorIncidentTrendsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder      
      .addCase(getOperatorIncidentTrends.pending, (state: OperatorIncidentTrendsState) => {
        state.OperatorIncidentTrends.load = true;
      })
      .addCase(getOperatorIncidentTrends.fulfilled, (state: OperatorIncidentTrendsState, action: any) => {
        state.OperatorIncidentTrends.data = action.payload;
        state.OperatorIncidentTrends.load = false;
      })
      .addCase(getOperatorIncidentTrends.rejected, (state: OperatorIncidentTrendsState) => {
        state.OperatorIncidentTrends.load = false;
      });
  },
});

export default OperatorIncidentTrendsSlice.reducer;

export const getOperatorIncidentTrends = createAsyncThunk(
    "OperatorIncidentTrends/getOperatorIncidentTrends",
    async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any }}, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.get("management/v1/getOperatorIncidentTrends?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration);
        thunkAPI.dispatch(toggleLoading());
  
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);