import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ManagementUserSummaryState {
    ManagementUserSummary: {
    load: boolean;
    data: [];
  };
}

export const CouponWarehouseListSlice = createSlice({
  name: "ManagementUserSummary",
  initialState: {
    ManagementUserSummary: {
      load: false,
      data: [],
    },
  } as ManagementUserSummaryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchManagementUserSummary.pending, (state: ManagementUserSummaryState) => {
        state.ManagementUserSummary.load = true;
      })
      .addCase(fetchManagementUserSummary.fulfilled, (state: ManagementUserSummaryState, action: any) => {
        state.ManagementUserSummary.data = action.payload;
        state.ManagementUserSummary.load = false;
      })
      .addCase(fetchManagementUserSummary.rejected, (state: ManagementUserSummaryState) => {
        state.ManagementUserSummary.load = false;
      });
  },
});

export default CouponWarehouseListSlice.reducer;

export const fetchManagementUserSummary = createAsyncThunk(
    "ManagementUserSummary/fetchManagementUserSummary",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/managementUserList", {
                company_id: param.apiParam.company_id
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
