import SweetAlert from "react-bootstrap-sweetalert";

const SAlert = (props) => {
  return (
    <>
      <SweetAlert {...props}>
        {props.message}
      </SweetAlert>
    </>
  );
};

export default SAlert;
