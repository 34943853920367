import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardState {
    dashboard: {
        load: boolean;
        data: [];
    };
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        dashboard: {
            load: false,
            data: [],
        },
    } as DashboardState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchDashboardCount.pending, (state: DashboardState) => {
                state.dashboard.load = true;
            })
            .addCase(fetchDashboardCount.fulfilled, (state: DashboardState, action: any) => {
                state.dashboard.data = action.payload;
                state.dashboard.load = false;
            })
            .addCase(fetchDashboardCount.rejected, (state: DashboardState) => {
                state.dashboard.load = false;
            });
    },
});

export default dashboardSlice.reducer;

export const fetchDashboardCount = createAsyncThunk(
    "dashboard/fetchDashboardCount",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/getWarehouseLayoutRackInspectorCount", {
                warehouse_id: param.apiParam.warehouse_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

export const fetchWADashboardCount = createAsyncThunk(
    "dashboard/fetchDashboardCount",
    async (param: any) => {        
        try {
            const { data } = await DataServer.post("api/warehouseAdminUsersCount", {
                warehouse_id: param.apiParam.warehouse_id
        });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

