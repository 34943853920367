import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ScheduleDataState {
  ScheduleData: {
    load: boolean;
    data: [];
  };
}

export const ScheduleDataSlice = createSlice({
  name: "ScheduleData",
  initialState: {
    ScheduleData: {
      load: false,
      data: [],
    },
  } as ScheduleDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchScheduleData.pending, (state: ScheduleDataState) => {
        state.ScheduleData.load = true;
      })
      .addCase(fetchScheduleData.fulfilled, (state: ScheduleDataState, action: any) => {
        state.ScheduleData.data = action.payload;
        state.ScheduleData.load = false;
      })
      .addCase(fetchScheduleData.rejected, (state: ScheduleDataState) => {
        state.ScheduleData.load = false;
      });
  },
});

export default ScheduleDataSlice.reducer;

export const fetchScheduleData = createAsyncThunk(
  "users/fetchScheduleData",
  async (param: any, thunkAPI) => {
    
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(`api/fetchInspectionScheduleDatewiseData/${param.apiParam.warehouse_id}`);
      thunkAPI.dispatch(toggleLoading());
      return data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);
