
import "../../assets/css/views/notification.scss";
import NoData from "../NoData";
import RackHealthNotification from "./RackHealthNotification";

export default function Notification(props: any) {
    return <div className="notification header-notification">
        <h2>Notifications</h2>

        {/* <ScheduleNotification titleAndDate="Today’s Schedule Alert - July, 28 2023" TotalIssue="20" dailyInspectionStatus="Incomplete" detailednspectionStatus="Complete" time="Today at 9:42 AM" ></ScheduleNotification> */}
        <div className="scroll_not">
            {
                props?.NotificationData?.length === 0 ? <NoData sub_title="There are no notifications to display."></NoData> :
                props?.NotificationData?.map((data: any) => {
                    return <RackHealthNotification  data={data} />
                })
            }
        </div>
        {/* <ScheduleReminder message="Reminder - Expert Third Party Inspection is scheduled for Today" /> */}
        {/* <ScheduleReminder message="Today's schedule has been completed and data synchronized successfully" /> */}
        {
            props?.NotificationData?.length === 0 ? " " : 
            <div className="btn-all-notification">
                <a href="/notifications" className="link_btn">View All Notifications</a>
            </div>
        }
    </div> 
}
