import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { deActivateSweetAlert } from "../redux/slice/SweetAlert";
import { SweetAlert } from "../reusable";

interface alertsProps {}

const SweetAlertComponent: FC<alertsProps> = () => {
  const dispatch = useAppDispatch();

  const state = useAppSelector((state) => state.sweetAlert);
  const handleOnClick = () => {
    dispatch(deActivateSweetAlert());
  };
  return <SweetAlert {...state} onCancel={() => handleOnClick()} />;
};

export default SweetAlertComponent;
