import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface UserDeviceDetails {
    UserDeviceDetails: {
        load: boolean;
        data: [];
    };
}

export const NotificationSlice = createSlice({
    name: "UserDeviceDetails",
    initialState: {
        UserDeviceDetails: {
            load: false,
            data: [],
        },
    } as UserDeviceDetails,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchUserDeviceDetails.pending, (state: UserDeviceDetails) => {
                state.UserDeviceDetails.load = true;
            })
            .addCase(fetchUserDeviceDetails.fulfilled, (state: UserDeviceDetails, action: any) => {
                state.UserDeviceDetails.data = action.payload;
                state.UserDeviceDetails.load = false;
            })
            .addCase(fetchUserDeviceDetails.rejected, (state: UserDeviceDetails) => {
                state.UserDeviceDetails.load = false;
            })
            .addCase(EnableMdmSettingsStatus.pending, (state: UserDeviceDetails) => {
                state.UserDeviceDetails.load = true;
            })
            .addCase(EnableMdmSettingsStatus.fulfilled, (state: UserDeviceDetails, action: any) => {
                state.UserDeviceDetails.data = action.payload;
                state.UserDeviceDetails.load = false;
            })
            .addCase(EnableMdmSettingsStatus.rejected, (state: UserDeviceDetails) => {
                state.UserDeviceDetails.load = false;
            });;
    },
});

export default NotificationSlice.reducer;

export const fetchUserDeviceDetails = createAsyncThunk(
    "UserDeviceDetails/fetchUserDeviceDetails",
    async (param: {apiParam: { user_id: any }}, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/GetUserDeviceDetails", {
                user_id: param.apiParam.user_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error", error);
        }
    }
);

export const ForgetUserDeviceDetails = createAsyncThunk(
    "UserDeviceDetails/ForgetUserDeviceDetails",
    async (param: {apiParam: { user_id: any }, navigation: any}, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/forgetMDMSettings", {
                user_id: param.apiParam.user_id
            });

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            )

                const handleonConfirm = () => {
                    param.navigation("/user-summary");
                    thunkAPI.dispatch(deActivateSweetAlert());
                };
            return data.data;
        } catch (error) {
            // console.log("Error", error);
        }
    }
);

export const EnableMdmSettings = createAsyncThunk(
    "UserDeviceDetails/ForgetUserDeviceDetails",
    async (param: {apiParam: { company_id: any, mdm: any }, navigation: any}, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/MDMSettings", {
                company_id: param.apiParam.company_id,
                mdm: param.apiParam.mdm
            });

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            )

                const handleonConfirm = () => {
                    param.navigation("/company-summary");
                    thunkAPI.dispatch(deActivateSweetAlert());
                };
            return data.data;
        } catch (error) {
            // console.log("Error", error);
        }
    }
);

export const EnableMdmSettingsStatus = createAsyncThunk(
    "CompanyDeviceDetails/EnableMdmSettingsStatus",
    async (param: {apiParam: { company_id: any, mdm: any }, navigation: any}, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getMDMSettingStatus", {
                company_id: param.apiParam.company_id,
            });
            return data;
        } catch (error) {
            // console.log("Error", error);
        }
    }
);
