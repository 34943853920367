import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface ReportedIssueDetailsState {
  TpiReportedIssueDetails: {
    load: boolean;
    data: [];
  };
}

export const TpiReportedIssueDetailsSlice = createSlice({
  name: "TpiReportedIssueDetails",
  initialState: {
    TpiReportedIssueDetails: {
      load: false,
      data: [],
    },
  } as ReportedIssueDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiReportedIssueDetails.pending, (state: ReportedIssueDetailsState) => {
        state.TpiReportedIssueDetails.load = true;
      })
      .addCase(fetchTpiReportedIssueDetails.fulfilled, (state: ReportedIssueDetailsState, action: any) => {
        state.TpiReportedIssueDetails.data = action.payload;
        state.TpiReportedIssueDetails.load = false;
      })
      .addCase(fetchTpiReportedIssueDetails.rejected, (state: ReportedIssueDetailsState) => {
        state.TpiReportedIssueDetails.load = false;
      });
  },
});

export default TpiReportedIssueDetailsSlice.reducer;

export const fetchTpiReportedIssueDetails = createAsyncThunk(
  "TpiReportedIssueDetails/fetchTpiReportedIssueDetails",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/viewTpiReportedIssueData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;.
      // console.log("data.data", error)
    }
  }
);
