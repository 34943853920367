import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TpiCompanyLogoState {
    TpiCompanyLogo: {
        load: boolean;
        data: [];
    };
}

export const TpiCompanyLogoSlice = createSlice({
    name: "TpiCompanyLogo",
    initialState: {
        TpiCompanyLogo: {
            load: false,
            data: [],
        },
    } as TpiCompanyLogoState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchTpiCompanyLogo.pending, (state: TpiCompanyLogoState) => {
                state.TpiCompanyLogo.load = true;
            })
            .addCase(fetchTpiCompanyLogo.fulfilled, (state: TpiCompanyLogoState, action: any) => {
                state.TpiCompanyLogo.data = action.payload;
                state.TpiCompanyLogo.load = false;
            })
            .addCase(fetchTpiCompanyLogo.rejected, (state: TpiCompanyLogoState) => {
                state.TpiCompanyLogo.load = false;
            });
    },
});

export default TpiCompanyLogoSlice.reducer;

export const fetchTpiCompanyLogo = createAsyncThunk(
    "TpiCompanyLogo/fetchTpiCompanyLogo",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/getCompanyLogo", {
                company_id: param.apiParam.company_id
            });

            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
