import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface IncidentsOverTimeState {
  IncidentsOverTime: {
    load: boolean;
    data: [];
  };
}

export const IncidentsOverTimeSlice = createSlice({
  name: "IncidentsOverTime",
  initialState: {
    IncidentsOverTime: {
      load: false,
      data: [],
    },
  } as IncidentsOverTimeState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getIncidentsOverTime.pending, (state: IncidentsOverTimeState) => {
        state.IncidentsOverTime.load = true;
      })
      .addCase(getIncidentsOverTime.fulfilled, (state: IncidentsOverTimeState, action: any) => {
        state.IncidentsOverTime.data = action.payload;
        state.IncidentsOverTime.load = false;
      })
      .addCase(getIncidentsOverTime.rejected, (state: IncidentsOverTimeState) => {
        state.IncidentsOverTime.load = false;
      })
      .addCase(getOperatorIncidentsOverTime.pending, (state: IncidentsOverTimeState) => {
        state.IncidentsOverTime.load = true;
      })
      .addCase(getOperatorIncidentsOverTime.fulfilled, (state: IncidentsOverTimeState, action: any) => {
        state.IncidentsOverTime.data = action.payload;
        state.IncidentsOverTime.load = false;
      })
      .addCase(getOperatorIncidentsOverTime.rejected, (state: IncidentsOverTimeState) => {
        state.IncidentsOverTime.load = false;
      });
  },
});

export default IncidentsOverTimeSlice.reducer;

export const getIncidentsOverTime = createAsyncThunk(
  "IncidentsOvverTime/getIncidentsOverTime",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getIncidentsOverTime?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);

export const getOperatorIncidentsOverTime = createAsyncThunk(
  "IncidentsOvverTime/getOperatorIncidentsOverTime",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any, operator_id: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/operatorSummary/v1/getIncidentsOverTime?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration + "&operator_id=" + param.apiParam.operator_id);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
  