import { createSlice } from "@reduxjs/toolkit";

export const ErrorHandling = createSlice({
    name: "errormessage",
    initialState: [],
    reducers: {
        ErrorMessage: (state, error: any) => state
    }
})

export const selectError = (state: any) => state.errormessage;

export const { ErrorMessage } = ErrorHandling.actions;
export default ErrorHandling.reducer;