import React, { useEffect, useState } from 'react';
import decryptData from '../decryptData';

export default function DateConverter(props: any) {
    var utcDate;
    const [timeZone, settimeZone] = useState("");

    
    if(props.date){
        if((props?.date).includes("T") || (props?.date).includes("Z")) {
            utcDate = new Date(props?.date);
        } else {
            utcDate = new Date(props?.date + ' UTC');
        }
    }
    
    const loginUserData = decryptData();
    const role_id = loginUserData?.data.role_id;
    
    const LocalTimeZone = loginUserData?.warehouse_timeZone;

    return (
        <div>
            {
                role_id === 9 || role_id === 11 ?
                utcDate?.toLocaleString("en-US", {
                    timeZone: props?.timeZone,
                }) : 
                utcDate?.toLocaleString("en-US", {
                    timeZone: `${loginUserData?.warehouse_timeZone}`,
                })
            }
        </div>
    )
}
