import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ReportedIssuesDetailsState {
    reportreportedissuedetails: {
        load: boolean;
        data: [];
    };
}

export const reportreportedissuedetailsSlice = createSlice({
    name: "reportreportedissuedetails",
    initialState: {
        reportreportedissuedetails: {
            load: false,
            data: [],
        },
    } as ReportedIssuesDetailsState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchReportedIssueDetails.pending, (state: ReportedIssuesDetailsState) => {
                state.reportreportedissuedetails.load = true;
            })
            .addCase(fetchReportedIssueDetails.fulfilled, (state: ReportedIssuesDetailsState, action: any) => {
                state.reportreportedissuedetails.data = action.payload;
                state.reportreportedissuedetails.load = false;
            })
            .addCase(fetchReportedIssueDetails.rejected, (state: ReportedIssuesDetailsState) => {
                state.reportreportedissuedetails.load = false;
            });
    },
});

export default reportreportedissuedetailsSlice.reducer;

export const fetchReportedIssueDetails = createAsyncThunk(
    "reportreportedissuedetails/fetchReportedIssueDetails",
    async (param: { apiParam: { id: any, filter: any, warehouse_id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getReportedIssuesDetails", {
                inspection_pin: param.apiParam.id,
                warehouse_id: param.apiParam.warehouse_id,
                filter: param.apiParam.filter
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
