import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface SyncInspectionCountState {
  SyncInspectionCount: {
    load: boolean;
    data: [];
  };
}

export const SyncInspectionCountSlice = createSlice({
  name: "SyncInspectionCount",
  initialState: {
    SyncInspectionCount: {
      load: false,
      data: [],
    },
  } as SyncInspectionCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSyncInspectionCount.pending, (state: SyncInspectionCountState) => {
        state.SyncInspectionCount.load = true;
      })
      .addCase(fetchSyncInspectionCount.fulfilled, (state: SyncInspectionCountState, action: any) => {
        state.SyncInspectionCount.data = action.payload;
        state.SyncInspectionCount.load = false;
      })
      .addCase(fetchSyncInspectionCount.rejected, (state: SyncInspectionCountState) => {
        state.SyncInspectionCount.load = false;
      });
  },
});

export default SyncInspectionCountSlice.reducer;

export const fetchSyncInspectionCount = createAsyncThunk(
  "SyncInspectionCount/fetchSyncInspectionCount",
  async (param: {apiParam: {project_pin: any, warehouse_id: any}},thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/rackSyncInspectionCount", {
        warehouse_id: param.apiParam.warehouse_id,
        project_pin: param.apiParam.project_pin
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
