import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface MHEIncidentTrendsState {
  MHEIncidentTrends: {
    load: boolean;
    data: [];
  };
}

export const MHEIncidentTrendsSlice = createSlice({
  name: "MHEIncidentTrends",
  initialState: {
    MHEIncidentTrends: {
      load: false,
      data: [],
    },
  } as MHEIncidentTrendsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getMHEIncidentTrends.pending, (state: MHEIncidentTrendsState) => {
        state.MHEIncidentTrends.load = true;
      })
      .addCase(getMHEIncidentTrends.fulfilled, (state: MHEIncidentTrendsState, action: any) => {
        state.MHEIncidentTrends.data = action.payload;
        state.MHEIncidentTrends.load = false;
      })
      .addCase(getMHEIncidentTrends.rejected, (state: MHEIncidentTrendsState) => {
        state.MHEIncidentTrends.load = false;
      })
      .addCase(getOperatorMHEIncidentTrends.pending, (state: MHEIncidentTrendsState) => {
        state.MHEIncidentTrends.load = true;
      })
      .addCase(getOperatorMHEIncidentTrends.fulfilled, (state: MHEIncidentTrendsState, action: any) => {
        state.MHEIncidentTrends.data = action.payload;
        state.MHEIncidentTrends.load = false;
      })
      .addCase(getOperatorMHEIncidentTrends.rejected, (state: MHEIncidentTrendsState) => {
        state.MHEIncidentTrends.load = false;
      });
  },
});

export default MHEIncidentTrendsSlice.reducer;

export const getMHEIncidentTrends = createAsyncThunk(
  "MHEIncidentTrends/getMHEIncidentTrends",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getMHEIncidentTrends?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
 
export const getOperatorMHEIncidentTrends = createAsyncThunk(
  "MHEIncidentTrends/getOperatorMHEIncidentTrends",
  async (param: {apiParam: { company_id: any, warehouse_id: any, operator_id: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/operatorSummary/v1/getMHEIncidentTrends?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&operator_id=" + param.apiParam.operator_id);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);