import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const NoData = (props: any) => {
    return (
        <div className="text-center no_data">
            <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#b9b9b9" }}></FontAwesomeIcon>
            <h6>No Data Available</h6>
            <span>{props.sub_title}</span>
        </div>
    )
}

export default NoData