import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../../sensorconfig.axios";

interface OperatorSummaryListState {
  OperatorSummaryList: {
    load: boolean;
    data: [];
  };
}

export const OperatorSummaryListSlice = createSlice({
  name: "OperatorSummaryList",
  initialState: {
    OperatorSummaryList: {
      load: false,
      data: [],
    },
  } as OperatorSummaryListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOperatorSummaryList.pending, (state) => {
        state.OperatorSummaryList.load = true;
      })
      .addCase(getOperatorSummaryList.fulfilled, (state, action) => {
        state.OperatorSummaryList.data = action.payload;
        state.OperatorSummaryList.load = false;
      })
      .addCase(getOperatorSummaryList.rejected, (state) => {
        state.OperatorSummaryList.load = false;
      });
  },
});

export default OperatorSummaryListSlice.reducer;

export const getOperatorSummaryList = createAsyncThunk(
  "OperatorSummaryList/getOperatorSummaryList",
  async (param: any, thunkAPI) => {
    try {
      const { data, status } = await DataServer.get(
        "management/operatorSummary/v1/getOperatorSummaryList?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&per_page=" + param.apiParam.per_page + "&current_page=" + param.apiParam.current_page + "&search=" + param.apiParam.search
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
