import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TestingTestReadingState {
  TestingTestReading: {
    load: boolean;
    data: [];
  };
}

export const TestingTestReadingSlice = createSlice({
  name: "TestingTestReading",
  initialState: {
    TestingTestReading: {
      load: false,
      data: [],
    },
  } as TestingTestReadingState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTestingTestReading.pending, (state: TestingTestReadingState) => {
        state.TestingTestReading.load = true;
      })
      .addCase(fetchTestingTestReading.fulfilled, (state: TestingTestReadingState, action: any) => {
        state.TestingTestReading.data = action.payload;
        state.TestingTestReading.load = false;
      })
      .addCase(fetchTestingTestReading.rejected, (state: TestingTestReadingState) => {
        state.TestingTestReading.load = false;
      });
  },
});

export default TestingTestReadingSlice.reducer;

export const fetchTestingTestReading = createAsyncThunk(
  "TestingTestReading/fetchTestingTestReading",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getTestReadingSummary", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
