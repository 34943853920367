import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface OperatorUtilizationTrendsState {
  OperatorUtilizationTrends: {
    load: boolean;
    data: [];
  };
}

export const OperatorUtilizationTrendsSlice = createSlice({
  name: "OperatorUtilizationTrends",
  initialState: {
    OperatorUtilizationTrends: {
      load: false,
      data: [],
    },
  } as OperatorUtilizationTrendsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getOperatorUtilizationTrends.pending, (state: OperatorUtilizationTrendsState) => {
        state.OperatorUtilizationTrends.load = true;
      })
      .addCase(getOperatorUtilizationTrends.fulfilled, (state: OperatorUtilizationTrendsState, action: any) => {
        state.OperatorUtilizationTrends.data = action.payload;
        state.OperatorUtilizationTrends.load = false;
      })
      .addCase(getOperatorUtilizationTrends.rejected, (state: OperatorUtilizationTrendsState) => {
        state.OperatorUtilizationTrends.load = false;
      });
  },
});

export default OperatorUtilizationTrendsSlice.reducer;

export const getOperatorUtilizationTrends = createAsyncThunk(
  "OperatorUtilizationTrends/getOperatorUtilizationTrends",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getOperatorUtilizationTrends?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
  