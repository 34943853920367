import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface SubscriptionDetailsState {
  SubscriptionDetails: {
    load: boolean;
    data: [];
  };
}

export const SubscriptionDetailsSlice = createSlice({
  name: "SubscriptionDetails",
  initialState: {
    SubscriptionDetails: {
      load: false,
      data: [],
    },
  } as SubscriptionDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSubscriptionDetails.pending, (state: SubscriptionDetailsState) => {
        state.SubscriptionDetails.load = true;
      })
      .addCase(fetchSubscriptionDetails.fulfilled, (state: SubscriptionDetailsState, action: any) => {
        state.SubscriptionDetails.data = action.payload;
        state.SubscriptionDetails.load = false;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state: SubscriptionDetailsState) => {
        state.SubscriptionDetails.load = false;
      })
  },
});

export default SubscriptionDetailsSlice.reducer;

export const fetchSubscriptionDetails = createAsyncThunk(
  "SubscriptionDetails/fetchSubscriptionDetails",
  async (param: { apiParam: { tpi_firm_id: any } }, thunkAPI) => {
    try {
      // const { data } = await DataServer.get("api/GetSubscriptionSettingDetails?company_id=" + company_id);
      const { data } = await DataServer.get("api/GetSubscriptionSettingDetails?company_id=" + param.apiParam.tpi_firm_id);
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);

