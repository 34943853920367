import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface ElementWiseIssueCountState {
    ElementWiseIssueCount: {
        load: boolean;
        data: [];
    };
}

export const ElementWiseIssueCountSlice = createSlice({
    name: "ElementWiseIssueCount",
    initialState: {
        ElementWiseIssueCount: {
            load: false,
            data: [],
        },
    } as ElementWiseIssueCountState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchElementWiseIssueCount.pending, (state: ElementWiseIssueCountState) => {
                state.ElementWiseIssueCount.load = true;
            })
            .addCase(fetchElementWiseIssueCount.fulfilled, (state: ElementWiseIssueCountState, action: any) => {
                state.ElementWiseIssueCount.data = action.payload;
                state.ElementWiseIssueCount.load = false;
            })
            .addCase(fetchElementWiseIssueCount.rejected, (state: ElementWiseIssueCountState) => {
                state.ElementWiseIssueCount.load = false;
            });
    },
});

export default ElementWiseIssueCountSlice.reducer;

export const fetchElementWiseIssueCount = createAsyncThunk(
    "ElementWiseIssueCount/fetchElementWiseIssueCount",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getElementWiseIssueCount", {
                project_pin: param.apiParam.project_pin,
                layout_id: param.apiParam.layout_id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
