import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface SyncSyncElementCountState {
  SyncElementCount: {
    load: boolean;
    data: [];
  };
}

export const SyncElementCountSlice = createSlice({
  name: "SyncElementCount",
  initialState: {
    SyncElementCount: {
      load: false,
      data: [],
    },
  } as SyncSyncElementCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSyncElementCount.pending, (state: SyncSyncElementCountState) => {
        state.SyncElementCount.load = true;
      })
      .addCase(fetchSyncElementCount.fulfilled, (state: SyncSyncElementCountState, action: any) => {
        state.SyncElementCount.data = action.payload;
        state.SyncElementCount.load = false;
      })
      .addCase(fetchSyncElementCount.rejected, (state: SyncSyncElementCountState) => {
        state.SyncElementCount.load = false;
      });
  },
});

export default SyncElementCountSlice.reducer;

export const fetchSyncElementCount = createAsyncThunk(
  "SyncElementCount/fetchSyncElementCount",
  async (param: {apiParam: {project_pin: any, warehouse_id: any}},thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getSyncTpiElementsCount", {
        warehouse_id: param.apiParam.warehouse_id,
        project_pin: param.apiParam.project_pin
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
