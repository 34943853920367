import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../../SweetAlert";
import { toggleLoading } from "../../LoadingSlice";

export interface PackageData {
    packageType: string;
    packageName: string;
    companyType: string;
    packageDescription: string;
    numberOfBays: number;
    numberOfUsers: number;
    numberOfStorage: number;
    finalAmount: number;
    validity: number;
    status: number;
}

interface PackageState {
    package: {
        load: boolean;
        data: Array<PackageData>;
    };
    packageData?: PackageData;
}
const initialState = {
    package: {
        load: false,
        data: [],
    },
    packageData: {
        packageType: "",
        packageName: "",
        companyType: "",
        packageDescription: "",
        numberOfBays: 0,
        numberOfUsers: 0,
        numberOfStorage: 0,
        finalAmount: 0,
        validity: 0,
        status: 1,
    },
} as PackageState;

export const PackageSlice = createSlice({
    name: "package",
    initialState,
    reducers: {
        resetPackageData: (state: PackageState) => {
            state.packageData = initialState.packageData;
        },
        updatePackageData: (state: PackageState, action: { payload: PackageData }) => {
            state.packageData = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
        .addCase(fetchPackageList.pending, (state: PackageState) => {
            state.package.load = true;
        })
        .addCase(fetchPackageList.fulfilled, (state: PackageState, action: any) => {
            state.package.data = action.payload;
            state.package.load = false;
        })
        .addCase(fetchPackageList.rejected, (state: PackageState) => {
            state.package.load = false;
        })
    },
});
export const { updatePackageData, resetPackageData } = PackageSlice.actions;
export default PackageSlice.reducer;

export const fetchPackageList = createAsyncThunk(
    "package/fetchPackageList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/packages", {
                company_type: param.apiParam.company_type,
                per_page: param.apiParam.per_page,
                page: param.apiParam.page,
                search: param.apiParam.search
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const createPackage = createAsyncThunk(
    "package/createPackage",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/createPackage",
                param.apiParam
            );
            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/subscription-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
