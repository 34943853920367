import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TpiInspectionSummaryState {
  tpiInspectionlist: {
    load: boolean;
    data: [];
  };
}

export const inspectionSlice = createSlice({
  name: "tpiInspectionlist",
  initialState: {
    tpiInspectionlist: {
      load: false,
      data: [],
    },
  } as TpiInspectionSummaryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiInspectionList.pending, (state: TpiInspectionSummaryState) => {
        state.tpiInspectionlist.load = true;
      })
      .addCase(fetchTpiInspectionList.fulfilled, (state: TpiInspectionSummaryState, action: any) => {
        state.tpiInspectionlist.data = action.payload;
        state.tpiInspectionlist.load = false;
      })
      .addCase(fetchTpiInspectionList.rejected, (state: TpiInspectionSummaryState) => {
        state.tpiInspectionlist.load = false;
      });
  },
});

export default inspectionSlice.reducer;

export const fetchTpiInspectionList = createAsyncThunk(
  "tpiInspectionlist/fetchTpiInspectionList",
  async (param: {apiParam : {per_page: any, page: any, search: any, warehouse_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/tpInspectionsummary", {
        warehouse_id: param.apiParam.warehouse_id,
        per_page: param.apiParam.per_page,
        page: param.apiParam.page,
        search: param.apiParam.search
    });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
