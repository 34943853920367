import { createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../config.axios";
import { toggleLoading } from "../LoadingSlice";

export const ResetPasswordSlice = createSlice({
  name: "ResetPassword",
  initialState: {
    ResetPassword: {
      load: false,
      data: {
        id: 0,
        role_id: 0,
      },
      message: "",
    },
  },
  reducers: {
    ResetPasswordRequest: (state) => {
      state.ResetPassword.load = true;
    },
    ResetPasswordSuccess: (state, action) => {
      state.ResetPassword = action.payload;
      state.ResetPassword.load = false;
    },
    ResetPasswordFail: (state, action) => {
      state.ResetPassword.load = action.payload.load;
      state.ResetPassword.message = action.payload.message;
    },
  },
});

export const {
  ResetPasswordRequest,
  ResetPasswordSuccess,
  ResetPasswordFail,
} = ResetPasswordSlice.actions;

export default ResetPasswordSlice.reducer;

export const ForgetPassword =
  ({ email = "", base_url = "" }) =>
  async (dispatch: Function) => {
    try {
      dispatch(toggleLoading());
      const { data, status } = await DataServer.post("api/forgotPassword", {
        email: email,
        base_url: base_url,
      });

      dispatch(toggleLoading());
    } catch (error) {
      dispatch(toggleLoading());
      dispatch(
        ResetPasswordFail({
          isAuthenticated: false,
          load: false,
          message: error,
        })
      );
    }
  };
