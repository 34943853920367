import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface NoSeverityCountState {
    NoSeverityCount: {
        load: boolean;
        data: [];
    };
}

export const NoSeverityCountSlice = createSlice({
    name: "NoSeverityCount",
    initialState: {
        NoSeverityCount: {
            load: false,
            data: [],
        },
    } as NoSeverityCountState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchNoSeverityCount.pending, (state: NoSeverityCountState) => {
                state.NoSeverityCount.load = true;
            })
            .addCase(fetchNoSeverityCount.fulfilled, (state: NoSeverityCountState, action: any) => {
                state.NoSeverityCount.data = action.payload;
                state.NoSeverityCount.load = false;
            })
            .addCase(fetchNoSeverityCount.rejected, (state: NoSeverityCountState) => {
                state.NoSeverityCount.load = false;
            })
    },
});

export default NoSeverityCountSlice.reducer;

export const fetchNoSeverityCount = createAsyncThunk(
    "NoSeverityCount/fetchNoSeverityCount",
    async (param: { apiParam: { code: any, element_pin: any, warehouse_id: any } }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getHealthParameterNotSetCount", {
                warehouse_id: param.apiParam.warehouse_id,
                code: param.apiParam.code,
                element_pin: param.apiParam.element_pin
            });

            return data.data;

        } catch (error) {
            // return (error);
        }
    }
);
