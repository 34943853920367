import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface SafetyAccessoriesIssueCountState {
    SafetyAccessoriesIssueCount: {
        load: boolean;
        data: [];
    };
}

export const SafetyAccessoriesIssueCountSlice = createSlice({
    name: "SafetyAccessoriesIssueCount",
    initialState: {
        SafetyAccessoriesIssueCount: {
            load: false,
            data: [],
        },
    } as SafetyAccessoriesIssueCountState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchSafetyAccessoriesIssueCount.pending, (state: SafetyAccessoriesIssueCountState) => {
                state.SafetyAccessoriesIssueCount.load = true;
            })
            .addCase(fetchSafetyAccessoriesIssueCount.fulfilled, (state: SafetyAccessoriesIssueCountState, action: any) => {
                state.SafetyAccessoriesIssueCount.data = action.payload;
                state.SafetyAccessoriesIssueCount.load = false;
            })
            .addCase(fetchSafetyAccessoriesIssueCount.rejected, (state: SafetyAccessoriesIssueCountState) => {
                state.SafetyAccessoriesIssueCount.load = false;
            });
    },
});

export default SafetyAccessoriesIssueCountSlice.reducer;

export const fetchSafetyAccessoriesIssueCount = createAsyncThunk(
    "SafetyAccessoriesIssueCount/fetchSafetyAccessoriesIssueCount",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getSafetyAccessoriesIssueCount",{
                project_pin: param.apiParam.project_pin,
                layout_id: param.apiParam.layout_id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);
