import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TpiLayoutListState {
  TpiLayoutList: {
    load: boolean;
    data: [];
  };
}

export const TpiLayoutListSlice = createSlice({
  name: "TpiLayoutList",
  initialState: {
    TpiLayoutList: {
      load: false,
      data: [],
    },
  } as TpiLayoutListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiLayoutList.pending, (state: TpiLayoutListState) => {
        state.TpiLayoutList.load = true;
      })
      .addCase(fetchTpiLayoutList.fulfilled, (state: TpiLayoutListState, action: any) => {
        state.TpiLayoutList.data = action.payload;
        state.TpiLayoutList.load = false;
      })
      .addCase(fetchTpiLayoutList.rejected, (state: TpiLayoutListState) => {
        state.TpiLayoutList.load = false;
      });
  },
});

export default TpiLayoutListSlice.reducer;

export const fetchTpiLayoutList = createAsyncThunk(
  "TpiLayoutList/fetchTpiLayoutList",
  async (param: {apiParam: {project_pin: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getLayoutList", {
        project_pin: param.apiParam.project_pin
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
