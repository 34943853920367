import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";

interface isAuthRoute {
  children: JSX.Element;
}
const IsAuthRoute: FC<isAuthRoute> = ({ children }) => {
  let location = useLocation();

  const { user } = useAppSelector((state) => state.user);
  if (user.isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }
  return children;
};

export default IsAuthRoute;
