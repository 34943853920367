import { FC } from "react";
// import { Spinner, SpinnerProps } from "react-bootstrap";
import Alerts, { alertsProps } from "./Alerts";
// import Button, { buttonProps } from "./Button";
import Input, { inputProps } from "./Input";
import Toasts, { toastsProps } from "./Toasts";
import SAlert from "./SweetAlert";
import { SweetAlertOptionalProps } from "./SweetAlert/SweetAlert";


export const BTAlerts: FC<alertsProps> = (props) => <Alerts {...props} />;
// export const BTButton: FC<buttonProps> = (props) => <Button {...props} />;
export const BTInput: FC<inputProps> = (props) => <Input {...props} />;
export const BTToasts: FC<toastsProps> = (props) => <Toasts {...props} />;
// export const BTSpinner: FC<SpinnerProps> = (props) => <Spinner {...props} />;
export const SweetAlert: FC<SweetAlertOptionalProps> = (props) => <SAlert {...props} />;
