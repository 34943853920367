import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface PlanListState {
  PlanList: {
    load: boolean;
    data: [];
  };
}

export const inspectionSlice = createSlice({
  name: "PlanList",
  initialState: {
    PlanList: {
      load: false,
      data: [],
    },
  } as PlanListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchPlansList.pending, (state: PlanListState) => {
        state.PlanList.load = true;
      })
      .addCase(fetchPlansList.fulfilled, (state: PlanListState, action: any) => {
        state.PlanList.data = action.payload;
        state.PlanList.load = false;
      })
      .addCase(fetchPlansList.rejected, (state: PlanListState) => {
        state.PlanList.load = false;
      });
  },
});

export default inspectionSlice.reducer;

export const fetchPlansList = createAsyncThunk(
  "PlanList/fetchPlansList",
  async (param: {apiParam : {company_type: any, page: any,per_page: any, search: any}}, thunkAPI) => {

    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/packages?company_type=" + param.apiParam.company_type + "&per_page=" + param.apiParam.per_page + "&page=" + param.apiParam.page + "&search=" + param.apiParam.search );
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
