import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface StateData {
  city: string;
  cityId: number;
  stateId: number;
  stateName: string;
}

export interface CitiesState {
  cities: {
    load: boolean;
    data: Array<StateData>;
  };
}

export const CitiesSlice = createSlice({
  name: "cities",
  initialState: {
    cities: {
      load: false,
      data: [],
    },
  } as CitiesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.cities.load = true;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities.data = action.payload;
        state.cities.load = false;
      })
      .addCase(fetchCities.rejected, (state) => {
        state.cities.load = false;
      });
  },
});

export default CitiesSlice.reducer;

export const fetchCities = createAsyncThunk(
  "users/fetchCities",
  async (param: { apiParam: { countryId: any } }, thunkAPI) => {
    try {
      const { data, status } = await DataServer.post(
        "api/admin/cities",
        param.apiParam
      );
      if (status !== 200) throw "Login Error";
      return data.data;
    } catch (error) {
      // console.log("Error in Login", error);
    }
  }
);
