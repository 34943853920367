import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface SplicingIssueCountState {
    SplicingIssueCount: {
        load: boolean;
        data: [];
    };
}

export const SplicingIssueCountSlice = createSlice({
    name: "SplicingIssueCount",
    initialState: {
        SplicingIssueCount: {
            load: false,
            data: [],
        },
    } as SplicingIssueCountState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchSplicingIssueCount.pending, (state: SplicingIssueCountState) => {
                state.SplicingIssueCount.load = true;
            })
            .addCase(fetchSplicingIssueCount.fulfilled, (state: SplicingIssueCountState, action: any) => {
                state.SplicingIssueCount.data = action.payload;
                state.SplicingIssueCount.load = false;
            })
            .addCase(fetchSplicingIssueCount.rejected, (state: SplicingIssueCountState) => {
                state.SplicingIssueCount.load = false;
            });
    },
});

export default SplicingIssueCountSlice.reducer;

export const fetchSplicingIssueCount = createAsyncThunk(
    "SplicingIssueCount/fetchSplicingIssueCount",
    async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getSplicingIssueDataCount", {
                project_pin: param.apiParam.project_pin,
                layout_id: param.apiParam.layout_id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return error;
        }
    }
);