import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../../sensorconfig.axios";

interface OperatorsStatisticsState {
  OperatorsStatistics: {
    load: boolean;
    data: [];
  };
}

export const OperatorsStatisticsSlice = createSlice({
  name: "OperatorsStatistics",
  initialState: {
    OperatorsStatistics: {
      load: false,
      data: [],
    },
  } as OperatorsStatisticsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOverallOperatorsStatistics.pending, (state) => {
        state.OperatorsStatistics.load = true;
      })
      .addCase(getOverallOperatorsStatistics.fulfilled, (state, action) => {
        state.OperatorsStatistics.data = action.payload;
        state.OperatorsStatistics.load = false;
      })
      .addCase(getOverallOperatorsStatistics.rejected, (state) => {
        state.OperatorsStatistics.load = false;
      })
      .addCase(getOperatorsStatistics.pending, (state) => {
        state.OperatorsStatistics.load = true;
      })
      .addCase(getOperatorsStatistics.fulfilled, (state, action) => {
        state.OperatorsStatistics.data = action.payload;
        state.OperatorsStatistics.load = false;
      })
      .addCase(getOperatorsStatistics.rejected, (state) => {
        state.OperatorsStatistics.load = false;
      });
  },
});

export default OperatorsStatisticsSlice.reducer;

export const getOverallOperatorsStatistics = createAsyncThunk(
  "OperatorsStatistics/getOverallOperatorsStatistics",
  async (param: any, thunkAPI) => {
    try {
      const { data, status } = await DataServer.get(
        "management/operatorSummary/v1/getOverallOperatorsStatistics?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const getOperatorsStatistics = createAsyncThunk(
  "OperatorsStatistics/getOperatorsStatistics",
  async (param: any, thunkAPI) => {
    try {
      const { data, status } = await DataServer.get(
        "management/operatorSummary/v1/getOperatorStatistics?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&operator_id=" + param.apiParam.operator_id
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
