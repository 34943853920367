import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface CouponData {
    coupon_name: string;
    coupon_type: string;
    limit: string;
    discount: number;
    expired_at: string;
    condition: string;
    description: string;
    coupon_status: number;
}

interface CouponState {
    coupon: {
        load: boolean;
        data: Array<CouponData>;
    };
    couponData?: CouponData;
}
const initialState = {
    coupon: {
        load: false,
        data: [],
    },
    couponData: {
        coupon_name: "",
        coupon_type: "",
        limit: "",
        discount: 0,
        expired_at: "",
        condition: "",
        description: "",
        coupon_status: 0,
    },
} as CouponState;

export const CouponSlice = createSlice({
    name: "coupon",
    initialState,
    reducers: {
        resetCouponData: (state: CouponState) => {
            state.couponData = initialState.couponData;
        },
        updateCouponData: (state: CouponState, action: { payload: CouponData }) => {
            state.couponData = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchCouponList.pending, (state: CouponState) => {
                state.coupon.load = true;
            })
            .addCase(fetchCouponList.fulfilled, (state: CouponState, action: any) => {
                state.coupon.data = action.payload;
                state.coupon.load = false;
            })
            .addCase(fetchCouponList.rejected, (state: CouponState) => {
                state.coupon.load = false;
            })
    },
});
export const { updateCouponData, resetCouponData } = CouponSlice.actions;
export default CouponSlice.reducer;

export const fetchCouponList = createAsyncThunk(
    "coupon/fetchCouponList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getCouponSummaryData", {
                page: param.apiParam.page,
                per_page: param.apiParam.per_page,
                search: param.apiParam.search
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);

export const addCoupon = createAsyncThunk(
    "coupon/addCoupon",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/postCouponData",
                param.apiParam
            );
            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/coupon-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
