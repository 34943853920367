import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface MasterLayoutState {
  MasterLayout: {
    load: boolean;
    data: [];
  };
}

export const MasterLayoutSlice = createSlice({
  name: "MasterLayout",
  initialState: {
    MasterLayout: {
      load: false,
      data: [],
    },
  } as MasterLayoutState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchMasterLayout.pending, (state: MasterLayoutState) => {
        state.MasterLayout.load = true;
      })
      .addCase(fetchMasterLayout.fulfilled, (state: MasterLayoutState, action: any) => {
        state.MasterLayout.data = action.payload;
        state.MasterLayout.load = false;
      })
      .addCase(fetchMasterLayout.rejected, (state: MasterLayoutState) => {
        state.MasterLayout.load = false;
      });
  },
});

export default MasterLayoutSlice.reducer;

export const fetchMasterLayout = createAsyncThunk(
  "MasterLayout/fetchMasterLayout",
  async (param: { apiParam: { layout_id: any } }) => {
    const warehouse_id = localStorage.getItem("_warehouse_id");
    try {
      const data:any = await DataServer.post("api/getMasterLayoutData", {
        warehouse_id: warehouse_id,
        layout_id: param.apiParam.layout_id
      });
       
      return data.data.data;
      
    } catch (error) {
      return error;
    }
  }
);
