import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface TpiScheduleDataState {
  TpiScheduleData: {
    load: boolean;
    data: [];
  };
}

export const TpiScheduleDataSlice = createSlice({
  name: "TpiScheduleData",
  initialState: {
    TpiScheduleData: {
      load: false,
      data: [],
    },
  } as TpiScheduleDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiScheduleData.pending, (state: TpiScheduleDataState) => {
        state.TpiScheduleData.load = true;
      })
      .addCase(fetchTpiScheduleData.fulfilled, (state: TpiScheduleDataState, action: any) => {
        state.TpiScheduleData.data = action.payload;
        state.TpiScheduleData.load = false;
      })
      .addCase(fetchTpiScheduleData.rejected, (state: TpiScheduleDataState) => {
        state.TpiScheduleData.load = false;
      });
  },
});

export default TpiScheduleDataSlice.reducer;

export const fetchTpiScheduleData = createAsyncThunk(
  "TpiScheduleData/fetchTpiScheduleData",
  async (param: { apiParam: {tpi_firm_id: any, login_user_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getTpiInspectionScheduleData", {
        tpi_firm_id: param.apiParam.tpi_firm_id,
        login_user_id: param.apiParam.login_user_id
      });
      return data.data;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error);
    }
  }
);
