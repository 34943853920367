import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";

interface ExecutiveSummaryState {
  ExecutiveSummary: {
    load: boolean;
    data: [];
  };
}

export const ExecutiveSummarySlice = createSlice({
  name: "ExecutiveSummary",
  initialState: {
    ExecutiveSummary: {
      load: false,
      data: [],
    },
  } as ExecutiveSummaryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchExecutiveSummary.pending, (state: ExecutiveSummaryState) => {
        state.ExecutiveSummary.load = true;
      })
      .addCase(fetchExecutiveSummary.fulfilled, (state: ExecutiveSummaryState, action: any) => {
        state.ExecutiveSummary.data = action.payload;
        state.ExecutiveSummary.load = false;
      })
      .addCase(fetchExecutiveSummary.rejected, (state: ExecutiveSummaryState) => {
        state.ExecutiveSummary.load = false;
      });
  },
});

export default ExecutiveSummarySlice.reducer;

export const fetchExecutiveSummary = createAsyncThunk(
  "ExecutiveSummary/fetchExecutiveSummary",
  async (param: { apiParam: { project_pin: any, layout_id: any, code_group: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getExecutiveSummaryData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id,
        code_group: param.apiParam.code_group
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
