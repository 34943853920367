import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface CompanyDetailsState {
  companyDetails: {
    load: boolean;
    data: [];
  };
}

export const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState: {
    companyDetails: {
      load: false,
      data: [],
    },
  } as CompanyDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchCompanyDetails.pending, (state: CompanyDetailsState) => {
        state.companyDetails.load = true;
      })
      .addCase(fetchCompanyDetails.fulfilled, (state: CompanyDetailsState, action: any) => {
        state.companyDetails.data = action.payload;
        state.companyDetails.load = false;
      })
      .addCase(fetchCompanyDetails.rejected, (state: CompanyDetailsState) => {
        state.companyDetails.load = false;
      });
  },
});

export default companyDetailsSlice.reducer;


export const fetchCompanyDetails = createAsyncThunk(
    "report/fetchCompanyDetails",
    async (param: any) => {
      try {
        const { data } = await DataServer.post(`api/getCompanyDetails`, {
          warehouse_id: param.apiParam.warehouse_id
        });
        return data.data;
      } catch (error) {
        return error;
      }
    }
  );
