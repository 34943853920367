import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface NodesDataState {
    NodesData: {
        load: boolean;
        data: [];
    };
}

export const NodesDataSlice = createSlice({
    name: "NodesData",
    initialState: {
        NodesData: {
            load: false,
            data: [],
        },
    } as NodesDataState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchNodesData.pending, (state: NodesDataState) => {
                state.NodesData.load = true;
            })
            .addCase(fetchNodesData.fulfilled, (state: NodesDataState, action: any) => {
                state.NodesData.data = action.payload;
                state.NodesData.load = false;
            })
            .addCase(fetchNodesData.rejected, (state: NodesDataState) => {
                state.NodesData.load = false;
            });
    },
});

export default NodesDataSlice.reducer;

export const fetchNodesData = createAsyncThunk(
    "NodesData/fetchNodesData",
    async (param: { apiParam: { sublayout_id: any, rack_run_pin: any } }) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            const { data } = await DataServer.post("api/getNodesData", {
                warehouse_id: warehouse_id,
                sublayout_id: param.apiParam.sublayout_id,
                rack_run_id: param.apiParam.rack_run_pin
            });
            // console.log(data)
            return data.data;
        } catch (error) {
            return error;
        }

    }

);
