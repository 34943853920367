import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface RackSystemDataState {
  RackSystemData: {
    load: boolean;
    data: [];
  };
}

const initialState: RackSystemDataState = {
  RackSystemData: {
    load: false,
    data: [],
  },
};

export const RackSystemDataSlice = createSlice({
  name: "RackSystemData",
  initialState,
  reducers: {
    resetRackSystemData: (state) => {
      state.RackSystemData.data = initialState.RackSystemData.data;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackSystemData.pending, (state: RackSystemDataState) => {
        state.RackSystemData.load = true;
      })
      .addCase(fetchRackSystemData.fulfilled, (state: RackSystemDataState, action: any) => {
        state.RackSystemData.data = action.payload;
        state.RackSystemData.load = false;
      })
      .addCase(fetchRackSystemData.rejected, (state: RackSystemDataState) => {
        state.RackSystemData.load = false;
      });
  },
});

export const { resetRackSystemData } = RackSystemDataSlice.actions;
export default RackSystemDataSlice.reducer;

export const fetchRackSystemData = createAsyncThunk(
  "RackSystemData/fetchRackSystemData",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getRackSystemDetails", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(resetRackSystemData());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
