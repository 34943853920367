import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface FrameWiseHealthDataState {
  FrameWiseHealthData: {
    load: boolean;
    data: [];
  };
}

export const FrameWiseHealthDataSlice = createSlice({
  name: "FrameWiseHealthData",
  initialState: {
    FrameWiseHealthData: {
      load: false,
      data: [],
    },
  } as FrameWiseHealthDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchFrameWiseHealthData.pending, (state: FrameWiseHealthDataState) => {
        state.FrameWiseHealthData.load = true;
      })
      .addCase(fetchFrameWiseHealthData.fulfilled, (state: FrameWiseHealthDataState, action: any) => {
        state.FrameWiseHealthData.data = action.payload;
        state.FrameWiseHealthData.load = false;
      })
      .addCase(fetchFrameWiseHealthData.rejected, (state: FrameWiseHealthDataState) => {
        state.FrameWiseHealthData.load = false;
      });
  },
});

export default FrameWiseHealthDataSlice.reducer;

export const fetchFrameWiseHealthData = createAsyncThunk(
  "FrameWiseHealthData/fetchFrameWiseHealthData",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getFrameWiseTpiRackHealthData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
