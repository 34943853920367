import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface ConclusionState {
  Conclusion: {
    load: boolean;
    data: [];
  };
}

export const ConclusionSlice = createSlice({
  name: "Conclusion",
  initialState: {
    Conclusion: {
      load: false,
      data: [],
    },
  } as ConclusionState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchConclusion.pending, (state: ConclusionState) => {
        state.Conclusion.load = true;
      })
      .addCase(fetchConclusion.fulfilled, (state: ConclusionState, action: any) => {
        state.Conclusion.data = action.payload;
        state.Conclusion.load = false;
      })
      .addCase(fetchConclusion.rejected, (state: ConclusionState) => {
        state.Conclusion.load = false;
      });
  },
});

export default ConclusionSlice.reducer;

export const fetchConclusion = createAsyncThunk(
  "Conclusion/fetchConclusion",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getCustomConclusionData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);

export const postConclusion = createAsyncThunk(
    "Conclusion/postConclusion",
    async (param: {apiParam: {project_pin: any, layout_id: any, conclusion: any}, navigation: any}, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        // const { data } = await DataServer.post("api/postCustomConclusionForTpiReport?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id + "&conclusion=" + param.apiParam.conclusion);
        const { data } = await DataServer.post("api/postCustomConclusionForTpiReport", {
          project_pin: param.apiParam.project_pin,
          layout_id: param.apiParam.layout_id,
          conclusion: param.apiParam.conclusion
        });
       
        thunkAPI.dispatch(toggleLoading());

        if(data.message === "Conclusion already saved successfully"){
          thunkAPI.dispatch(
            activateSweetAlert({
                show: true,
                type: "warning",
                title: "Warning!",
                confirmBtnText: 'Done',
                message: "Conclusion already saved.",
                onConfirm: () => {
                    handleonConfirm()
                },
            })
          );
        } else {
          thunkAPI.dispatch(
              activateSweetAlert({
                  show: true,
                  type: "success",
                  title: "Success!",
                  confirmBtnText: 'Done',
                  message: data.message,
                  onConfirm: () => {
                      handleonConfirm()
                  },
              })
          );
        }

        const handleonConfirm = () => {
            // param.navigation(`/view-tpi-report/${param.apiParam.project_pin}`);
            // window.location.reload();
            thunkAPI.dispatch(deActivateSweetAlert());
        };
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
  );

  export const UpdateConclusionData = createAsyncThunk(
    "Conclusion/UpdateConclusionData",
    async (param: {apiParam: {project_pin: any, layout_id: any, conclusion: any}, navigation: any}, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        // const { data } = await DataServer.post("api/postCustomConclusionForTpiReport?project_pin=" + param.apiParam.project_pin + "&layout_id=" + param.apiParam.layout_id + "&conclusion=" + param.apiParam.conclusion);
        const { data } = await DataServer.post("api/updateCustomConclusionForTpiReport", {
          project_pin: param.apiParam.project_pin,
          layout_id: param.apiParam.layout_id,
          conclusion: param.apiParam.conclusion
        });
       
        thunkAPI.dispatch(toggleLoading());
        activateSweetAlert({
            show: true,
            type: "success",
            title: "Success!",
            confirmBtnText: 'Done',
            message: data.message,
            onConfirm: () => {
                handleonConfirm()
            },
        })

        const handleonConfirm = () => {
            thunkAPI.dispatch(deActivateSweetAlert());
        };
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);
