import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface CompanyData {
    company_name: string;
    company_website: string;
    email: string;
    contact_number: string;
    image_name: string;
    address_line1: string;
    address_line2: string;
    city: string;
    district_id: number;
    state_id: number;
    country_id: number;
    pin_code: string;
    created_at: string;
    status: string;
    updated_at: string;
    company_logo_image: string;
    company_type: string;
}

interface CompanyState {
    company: {
        load: boolean;
        data: Array<CompanyData>;
    };
    companyData?: CompanyData;
    editCompany: {
        load: boolean;
    };
}
const initialState = {
    company: {
        load: false,
        data: [],
    },
    companyData: {
        company_name: "",
        company_website: "",
        email: "",
        contact_number: "",
        image_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        district_id: 0,
        state_id: 0,
        country_id: 0,
        pin_code: "",
        created_at: "",
        status: "",
        updated_at: "",
        company_logo_image: "",
        company_type: ""
    },
    editCompany: {
        load: false,
    },
} as CompanyState;

export const CompanySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        resetCompanyData: (state: CompanyState) => {
            state.companyData = initialState.companyData;
        },
        updateCompanyData: (state: CompanyState, action: { payload: CompanyData }) => {
            state.companyData = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchCompanyList.pending, (state: CompanyState) => {
                state.company.load = true;
            })
            .addCase(fetchCompanyList.fulfilled, (state: CompanyState, action: any) => {
                state.company.data = action.payload;
                state.company.load = false;
            })
            .addCase(fetchCompanyList.rejected, (state: CompanyState) => {
                state.company.load = false;
            })
            .addCase(fetchCompany.pending, (state: CompanyState) => {
                state.editCompany.load = true;
            })
            .addCase(fetchCompany.fulfilled, (state: CompanyState, action: any) => {
                state.companyData = action.payload;
                state.editCompany.load = false;
            })
            .addCase(fetchCompany.rejected, (state: CompanyState) => {
                state.editCompany.load = false;
            });
    },
});
export const { updateCompanyData, resetCompanyData } = CompanySlice.actions;
export default CompanySlice.reducer;

export const fetchCompanyList = createAsyncThunk(
    "company/fetchCompanyList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/companySummary?page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page + "&search=" + param.apiParam.search + "&company_type=" + param.apiParam.company_type);
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
        }
    }
);


export const fetchCompany = createAsyncThunk(
    "users/fetchCompany",
    async (param: { apiParam: { id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(`api/editCompany`, {
                company_id: param.apiParam.id
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);

export const addCompany = createAsyncThunk(
    "company/addComapny",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/createnewcompany",
                param.apiParam
            );
            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: "Company Created successfully",
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/company-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const editCompany = createAsyncThunk(
    "company/editCompany",
    async (param: { apiParam: any, navigation: any, role_id: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/updateCompany", param.apiParam);

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                if(param.role_id === 9 || param.role_id === 11 || param.role_id === 5){
                    window.location.reload();
                } else {
                    param.navigation("/company-summary");
                }
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            return error;
        }
    }
);
