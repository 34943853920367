import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface PreviousCycleDataState {
    previouscycledata: {
        load: boolean;
        data: [];
    };
}

export const previouscycledataSlice = createSlice({
    name: "previouscycledata",
    initialState: {
        previouscycledata: {
            load: false,
            data: [],
        },
    } as PreviousCycleDataState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchPreviousCycleData.pending, (state: PreviousCycleDataState) => {
                state.previouscycledata.load = true;
            })
            .addCase(fetchPreviousCycleData.fulfilled, (state: PreviousCycleDataState, action: any) => {
                state.previouscycledata.data = action.payload;
                state.previouscycledata.load = false;
            })
            .addCase(fetchPreviousCycleData.rejected, (state: PreviousCycleDataState) => {
                state.previouscycledata.load = false;
            });
    },
});

export default previouscycledataSlice.reducer;

export const fetchPreviousCycleData = createAsyncThunk(
    "previouscycledata/fetchPreviousCycleData",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getDetailedInspectionPreviousCycleData", {
                warehouse_id: param.apiParam.warehouse_id,
                graph_type: "DETAILED",
                layout_id: param.apiParam.layout_id,
                inspector_id: "ALL",
                cycle: param.apiParam.cycle
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
