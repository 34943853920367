import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ElementCountState {
  elementcount: {
    load: boolean;
    data: [];
  };
}

export const elementcountSlice = createSlice({
  name: "elementcount",
  initialState: {
    elementcount: {
      load: false,
      data: [],
    },
  } as ElementCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchElementCount.pending, (state: ElementCountState) => {
        state.elementcount.load = true;
      })
      .addCase(fetchElementCount.fulfilled, (state: ElementCountState, action: any) => {
        state.elementcount.data = action.payload;
        state.elementcount.load = false;
      })
      .addCase(fetchElementCount.rejected, (state: ElementCountState) => {
        state.elementcount.load = false;
      });
  },
});

export default elementcountSlice.reducer;


export const fetchElementCount = createAsyncThunk(
    "report/fetchElementCount",
    async (param: {apiParam: {id: any, warehouse_id: any}}) => {
      try {
        const { data } = await DataServer.post(`api/getElementsCountTillCurrentDate`, {
          warehouse_id: param.apiParam.warehouse_id,
          inspection_schedule_details_pin: param.apiParam.id
        });
        return data.data;
      } catch (error) {
        return error;
      }
    }
  );
