import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface CycleListState {
    cyclelist: {
        load: boolean;
        data: [];
    };
}

export const cyclelistSlice = createSlice({
    name: "cyclelist",
    initialState: {
        cyclelist: {
            load: false,
            data: [],
        },
    } as CycleListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchCycleList.pending, (state: CycleListState) => {
                state.cyclelist.load = true;
            })
            .addCase(fetchCycleList.fulfilled, (state: CycleListState, action: any) => {
                state.cyclelist.data = action.payload;
                state.cyclelist.load = false;
            })
            .addCase(fetchCycleList.rejected, (state: CycleListState) => {
                state.cyclelist.load = false;
            });
    },
});

export default cyclelistSlice.reducer;

export const fetchCycleList = createAsyncThunk(
    "cyclelist/fetchCycleList",
    async (param: any, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getPreviousCycleList", {
                warehouse_id: param.apiParam.warehouse_id,
                graph_type: "DETAILED",
                layout_id: param.apiParam.layout_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
