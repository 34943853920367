import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ReportState {
  report: {
    load: boolean;
    data: [];
  };
}

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    report: {
      load: false,
      data: [],
    },
  } as ReportState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchReportDetails.pending, (state: ReportState) => {
        state.report.load = true;
      })
      .addCase(fetchReportDetails.fulfilled, (state: ReportState, action: any) => {
        state.report.data = action.payload;
        state.report.load = false;
      })
      .addCase(fetchReportDetails.rejected, (state: ReportState) => {
        state.report.load = false;
      });
  },
});

export default reportSlice.reducer;

export const fetchReportDetails = createAsyncThunk(
  "report/fetchReportDetails",
  async (param: any) => {
    const warehouse_id = localStorage.getItem("_warehouse_id");
    try {
      const { data } = await DataServer.post(`api/viewInspectionReport/${param.apiParam.id}?warehouse_id=` + warehouse_id);
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
