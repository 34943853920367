import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

import {
  activateSweetAlert,
  deActivateSweetAlert,
} from "../SweetAlert";
import { ErrorMessage } from "../ErrorHandling";

export interface UserData {
  address_line1: string;
  address_line2: string;
  city: string;
  contact_number: string;
  country_id: number;
  created_at: string;
  district_id: number;
  email: string;
  first_name: string;
  user_id: number;
  id: number;
  login_user_id: number;
  joining_date: string;
  last_name: string;
  member_id: number;
  pin_code: string;
  profile_image: string;
  role_id: number;
  state_id: number;
  status: string;
  updated_at: string;
  company_id: number
}

interface UsersState {
  users: {
    load: boolean;
    data: Array<UserData>;
    errors: Array<UserData>
  };
  userData?: UserData;
  editUser: {
    load: boolean;
  };
}
const initialState = {
  users: {
    load: false,
    data: [],
    errors: []
  },
  userData: {
    address_line1: "",
    address_line2: "",
    city: "",
    contact_number: "",
    country_id: 0,
    created_at: "",
    district_id: 0,
    email: "",
    first_name: "",
    user_id: 0,
    id: 0,
    login_user_id: 0,
    joining_date: "",
    last_name: "",
    member_id: 0,
    pin_code: "",
    profile_image: "",
    role_id: 0,
    state_id: 0,
    status: "",
    updated_at: "",
    company_id: 0
  },
  editUser: {
    load: false,
  },
} as UsersState;

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserData: (state: UsersState) => {
      state.userData = initialState.userData;
    },
    updateData: (state: UsersState, action: { payload: UserData }) => {
      state.userData = action.payload;
    },
    userAddFail: (state, action) => {
      state.users.errors = action.payload.errors;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchUsers.pending, (state: UsersState) => {
        state.users.load = true;
      })
      .addCase(fetchUsers.fulfilled, (state: UsersState, action: any) => {
        state.users.data = action.payload;
        state.users.load = false;
      })
      .addCase(fetchUsers.rejected, (state: UsersState) => {
        state.users.load = false;
      })
      .addCase(fetchUser.pending, (state: UsersState) => {
        state.editUser.load = true;
      })
      .addCase(fetchUser.fulfilled, (state: UsersState, action: any) => {
        state.userData = action.payload;
        state.editUser.load = false;
      })
      .addCase(fetchUser.rejected, (state: UsersState) => {
        state.editUser.load = false;
      });
  },
});
export const { updateData, resetUserData, userAddFail } = usersSlice.actions;
export default usersSlice.reducer;

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (param: { apiParam: any }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("api/userList?page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page + "&warehouse_id=" + param.apiParam.warehouse_id + "&tpi_firm_id=" + param.apiParam.tpiFirmId + "&login_user_id=" + param.apiParam.login_user_id + "&search=" + param.apiParam.search);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
export const fetchUsers1 = createAsyncThunk(
  "users/fetchUsers",
  async (param: { apiParam: any }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("api/userList?page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page + "&warehouse_id=" + param.apiParam.warehouse_id + "&role_id=" + param.apiParam.role_id + "&search=" + param.apiParam.search);
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);

export const fetchUser = createAsyncThunk(
  "userdetails/fetchUser",
  async (param: { apiParam: { id: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(`api/editUser`, {
        user_id: param.apiParam.id
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);

export const addUser = createAsyncThunk(
  "users/addEditUser",
  async (param: { apiParam: any, navigation: any }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(
        "api/createSubAdmin",
        param.apiParam
      );

      // thunkAPI.dispatch(toggleLoading());

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => {
            handleonConfirm()
          },
        })
      );

      const handleonConfirm = () => {
        param.navigation("/user-summary");
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(userAddFail(error));
      return error;
    }
  }
);

export const editUser = createAsyncThunk(
  "users/editUser",
  async (param: { apiParam: any, navigation: any, popoupmsg: any }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/updateUser", param.apiParam);
      thunkAPI.dispatch(toggleLoading());

      if(param.popoupmsg === "profileSetting"){
        thunkAPI.dispatch(
          activateSweetAlert({
            show: true,
            type: "success",
            title: "Success!",
            confirmBtnText: 'Done',
            message: "Profile updated Successfully",
            onConfirm: () => {
              handleonConfirm()
            },
          })
        );
      } else {
        thunkAPI.dispatch(
          activateSweetAlert({
            show: true,
            type: "success",
            title: "Success!",
            confirmBtnText: 'Done',
            message: data.message,
            onConfirm: () => {
              handleonConfirm()
            },
          })
        );
      }

      const handleonConfirm = () => {
        param.navigation("/user-summary");
        thunkAPI.dispatch(deActivateSweetAlert());
        window.location.reload();
      };
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // thunkAPI.dispatch(ErrorMessage(error));
      return error;
      // console.log("Error", error);
    }
  }
);
