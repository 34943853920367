import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface RackHealthCountState {
  RackHealthCount: {
    load: boolean;
    data: [];
  };
}

export const RackHealthCountSlice = createSlice({
  name: "RackHealthCount",
  initialState: {
    RackHealthCount: {
      load: false,
      data: [],
    },
  } as RackHealthCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackHealthCount.pending, (state: RackHealthCountState) => {
        state.RackHealthCount.load = true;
      })
      .addCase(fetchRackHealthCount.fulfilled, (state: RackHealthCountState, action: any) => {
        state.RackHealthCount.data = action.payload;
        state.RackHealthCount.load = false;
      })
      .addCase(fetchRackHealthCount.rejected, (state: RackHealthCountState) => {
        state.RackHealthCount.load = false;
      });
  },
});

export default RackHealthCountSlice.reducer;

export const fetchRackHealthCount = createAsyncThunk(
  "RackHealthCount/fetchRackHealthCount",
  async (param: { apiParam: { layout_pin: any, warehouse_id: any }}) => {
    try {
      const { data } = await DataServer.post("api/getElementsCount", {
        warehouse_id: param.apiParam.warehouse_id,
        layout_pin: param.apiParam.layout_pin
      });

      return data.data;
    } catch (error) {
      return error;
    }
  }
);
