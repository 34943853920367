import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { activateAlert } from "../Alert";
import { Alert } from "react-bootstrap";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface QuotationData {
    quotation_no: string;
    quotation_date: string;
    quotation_type: string;
    validity_days: string;
    lead_by: string;
    company_name: string;
    warehouse_name: string;
    address_line1: string;
    address_line2: string;
    city: string;
    district_id: number;
    state_id: number;
    country_id: number;
    pin_code: string;
    first_name: string;
    last_name: string;
    email: string;
    contact: any;
    package_description: string;
    final_amount: any;
    validity: string;
    created_at: string;
    status: string;
    updated_at: string;
}

interface QuotationState {
    quotation: {
        load: boolean;
        data: Array<QuotationData>;
    };
    quotationData?: QuotationData;
    editQuotation: {
        load: boolean;
    };
}
const initialState = {
    quotation: {
        load: false,
        data: [],
    },
    quotationData: {
        quotation_no: "",
        quotation_date: "",
        quotation_type: "",
        validity_days: "",
        lead_by: "",
        company_name: "",
        warehouse_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        district_id: 0,
        state_id: 0,
        country_id: 0,
        pin_code: "",
        first_name: "",
        last_name: "",
        email: "",
        contact: "",
        package_description: "",
        final_amount: "",
        validity: "",
        created_at: "",
        status: "",
        updated_at: "",
    },
    editQuotation: {
        load: false,
    },
} as QuotationState;

export const QuotationSlice = createSlice({
    name: "quotation",
    initialState,
    reducers: {
        resetQuotationData: (state: QuotationState) => {
            state.quotationData = initialState.quotationData;
        },
        updateQuotationData: (state: QuotationState, action: { payload: QuotationData }) => {
            state.quotationData = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchQuotationList.pending, (state: QuotationState) => {
                state.quotation.load = true;
            })
            .addCase(fetchQuotationList.fulfilled, (state: QuotationState, action: any) => {
                state.quotation.data = action.payload;
                state.quotation.load = false;
            })
            .addCase(fetchQuotationList.rejected, (state: QuotationState) => {
                state.quotation.load = false;
            })
            .addCase(fetchQuotation.pending, (state: QuotationState) => {
                state.editQuotation.load = true;
            })
            .addCase(fetchQuotation.fulfilled, (state: QuotationState, action: any) => {
                state.quotationData = action.payload;
                state.editQuotation.load = false;
            })
            .addCase(fetchQuotation.rejected, (state: QuotationState) => {
                state.editQuotation.load = false;
            });
    },
});
export const { updateQuotationData, resetQuotationData } = QuotationSlice.actions;
export default QuotationSlice.reducer;

export const fetchQuotationList = createAsyncThunk(
    "quotation/fetchQuotationList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/quotationSummary?page=" + param.apiParam.page + "&per_page=" + param.apiParam.per_page + "&search=" + param.apiParam.search);
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);


export const fetchQuotation = createAsyncThunk(
    "quotation/fetchQuotation",
    async (param: { apiParam: { id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get(
                `api/fetchUserSummaryById/${param.apiParam.id}`
            );
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const addQuotation = createAsyncThunk(
    "quotation/addQuotation",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/warehouseGenereateQuotation",
                param.apiParam
            );

            thunkAPI.dispatch(toggleLoading());
            
            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/quotation-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const editQuotation = createAsyncThunk(
    "quotation/editQuotation",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/updateUser", param.apiParam);

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
