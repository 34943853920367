import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface ComplianceStandardCodeState {
    compliancestandardcode: {
        load: boolean;
        data: [];
    };
}

export const compliancestandardcodeSlice = createSlice({
    name: "compliancestandardcode",
    initialState: {
        compliancestandardcode: {
            load: false,
            data: [],
        },
    } as ComplianceStandardCodeState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchcompliancestandardcode.pending, (state: ComplianceStandardCodeState) => {
                state.compliancestandardcode.load = true;
            })
            .addCase(fetchcompliancestandardcode.fulfilled, (state: ComplianceStandardCodeState, action: any) => {
                state.compliancestandardcode.data = action.payload;
                state.compliancestandardcode.load = false;
            })
            .addCase(fetchcompliancestandardcode.rejected, (state: ComplianceStandardCodeState) => {
                state.compliancestandardcode.load = false;
            });
    },
});

export default compliancestandardcodeSlice.reducer;

export const fetchcompliancestandardcode = createAsyncThunk(
    "compliancestandardcode/fetchcompliancestandardcode",
    async (param, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/complianceCodeList");
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error in Login", error);
        }
    }
);
