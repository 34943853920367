export const getItem = (key: any) => {
  const data =
    typeof window !== "undefined" && localStorage.getItem(key)
      ? localStorage.getItem(key)
      : "";

  try {
    return data;
  } catch (error) {
    return "";
  }
};

export const setItem = (key: any, value: any) => {
  return localStorage.setItem(key, value);
};

export const removeItem = (key: any) => {
  localStorage.removeItem(key);
};

export const removeAll = () => localStorage.clear();
