import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";

interface StabilityCertificateState {
  StabilityCertificate: {
    load: boolean;
    data: [];
  };
}

export const StabilityCertificateSlice = createSlice({
  name: "StabilityCertificate",
  initialState: {
    StabilityCertificate: {
      load: false,
      data: [],
    },
  } as StabilityCertificateState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchStabilityCertificate.pending, (state: StabilityCertificateState) => {
        state.StabilityCertificate.load = true;
      })
      .addCase(fetchStabilityCertificate.fulfilled, (state: StabilityCertificateState, action: any) => {
        state.StabilityCertificate.data = action.payload;
        state.StabilityCertificate.load = false;
      })
      .addCase(fetchStabilityCertificate.rejected, (state: StabilityCertificateState) => {
        state.StabilityCertificate.load = false;
      });
  },
});

export default StabilityCertificateSlice.reducer;

export const fetchStabilityCertificate = createAsyncThunk(
  "StabilityCertificate/fetchStabilityCertificate",
  async (param: {apiParam: {project_pin: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getStabilityCertificateData", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
