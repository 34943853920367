import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface MergePdfState {
  MergePdf: {
    load: boolean;
    data: [];
  };
}

export const MergePdfSlice = createSlice({
  name: "MergePdf",
  initialState: {
    MergePdf: {
      load: false,
      data: [],
    },
  } as MergePdfState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(getMergeReportPdfFile.pending, (state: MergePdfState) => {
        state.MergePdf.load = true;
      })
      .addCase(
        getMergeReportPdfFile.fulfilled,
        (state: MergePdfState, action: any) => {
          state.MergePdf.data = action.payload;
          state.MergePdf.load = false;
        }
      )
      .addCase(getMergeReportPdfFile.rejected, (state: MergePdfState) => {
        state.MergePdf.load = false;
      });
  },
});

export default MergePdfSlice.reducer;

export const postMergePdf = createAsyncThunk(
  "MergePdf/postMergePdf",
  async (
    param: {
      apiParam: {
        project_pin: any;
        layout_id: any;
        report_file: any;
        login_user_id: any;
      };
    },
    thunkAPI
  ) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/mergePdf", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id,
        report_file: param.apiParam.report_file,
        login_user_id: param.apiParam.login_user_id,
      });

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: "Done",
          message: data.message,
          onConfirm: () => {
            handleonConfirm();
          },
        })
      );

      const handleonConfirm = () => {
        window.location.reload();
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);

export const getMergeReportPdfFile = createAsyncThunk(
  "MergePdf/postMergePdf",
  async (
    param: {
      apiParam: {
        project_pin: any;
        layout_id: any;
      };
    },
    thunkAPI
  ) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getMergeReportPdfFile", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id,
      });
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
