import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface SensorDetailsState {
  SensorDetails: {
    load: boolean;
    data: [];
  };
}

export const SensorDetailsSlice = createSlice({
  name: "SensorDetails",
  initialState: {
    SensorDetails: {
      load: false,
      data: [],
    },
  } as SensorDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder      
      .addCase(getSensorDetails.pending, (state: SensorDetailsState) => {
        state.SensorDetails.load = true;
      })
      .addCase(getSensorDetails.fulfilled, (state: SensorDetailsState, action: any) => {
        state.SensorDetails.data = action.payload;
        state.SensorDetails.load = false;
      })
      .addCase(getSensorDetails.rejected, (state: SensorDetailsState) => {
        state.SensorDetails.load = false;
      });
  },
});

export default SensorDetailsSlice.reducer;

export const getSensorDetails = createAsyncThunk(
    "SensorDetails/getSensorDetails",
    async (param: {apiParam: { sensor_pin: any }}, thunkAPI) => {
      try {
        thunkAPI.dispatch(toggleLoading());
        const { data } = await DataServer.get("sensor/v1/getSensorDetails?sensor_pin=" + param.apiParam.sensor_pin);
        thunkAPI.dispatch(toggleLoading());
  
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
        // return error;
      }
    }
);
  