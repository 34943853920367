import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface BeamDataState {
    BeamData: {
        load: boolean;
        data: [];
    };
}

export const BeamDataSlice = createSlice({
    name: "BeamData",
    initialState: {
        BeamData: {
            load: false,
            data: [],
        },
    } as BeamDataState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchBeamData.pending, (state: BeamDataState) => {
                state.BeamData.load = true;
            })
            .addCase(fetchBeamData.fulfilled, (state: BeamDataState, action: any) => {
                state.BeamData.data = action.payload;
                state.BeamData.load = false;
            })
            .addCase(fetchBeamData.rejected, (state: BeamDataState) => {
                state.BeamData.load = false;
            });
    },
});

export default BeamDataSlice.reducer;

export const fetchBeamData = createAsyncThunk(
    "BeamData/fetchBeamData",
    async (param: { apiParam: { sublayout_id: any, rack_run_id: any } }) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            const { data } = await DataServer.post("api/getBeamsData", {
                warehouse_id: warehouse_id,
                sublayout_id: param.apiParam.sublayout_id,
                rack_run_id: param.apiParam.rack_run_id
            });

            return data.data;
        } catch (error) {
            return error;
        }
    }
);
