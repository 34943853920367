import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardDetailedGraphState {
    dashboarddetailedgraph: {
        load: boolean;
        data: [];
    };
}

export const dashboarddetailedgraphSlice = createSlice({
    name: "dashboarddetailedgraph",
    initialState: {
        dashboarddetailedgraph: {
            load: false,
            data: [],
        },
    } as DashboardDetailedGraphState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchGraphDetails.pending, (state: DashboardDetailedGraphState) => {
                state.dashboarddetailedgraph.load = true;
            })
            .addCase(fetchGraphDetails.fulfilled, (state: DashboardDetailedGraphState, action: any) => {
                state.dashboarddetailedgraph.data = action.payload;
                state.dashboarddetailedgraph.load = false;
            })
            .addCase(fetchGraphDetails.rejected, (state: DashboardDetailedGraphState) => {
                state.dashboarddetailedgraph.load = false;
            })
            // .addCase(fetchGraphDetailsOfInspector.pending, (state: DashboardDetailedGraphState) => {
            //     state.dashboarddetailedgraph.load = true;
            // })
            // .addCase(fetchGraphDetailsOfInspector.fulfilled, (state: DashboardDetailedGraphState, action: any) => {
            //     state.dashboarddetailedgraph.data = action.payload;
            //     state.dashboarddetailedgraph.load = false;
            // })
            // .addCase(fetchGraphDetailsOfInspector.rejected, (state: DashboardDetailedGraphState) => {
            //     state.dashboarddetailedgraph.load = false;
            // })
            .addCase(fetchDetailedInspectionStatistics.pending, (state: DashboardDetailedGraphState) => {
                state.dashboarddetailedgraph.load = true;
            })
            .addCase(fetchDetailedInspectionStatistics.fulfilled, (state: DashboardDetailedGraphState, action: any) => {
                state.dashboarddetailedgraph.data = action.payload;
                state.dashboarddetailedgraph.load = false;
            })
            .addCase(fetchDetailedInspectionStatistics.rejected, (state: DashboardDetailedGraphState) => {
                state.dashboarddetailedgraph.load = false;
            });
    },
});

export default dashboarddetailedgraphSlice.reducer;

export const fetchGraphDetails = createAsyncThunk(
    "dashboarddetailedgraph/fetchGraphDetails",
    async (param: { apiParam: { inspection_type: any, layout_id: any, warehouse_id: any, inspector_id: any }}, thunkAPI) => {        
        try {
            const { data } = await DataServer.post("api/getDetailedInspectionSchedulesData", {
                warehouse_id: param.apiParam.warehouse_id,
                graph_type: "DETAILED",
                layout_id: param.apiParam.layout_id,
                inspector_id: param.apiParam.inspector_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

// export const fetchGraphDetailsOfInspector = createAsyncThunk(
//     "dashboarddetailedgraph/fetchGraphDetailsOfInspector",
//     async (param: { apiParam: { inspector_id: any, inspection_type: any } }, thunkAPI) => {
//         const warehouse_id = localStorage.getItem("_warehouse_id");
//         try {
//             const { data } = await DataServer.post("api/getInspectionScheduleGraphData?warehouse_id=" + warehouse_id +"&graph_type=" + param.apiParam.inspection_type + "&inspector_id=" + param.apiParam.inspector_id);
//             return data.data;
//         } catch (error) {
//             console.log("Error in Login", error);
//         }
//     }
// );

export const fetchDetailedInspectionStatistics = createAsyncThunk(
    "dashboardgraph/fetchDetailedInspectionStatistics",
    async (param: any, thunkAPI) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            const { data } = await DataServer.post("api/getIssueCount?warehouse_id=" + warehouse_id +"&graph_type=" + "DETAILED");
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
