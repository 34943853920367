import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface NotificationCountState {
  notificationcount: {
    load: boolean;
    data: [];
  };
}

export const NotificationSlice = createSlice({
  name: "notificationcount",
  initialState: {
    notificationcount: {
      load: false,
      data: [],
    },
  } as NotificationCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchNotificationCount.pending, (state: NotificationCountState) => {
        state.notificationcount.load = true;
      })
      .addCase(fetchNotificationCount.fulfilled, (state: NotificationCountState, action: any) => {
        state.notificationcount.data = action.payload;
        state.notificationcount.load = false;
      })
      .addCase(fetchNotificationCount.rejected, (state: NotificationCountState) => {
        state.notificationcount.load = false;
      });
  },
});

export default NotificationSlice.reducer;

export const fetchNotificationCount = createAsyncThunk(
  "notificationcountcount/fetchNotificationCount",
  async (param: {apiParam: {loginUserId: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getWarehouseDataCount", {
        login_user_id: param.apiParam.loginUserId
      });
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);
