import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import CreateRootReducer, { resetEnhancer } from './redux/RootReducer'

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: resetEnhancer(CreateRootReducer(history))
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch