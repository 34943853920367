import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ReportedIssuesState {
    reportedissue: {
    load: boolean;
    data: [];
  };
}

export const ReportedIssueSlice = createSlice({
  name: "reportedissue",
  initialState: {
    reportedissue: {
      load: false,
      data: [],
    },
  } as ReportedIssuesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchReportedIssuesDetails.pending, (state: ReportedIssuesState) => {
        state.reportedissue.load = true;
      })
      .addCase(fetchReportedIssuesDetails.fulfilled, (state: ReportedIssuesState, action: any) => {
        state.reportedissue.data = action.payload;
        state.reportedissue.load = false;
      })
      .addCase(fetchReportedIssuesDetails.rejected, (state: ReportedIssuesState) => {
        state.reportedissue.load = false;
      })
  },
});

export default ReportedIssueSlice.reducer;

export const fetchReportedIssuesDetails = createAsyncThunk(
  "reportedissue/fetchReportedIssuesDetails",
  async (param: {apiParam: {per_page: any, page: any, search: any, warehouse_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get(`api/getReportedIssues?warehouse_id=` + param.apiParam.warehouse_id + `&per_page=` + param.apiParam.per_page + `&page=` + param.apiParam.page + "&search=" + param.apiParam.search);
      // thunkAPI.dispatch(toggleLoading());
      // console.log("data", data)
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);


