import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface AverageSpeedByOperatorState {
  AverageSpeedByOperator: {
    load: boolean;
    data: [];
  };
}

export const AverageSpeedByOperatorSlice = createSlice({
  name: "AverageSpeedByOperator",
  initialState: {
    AverageSpeedByOperator: {
      load: false,
      data: [],
    },
  } as AverageSpeedByOperatorState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getAverageSpeedByOperator.pending, (state: AverageSpeedByOperatorState) => {
        state.AverageSpeedByOperator.load = true;
      })
      .addCase(getAverageSpeedByOperator.fulfilled, (state: AverageSpeedByOperatorState, action: any) => {
        state.AverageSpeedByOperator.data = action.payload;
        state.AverageSpeedByOperator.load = false;
      })
      .addCase(getAverageSpeedByOperator.rejected, (state: AverageSpeedByOperatorState) => {
        state.AverageSpeedByOperator.load = false;
      })
      .addCase(getAveragePeakSpeedByOperator.pending, (state: AverageSpeedByOperatorState) => {
        state.AverageSpeedByOperator.load = true;
      })
      .addCase(getAveragePeakSpeedByOperator.fulfilled, (state: AverageSpeedByOperatorState, action: any) => {
        state.AverageSpeedByOperator.data = action.payload;
        state.AverageSpeedByOperator.load = false;
      })
      .addCase(getAveragePeakSpeedByOperator.rejected, (state: AverageSpeedByOperatorState) => {
        state.AverageSpeedByOperator.load = false;
      });
  },
});

export default AverageSpeedByOperatorSlice.reducer;

export const getAverageSpeedByOperator = createAsyncThunk(
  "operatorspeed/getAverageSpeedByOperator",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getAverageSpeedByOperator?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
 
export const getAveragePeakSpeedByOperator = createAsyncThunk(
  "operatorspeed/getAveragePeakSpeedByOperator",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any, operator_id: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/operatorSummary/v1/getOperatorAverageAndPeakSpeedStatistics?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration + "&operator_id=" + param.apiParam.operator_id);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
