import { FC, useState } from "react";
import "../../../assets/css/reusable/Loading/_loading.scss";

const Loading: FC = () => {
  return <>
    <div className="loader-container">
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  </>;
};

export default Loading;
