import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface TpiGeneralizedIssueListState {
  GeneralizedIssueList: {
    load: boolean;
    data: [];
  };
}

export const GeneralizedIssueListSlice = createSlice({
  name: "GeneralizedIssueList",
  initialState: {
    GeneralizedIssueList: {
      load: false,
      data: [],
    },
  } as TpiGeneralizedIssueListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(
        fetchGeneralizedIssueList.pending,
        (state: TpiGeneralizedIssueListState) => {
          state.GeneralizedIssueList.load = true;
        }
      )
      .addCase(
        fetchGeneralizedIssueList.fulfilled,
        (state: TpiGeneralizedIssueListState, action: any) => {
          state.GeneralizedIssueList.data = action.payload;
          state.GeneralizedIssueList.load = false;
        }
      )
      .addCase(
        fetchGeneralizedIssueList.rejected,
        (state: TpiGeneralizedIssueListState) => {
          state.GeneralizedIssueList.load = false;
        }
      );
  },
});

export default GeneralizedIssueListSlice.reducer;

export const fetchGeneralizedIssueList = createAsyncThunk(
  "GeneralizedIssueList/fetchGeneralizedIssueList",
  async (param: { apiParam: { project_pin: any, page: any, per_page: any, search: any } }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getAllGenerelizedIssueData", {
        project_pin: param.apiParam.project_pin,
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        search: param.apiParam.search
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
