import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { deactivateAlert } from "../redux/slice/Alert";
import { BTToasts } from "../reusable";

interface alertsProps {}

const AlertComponent: FC<alertsProps> = () => {
  const dispatch = useAppDispatch();

  const { isAlertActive, alertTitle, content } = useAppSelector(
    (state) => state.alert
  );

  const handleOnClick = () => {
    dispatch(deactivateAlert());
  };
  return (
    <BTToasts
      isShow={isAlertActive}
      onClose={() => handleOnClick()}
      position="bottom-end"
      header={alertTitle}
      message={content}
    />
  );
};

export default AlertComponent;
