import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface CouponOwnedByState {
    couponownedby: {
    load: boolean;
    data: [];
  };
}

export const CouponOwnedBySlice = createSlice({
  name: "couponownedby",
  initialState: {
    couponownedby: {
      load: false,
      data: [],
    },
  } as CouponOwnedByState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchCouponOwnedWarehouseList.pending, (state: CouponOwnedByState) => {
        state.couponownedby.load = true;
      })
      .addCase(fetchCouponOwnedWarehouseList.fulfilled, (state: CouponOwnedByState, action: any) => {
        state.couponownedby.data = action.payload;
        state.couponownedby.load = false;
      })
      .addCase(fetchCouponOwnedWarehouseList.rejected, (state: CouponOwnedByState) => {
        state.couponownedby.load = false;
      });
  },
});

export default CouponOwnedBySlice.reducer;

export const fetchCouponOwnedWarehouseList = createAsyncThunk(
    "coupon/fetchCouponOwnedWarehouseList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/viewCouponsHistory", {
              coupon_code: param.apiParam.coupon_code,
              page: param.apiParam.page,
              per_page: param.apiParam.per_page,
              search: param.apiParam.search
            });
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
