import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { activateAlert } from "../Alert";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";

export interface QuoteLicenceData {
    quotation_id: number;
    quotation_pin: number;
    quotation_date: string;
    quotation_type: string;
    validity_days: string;
    lead_by: string;
    company_name: string;
    campus_name: string;
    address_line1: string;
    address_line2: string;
    city: string;
    district_id: number;
    state_id: number;
    country_id: number;
    pin_code: string;
    first_name: string;
    last_name: string;
    email: string;
    contact_number: string;
    package_description: string;
    final_amount: number;
    package_final_amount: number;
    validity: string;
    payment_method: string;
    minimum_amount: string;
    payment_term: number;
    coupon_code: string;
    payment_due_date: string;
    transaction_id: string;
    payment_mode: string;
    bank_name: string;
    cheque_no: string;
    po_no: string;
    upload_po: string;
    remark: string;
    transaction_date: string;
    advanced_amount: number;
    due_amount: number;
}

interface QuoteLicenceState {
    quotelicence: {
        load: boolean;
        data: Array<QuoteLicenceData>;
    };
    quotelicenceData?: QuoteLicenceData;
    quoteeditLicence: {
        load: boolean;
    };
}
const initialState = {
    quotelicence: {
        load: false,
        data: [],
    },
    quotelicenceData: {
        quotation_id: 0,
        quotation_pin: 0,
        quotation_date: "",
        quotation_type: "",
        validity_days: "",
        lead_by: "",
        company_name: "",
        campus_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        district_id: 0,
        state_id: 0,
        country_id: 0,
        pin_code: "",
        first_name: "",
        last_name: "",
        email: "",
        contact_number: "",
        package_description: "",
        final_amount: 0,
        package_final_amount: 0,
        validity: "",
        payment_method: "",
        minimum_amount: "",
        payment_term: 0,
        coupon_code: "",
        payment_due_date: "",
        transaction_id: "",
        payment_mode: "",
        bank_name: "",
        cheque_no: "",
        po_no: "",
        upload_po: "",
        remark: "",
        transaction_date: "",
        advanced_amount: 0,
        due_amount: 0,
    },
    quoteeditLicence: {
        load: false,
    },
} as QuoteLicenceState;

export const QuoteLicenceSlice = createSlice({
    name: "quotelicence",
    initialState,
    reducers: {
        resetQuoteLicenceData: (state: QuoteLicenceState) => {
            state.quotelicenceData = initialState.quotelicenceData;
        },
        updateQuoteLicenceData: (state: QuoteLicenceState, action: { payload: QuoteLicenceData }) => {
            state.quotelicenceData = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchQuoteDetails.pending, (state: QuoteLicenceState) => {
                state.quoteeditLicence.load = true;
            })
            .addCase(fetchQuoteDetails.fulfilled, (state: QuoteLicenceState, action: any) => {
                state.quotelicenceData = action.payload;
                state.quoteeditLicence.load = false;
            })
            .addCase(fetchQuoteDetails.rejected, (state: QuoteLicenceState) => {
                state.quoteeditLicence.load = false;
            });
    },
});
export const { resetQuoteLicenceData, updateQuoteLicenceData } = QuoteLicenceSlice.actions;
export default QuoteLicenceSlice.reducer;

export const fetchQuoteDetails = createAsyncThunk(
    "quotelicence/fetchQuoteDetails",
    async (param: { apiParam: { id: any } }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(`api/createLicenseByQuotation/${param.apiParam.id}`);
            thunkAPI.dispatch(toggleLoading());
            return data.data[0];
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);

export const quoteeditLicence = createAsyncThunk(
    "quotelicence/quoteeditLicence",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/updateLicense", param.apiParam);
            thunkAPI.dispatch(toggleLoading());
            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/licence-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);
