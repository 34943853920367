import { FC } from "react";
import { Alert } from "react-bootstrap";

export type variantType =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

export interface alertsProps {
  variant: variantType;
  message: string;
}

const Alerts: FC<alertsProps> = ({ variant, message }) => {
  return (
    <Alert key={variant} variant={variant}>
      {message}
    </Alert>
  );
};

export default Alerts;
