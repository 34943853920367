import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardGraphState {
    dashboardgraph: {
        load: boolean;
        data: [];
    };
}

export const dashboardgraphSlice = createSlice({
    name: "dashboardgraph",
    initialState: {
        dashboardgraph: {
            load: false,
            data: [],
        },
    } as DashboardGraphState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRackInspectionStatistics.pending, (state: DashboardGraphState) => {
                state.dashboardgraph.load = true;
            })
            .addCase(fetchRackInspectionStatistics.fulfilled, (state: DashboardGraphState, action: any) => {
                state.dashboardgraph.data = action.payload;
                state.dashboardgraph.load = false;
            })
            .addCase(fetchRackInspectionStatistics.rejected, (state: DashboardGraphState) => {
                state.dashboardgraph.load = false;
            });
    },
});

export default dashboardgraphSlice.reducer;


export const fetchRackInspectionStatistics = createAsyncThunk(
    "dashboardgraph/fetchRackInspectionStatistics",
    async (param: any , thunkAPI) => {
        const warehouse_id = localStorage.getItem("_warehouse_id");
        try {
            const { data } = await DataServer.post("api/getRackHealthGraphData", {
                warehouse_id: warehouse_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
