import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface UserAssignedWarehouseListState {
    UserAssignedWarehouseList: {
    load: boolean;
    data: [];
  };
}

export const CouponWarehouseListSlice = createSlice({
  name: "UserAssignedWarehouseList",
  initialState: {
    UserAssignedWarehouseList: {
      load: false,
      data: [],
    },
  } as UserAssignedWarehouseListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchUserAssignedWarehouseList.pending, (state: UserAssignedWarehouseListState) => {
        state.UserAssignedWarehouseList.load = true;
      })
      .addCase(fetchUserAssignedWarehouseList.fulfilled, (state: UserAssignedWarehouseListState, action: any) => {
        state.UserAssignedWarehouseList.data = action.payload;
        state.UserAssignedWarehouseList.load = false;
      })
      .addCase(fetchUserAssignedWarehouseList.rejected, (state: UserAssignedWarehouseListState) => {
        state.UserAssignedWarehouseList.load = false;
      });
  },
});

export default CouponWarehouseListSlice.reducer;

export const fetchUserAssignedWarehouseList = createAsyncThunk(
    "UserAssignedWarehouseList/fetchUserAssignedWarehouseList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/userAssignedWarehouses?user_id=" + param.apiParam.user_id);
            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
        }
    }
);
