import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface MHEUtilizationTrendsState {
  MHEUtilizationTrends: {
    load: boolean;
    data: [];
  };
}

export const MHEUtilizationTrendsSlice = createSlice({
  name: "MHEUtilizationTrends",
  initialState: {
    MHEUtilizationTrends: {
      load: false,
      data: [],
    },
  } as MHEUtilizationTrendsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getMHEUtilizationTrends.pending, (state: MHEUtilizationTrendsState) => {
        state.MHEUtilizationTrends.load = true;
      })
      .addCase(getMHEUtilizationTrends.fulfilled, (state: MHEUtilizationTrendsState, action: any) => {
        state.MHEUtilizationTrends.data = action.payload;
        state.MHEUtilizationTrends.load = false;
      })
      .addCase(getMHEUtilizationTrends.rejected, (state: MHEUtilizationTrendsState) => {
        state.MHEUtilizationTrends.load = false;
      });
  },
});

export default MHEUtilizationTrendsSlice.reducer;

export const getMHEUtilizationTrends = createAsyncThunk(
  "MHEUtilizationTrends/getMHEUtilizationTrends",
  async (param: {apiParam: { company_id: any, warehouse_id: any, duration: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getMHEUtilizationTrends?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id + "&duration=" + param.apiParam.duration);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
  