import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardIssueCountState {
    dashboardissuecount: {
        load: boolean;
        data: [];
    };
}

export const dashboarddashboardissuecountSlice = createSlice({
    name: "dashboardissuecount",
    initialState: {
        dashboardissuecount: {
            load: false,
            data: [],
        },
    } as DashboardIssueCountState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchDashboardIssueCounts.pending, (state: DashboardIssueCountState) => {
                state.dashboardissuecount.load = true;
            })
            .addCase(fetchDashboardIssueCounts.fulfilled, (state: DashboardIssueCountState, action: any) => {
                state.dashboardissuecount.data = action.payload;
                state.dashboardissuecount.load = false;
            })
            .addCase(fetchDashboardIssueCounts.rejected, (state: DashboardIssueCountState) => {
                state.dashboardissuecount.load = false;
            });
    },
});

export default dashboarddashboardissuecountSlice.reducer;

export const fetchDashboardIssueCounts = createAsyncThunk(
    "dashboardissuecount/fetchDashboardIssueCounts",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/getTotalIssuesCount", {
                warehouse_id: param.apiParam.warehouse_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
