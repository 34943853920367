import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface RemainingCreditPointsState {
    RemainingCreditPoints: {
        load: boolean;
        data: [];
    };
}

export const inspectionSlice = createSlice({
    name: "RemainingCreditPoints",
    initialState: {
        RemainingCreditPoints: {
            load: false,
            data: [],
        },
    } as RemainingCreditPointsState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRemainingCreditPoints.pending, (state: RemainingCreditPointsState) => {
                state.RemainingCreditPoints.load = true;
            })
            .addCase(fetchRemainingCreditPoints.fulfilled, (state: RemainingCreditPointsState, action: any) => {
                state.RemainingCreditPoints.data = action.payload;
                state.RemainingCreditPoints.load = false;
            })
            .addCase(fetchRemainingCreditPoints.rejected, (state: RemainingCreditPointsState) => {
                state.RemainingCreditPoints.load = false;
            });
    },
});

export default inspectionSlice.reducer;

export const fetchRemainingCreditPoints = createAsyncThunk(
    "RemainingCreditPoints/fetchRemainingCreditPoints",
    async (param: { apiParam: { tpi_firm_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.get("api/GetAvailableCreditPoints?company_id=" + param.apiParam.tpi_firm_id);
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);
