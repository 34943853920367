import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface SyncRackHealthCountState {
  SyncRackHealthCount: {
    load: boolean;
    data: [];
  };
}

export const SyncRackHealthCountSlice = createSlice({
  name: "SyncRackHealthCount",
  initialState: {
    SyncRackHealthCount: {
      load: false,
      data: [],
    },
  } as SyncRackHealthCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchSyncRackHealthCount.pending, (state: SyncRackHealthCountState) => {
        state.SyncRackHealthCount.load = true;
      })
      .addCase(fetchSyncRackHealthCount.fulfilled, (state: SyncRackHealthCountState, action: any) => {
        state.SyncRackHealthCount.data = action.payload;
        state.SyncRackHealthCount.load = false;
      })
      .addCase(fetchSyncRackHealthCount.rejected, (state: SyncRackHealthCountState) => {
        state.SyncRackHealthCount.load = false;
      });
  },
});

export default SyncRackHealthCountSlice.reducer;

export const fetchSyncRackHealthCount = createAsyncThunk(
  "SyncRackHealthCount/fetchSyncRackHealthCount",
  async (param: {apiParam: {project_pin: any, warehouse_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getSyncTpiRackHealthData", {
        warehouse_id: param.apiParam.warehouse_id,
        project_pin: param.apiParam.project_pin
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
