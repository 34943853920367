import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface CreditSystemTransactionsState {
  CreditSystemTransactions: {
    load: boolean;
    data: [];
  };
}

export const CreditSystemTransactionsSlice = createSlice({
  name: "CreditSystemTransactions",
  initialState: {
    CreditSystemTransactions: {
      load: false,
      data: [],
    },
  } as CreditSystemTransactionsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchCreditSystemTransactions.pending, (state: CreditSystemTransactionsState) => {
        state.CreditSystemTransactions.load = true;
      })
      .addCase(fetchCreditSystemTransactions.fulfilled, (state: CreditSystemTransactionsState, action: any) => {
        state.CreditSystemTransactions.data = action.payload;
        state.CreditSystemTransactions.load = false;
      })
      .addCase(fetchCreditSystemTransactions.rejected, (state: CreditSystemTransactionsState) => {
        state.CreditSystemTransactions.load = false;
      })
  },
});

export default CreditSystemTransactionsSlice.reducer;

export const fetchCreditSystemTransactions = createAsyncThunk(
  "CreditSystemTransactions/fetchCreditSystemTransactions",
  async (param: { apiParam: { tpi_firm_id: any } }, thunkAPI) => {
    try {
      const { data } = await DataServer.get("api/GetCreditSystemTransactions?company_id=" + param.apiParam.tpi_firm_id);
      return data.data;
    } catch (error) {
      console.log("Error", error);
    }
  }
);
