import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { activateAlert } from "../Alert";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";


export const InspectionScheduleCalculations = createSlice({
  name: "Cycle",
  initialState: {
    Cycle: {
      load: false,
      data: {},
    },
  },
  reducers: {
    addCycleSuccess: (state, action) => {
      state.Cycle = action.payload;
      state.Cycle.load = false;
    },
    addCycleFail: (state, action) => {
      state.Cycle.load = action.payload.load;
    },
  },
});

export const {
  // addUserRequest,
  addCycleSuccess,
  addCycleFail,
} = InspectionScheduleCalculations.actions;

export default InspectionScheduleCalculations.reducer;


export const CreateCycle = createAsyncThunk(
  "users/Cycle",
  async (param: { apiParam: any, framecount: any }, thunkAPI) => {
    // thunkAPI.dispatch(toggleLoading());
    var Inspections = ["Daily", "Detailed", "Combined"];
    Inspections.sort();
    const permutation_array = [[] as string[]];
    for (const inspectiondata of Inspections) {                      // make pair of permutation_array
      const copy = [...permutation_array];
      for (const prefix of copy) {
        permutation_array.push(prefix.concat(inspectiondata));
      }
    }

    permutation_array.shift();   // remove 0 index which is blank array

    var inspectorList = [] as any;

    for (let i = 0; i < param.apiParam['allInspectorData'].length; i++) {
      inspectorList.push(param.apiParam['allInspectorData'][i]['inspectorType']);
    }
    var DailyWorkingHours = 0;
    var DetailedWorkingHours = 0;
    for (let i = 0; i < param.apiParam['allInspectorData'].length; i++) {
      if (param.apiParam['allInspectorData'][i]['inspectorType'] == 'Daily') {
        DailyWorkingHours += Number(param.apiParam['allInspectorData'][i]['workingHour']);
      }
      else if (param.apiParam['allInspectorData'][i]['inspectorType'] == 'Detailed') {
        // alert("called");
        DetailedWorkingHours += Number(param.apiParam['allInspectorData'][i]['workingHour']);
      }
      else if (param.apiParam['allInspectorData'][i]['inspectorType'] == 'Combined') {
        // alert("called");
        DetailedWorkingHours += Number(param.apiParam['allInspectorData'][i]['workingHour']);
      }
    }



    const ScheduleOption = param.apiParam['ScheduleOption'];
    const WorkingDays = param.apiParam['WorkingDays'];
    const BufferDays = param.apiParam['BufferDays'];
    const NumberOfInspector = param.apiParam['NumberOfInspector'];
    // const WorkingHours = param.apiParam['WorkingHours'];
    const timeReqDailyInspPerRack = param.apiParam['timeReqDailyInspPerRack'];
    const timeReqDetailedInspPerRack = param.apiParam['timeReqDetailedInspPerRack'];
    const RowCount = param.apiParam['HolidaysCount'];

    var test_arr = [];

    const count: any = {};

    inspectorList.forEach((element: any) => {
      count[element] = (count[element] || 0) + 1;
    });

    test_arr = Object.keys(count);

    test_arr.sort();

    var calculations_no: any;
    var selected_Inpsections = [test_arr];


    for (var x = 0; x < permutation_array.length; x++) {    // Match Select dropdown array with permutation_array
      for (var y = 0; y < selected_Inpsections.length; y++) {
        if (permutation_array[x][0] === selected_Inpsections[y][0]
          && permutation_array[x][1] === selected_Inpsections[y][1]
          && permutation_array[x][2] === selected_Inpsections[y][2]
          && permutation_array[x][3] === selected_Inpsections[y][3]
          && permutation_array[x][4] === selected_Inpsections[y][4]
        ) {
          calculations_no = x;
        }
      }
    }


    var totalWeek = 52;

    var totalWorkingDays = totalWeek * Number(WorkingDays);
    var numberOfWorkingDays = totalWorkingDays - Number(RowCount) - Number(BufferDays);
    var totalNoOfBaysInWarehouse = param.framecount;
    var CycleList: any = [];

    
    if (permutation_array[calculations_no] as any == "Daily") {
      var timeRequiredForDailyInspection = (totalNoOfBaysInWarehouse * Number(timeReqDailyInspPerRack));
      var timeRequiredForDailyInspectionInHr = (timeRequiredForDailyInspection / 60);
      // var timeRequiredForDailyInspectionInHr = (timeRequiredForDailyInspection / 60) / count.Daily;
      
      // console.log("DailyWorkingHours, timeRequiredForDailyInspectionInHr ", DailyWorkingHours, timeRequiredForDailyInspectionInHr);
      // alert("called")


      if (DailyWorkingHours > timeRequiredForDailyInspectionInHr) {
        for (let i = 1; i < 1; i++) {
          // console.log(i + " cycle of detailed inspection with " + numberOfWorkingDays + "+" + BufferDays + " buffer days");
          CycleList.push(i + " cycle of daily inspection ");

        }
      }
      else {
        // alert("Change way of inspection");
        thunkAPI.dispatch(
          activateSweetAlert({
            show: true,
            type: "warning",
            title: "Warning!",
            confirmBtnText: 'Done',
            message: "Change way of inspection",
            onConfirm: () => {
              handleonConfirm()
            },
          })
        );
  
        const handleonConfirm = () => {
          thunkAPI.dispatch(deActivateSweetAlert());
        };
      }

      thunkAPI.dispatch(
        addCycleSuccess({
          CycleList,
          load: false,
        })
      );
    }
    else if (permutation_array[calculations_no] as any == "Detailed") {
      // console.log(DetailedWorkingHours)
      var totalInspectorWorkingHour = Number(DetailedWorkingHours);
      // var timeRemainingToPerformDetailedInspPerDay = Number(WorkingHours) - timeRequiredForDailyInspectionInHr;
      var timeRemainingToPerformDetailedInspPerYear = Math.round(DetailedWorkingHours * numberOfWorkingDays);  //In Hours
      var timeRemainingToPerformDetailedInspPerYearInMin = timeRemainingToPerformDetailedInspPerYear * 60; //In Min

      var timeReqPerformDetailedInspOneCycle = totalNoOfBaysInWarehouse * Number(timeReqDetailedInspPerRack);
      var timeReqPerformDetailedInspOneCycleInHr = timeReqPerformDetailedInspOneCycle / 60;
      var timeReqPerformDetailedInspOneCycleInDays = Math.round(timeReqPerformDetailedInspOneCycleInHr / DetailedWorkingHours);

      var timeRemaningInYearOfDetailedInspection = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;

      var cycleCount = numberOfWorkingDays / timeReqPerformDetailedInspOneCycleInDays;

      // if (Number(DetailedWorkingHours) <= timeReqPerformDetailedInspOneCycleInHr) {
        for (let i = 1; i < cycleCount; i++) {
          numberOfWorkingDays = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;
          // CycleList.push(i + " cycle of detailed inspection with " + numberOfWorkingDays + "+" + Number(BufferDays) + " buffer days");
          CycleList.push({ id: i, NoOfCycle: i, CycleWorkingDays: numberOfWorkingDays, CycleBufferDays: Number(BufferDays) });
          // console.log("----", "NoOfCycle" + i, "CycleWorkingDays" + numberOfWorkingDays, "CycleBufferDays"  + Number(BufferDays))
        }
      // }
      // else {
      //   console.log("Change way of inspection");
      // }
      // thunkAPI.dispatch(toggleLoading());
      thunkAPI.dispatch(
        addCycleSuccess({
          CycleList,
          load: false,
        })
      );

      // console.log("numberOfWorkingDays", numberOfWorkingDays);
      // console.log("timeReqPerformDetailedInspOneCycleInHr", timeReqPerformDetailedInspOneCycleInHr);
      // console.log("timeReqPerformDetailedInspOneCycleInDays", timeReqPerformDetailedInspOneCycleInDays);
      // console.log("timeRemaningInYearOfDetailedInspection", timeRemaningInYearOfDetailedInspection);
      // console.log("timeReqPerformDetailedInspOneCycle", timeReqPerformDetailedInspOneCycle);
      // console.log("cycleCount", cycleCount);
      // console.log("CycleList", CycleList)
    }
    else if (permutation_array[calculations_no] as any == "Daily,Detailed") {

      // var totalWorkingHours = numberOfWorkingDays * ((Number(WorkingHours) * count.Daily) + ((Number(WorkingHours1) * count.Detailed)));
      var timeRequiredForDailyInspection = totalNoOfBaysInWarehouse * Number(timeReqDailyInspPerRack);
      var timeRequiredForDailyInspectionInHr = (timeRequiredForDailyInspection / 60);

      if (Number(DailyWorkingHours) < timeRequiredForDailyInspectionInHr) {
        thunkAPI.dispatch(
          activateSweetAlert({
            show: true,
            type: "warning",
            title: "Warning!",
            confirmBtnText: 'Done',
            message: "Change way of inspection",
            onConfirm: () => {
              handleonConfirm()
            },
          })
        );
  
        const handleonConfirm = () => {
          thunkAPI.dispatch(deActivateSweetAlert());
        };
      } else {
        var timeRemainingToPerformDetailedInspPerYear = Number(DetailedWorkingHours) * numberOfWorkingDays;
        var timeRemainingToPerformDetailedInspPerYearInMin = timeRemainingToPerformDetailedInspPerYear * 60;
  
        var timeReqPerformDetailedInspOneCycle = totalNoOfBaysInWarehouse * Number(timeReqDetailedInspPerRack);
        var timeReqPerformDetailedInspOneCycleInHr = (timeReqPerformDetailedInspOneCycle / 60);
        var timeReqPerformDetailedInspOneCycleInDays = Math.ceil(timeReqPerformDetailedInspOneCycleInHr / Number(DetailedWorkingHours));
  
        var timeRemaningInYearOfDetailedInspection = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;
  
        var cycleCount = Math.ceil(numberOfWorkingDays / timeReqPerformDetailedInspOneCycleInDays);
          for (let i = 1; i < cycleCount; i++) {
            numberOfWorkingDays = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;
            CycleList.push({ id: i, NoOfCycle: i, CycleWorkingDays: numberOfWorkingDays, CycleBufferDays: Number(BufferDays) });
          }
        thunkAPI.dispatch(
          addCycleSuccess({
            CycleList,
            load: false,
          })
        );
      }


    }
    else if (permutation_array[calculations_no] as any == "Combined") {
      var totalInspectorWorkingHour = Number(DetailedWorkingHours);
      var timeRequiredForDailyInspection = (totalNoOfBaysInWarehouse * Number(timeReqDailyInspPerRack)) / Number(NumberOfInspector);
      var timeRequiredForDailyInspectionInHr = (timeRequiredForDailyInspection / 60);

      var timeRemainingToPerformDetailedInspPerDay = DetailedWorkingHours - timeRequiredForDailyInspectionInHr;
      var timeRemainingToPerformDetailedInspPerYear = Math.round(timeRemainingToPerformDetailedInspPerDay * numberOfWorkingDays);
      var timeRemainingToPerformDetailedInspPerYearInMin = timeRemainingToPerformDetailedInspPerYear * 60;

      var timeReqPerformDetailedInspOneCycle = totalNoOfBaysInWarehouse * Number(timeReqDetailedInspPerRack);
      var timeReqPerformDetailedInspOneCycleInHr = (timeReqPerformDetailedInspOneCycle / 60) / Number(NumberOfInspector);
      var timeReqPerformDetailedInspOneCycleInDays = Math.round(timeReqPerformDetailedInspOneCycleInHr / timeRemainingToPerformDetailedInspPerDay);

      var timeRemaningInYearOfDetailedInspection = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;

      var cycleCount = numberOfWorkingDays / timeReqPerformDetailedInspOneCycleInDays;

      // console.log("totalInspectorWorkingHour", totalInspectorWorkingHour);
      // console.log("timeRequiredForDailyInspection", timeRequiredForDailyInspection);
      // console.log("timeRequiredForDailyInspectionInHr", timeRequiredForDailyInspectionInHr);
      // console.log("timeRemainingToPerformDetailedInspPerDay", timeRemainingToPerformDetailedInspPerDay);
      // console.log("timeReqPerformDetailedInspOneCycleInHr", timeReqPerformDetailedInspOneCycleInHr);
      // console.log("timeReqPerformDetailedInspOneCycleInDays", timeReqPerformDetailedInspOneCycleInDays);
      // console.log("timeRemaningInYearOfDetailedInspection", timeRemaningInYearOfDetailedInspection);
      // console.log("cycleCount", cycleCount);


      if (DetailedWorkingHours >= timeRequiredForDailyInspectionInHr) {
        for (let i = 1; i < cycleCount; i++) {
          numberOfWorkingDays = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;
          // console.log(i + " cycle of detailed inspection with " + numberOfWorkingDays + "+" + BufferDays + " buffer days");
          CycleList.push(i + " cycle of detailed inspection with " + numberOfWorkingDays + "+" + BufferDays + " buffer days");

        }
      }
      else {
        alert("Change way of inspection");
      }

      // return CycleList;
      thunkAPI.dispatch(
        addCycleSuccess({
          CycleList,
          load: false,
        })
      );
    }

    // else if (permutation_array[calculations_no] as any == "Combined,Daily") {
    //   var totalWorkingHours = numberOfWorkingDays * ((Number(WorkingHours) * count.Daily) + ((Number(WorkingHours1) * count.Combined)));
    //   var timeRequiredForDailyInspection = (totalNoOfBaysInWarehouse * Number(timeReqDailyInspPerRack)) / count.Daily;
    //   var timeRequiredForDailyInspectionInHr = (timeRequiredForDailyInspection / 60);

    //   var totalInspectorWorkingHour = Number(WorkingHours) + Number(WorkingHours1);

    //   var timeRemainingToPerformDetailedInspPerDay = totalInspectorWorkingHour - timeRequiredForDailyInspectionInHr;
    //   var timeRemainingToPerformDetailedInspPerYear = Math.round(timeRemainingToPerformDetailedInspPerDay * numberOfWorkingDays);
    //   var timeRemainingToPerformDetailedInspPerYearInMin = timeRemainingToPerformDetailedInspPerYear * 60;

    //   var timeReqPerformDetailedInspOneCycle = totalNoOfBaysInWarehouse * Number(timeReqDetailedInspPerRack);
    //   var timeReqPerformDetailedInspOneCycleInHr = (timeReqPerformDetailedInspOneCycle / 60);
    //   var timeReqPerformDetailedInspOneCycleInDays = Math.round(timeReqPerformDetailedInspOneCycleInHr / timeRemainingToPerformDetailedInspPerDay);

    //   var timeRemaningInYearOfDetailedInspection = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;

    //   var cycleCount = numberOfWorkingDays / timeReqPerformDetailedInspOneCycleInDays;

    //   console.log("totalWorkingHours", totalWorkingHours);
    //   console.log("timeRequiredForDailyInspection", timeRequiredForDailyInspection);
    //   console.log("timeRequiredForDailyInspectionInHr", timeRequiredForDailyInspectionInHr);
    //   console.log("timeRemainingToPerformDetailedInspPerDay", timeRemainingToPerformDetailedInspPerDay);
    //   console.log("timeReqPerformDetailedInspOneCycle", timeReqPerformDetailedInspOneCycle);
    //   console.log("timeReqPerformDetailedInspOneCycleInHr", timeReqPerformDetailedInspOneCycleInHr);
    //   console.log("timeReqPerformDetailedInspOneCycleInDays", timeReqPerformDetailedInspOneCycleInDays);
    //   console.log("timeRemaningInYearOfDetailedInspection", timeRemaningInYearOfDetailedInspection);
    //   console.log("cycleCount", cycleCount);

    //   if (Number(WorkingHours) < timeRequiredForDailyInspectionInHr) {
    //     for (let i = 1; i < cycleCount; i++) {
    //       numberOfWorkingDays = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;
    //       console.log(i + " cycle of detailed inspection with " + numberOfWorkingDays + "+" + BufferDays + " buffer days");
    //     }
    //   }
    //   else {
    //     alert("Change way of inspection");
    //   }
    // }
    // else if (permutation_array[calculations_no] as any == "Combined,Detailed") {
    //   alert("calculation Combined,Detailed");
    // }

    // else if (permutation_array[calculations_no] as any == "Combined,Daily,Detailed") {
    //   alert("calculation Combined,Daily,Detailed");
    // }

    else {
      return 0;
    }


    // var totalWeek = 52;

    // var totalWorkingDays = totalWeek * Number(WorkingDays);
    // var numberOfWorkingDays = totalWorkingDays - Number(RowCount) - Number(BufferDays);
    // var totalWorkingHours = numberOfWorkingDays * Number(WorkingHours) * Number(NumberOfInspector);
    // var totalNoOfBaysInWarehouse = 270;

    // console.log("totalWorkingDays", totalWorkingDays);
    // console.log("numberOfWorkingDays", numberOfWorkingDays);
    // console.log("totalWorkingHours", totalWorkingHours);

    // var timeRequiredForDailyInspection = totalNoOfBaysInWarehouse * Number(timeReqDailyInspPerRack);
    // var timeRequiredForDailyInspectionInHr = timeRequiredForDailyInspection/60;

    // var timeRemainingToPerformDetailedInspPerDay = Number(WorkingHours) - timeRequiredForDailyInspectionInHr;
    // var timeRemainingToPerformDetailedInspPerYear = Math.round(timeRemainingToPerformDetailedInspPerDay * numberOfWorkingDays);
    // var timeRemainingToPerformDetailedInspPerYearInMin = timeRemainingToPerformDetailedInspPerYear * 60;

    // var timeReqPerformDetailedInspOneCycle = totalNoOfBaysInWarehouse * Number(timeReqDetailedInspPerRack);
    // var timeReqPerformDetailedInspOneCycleInHr = timeReqPerformDetailedInspOneCycle / 60;
    // var timeReqPerformDetailedInspOneCycleInDays = Math.round(timeReqPerformDetailedInspOneCycleInHr / timeRemainingToPerformDetailedInspPerDay);

    // var timeRemaningInYearOfDetailedInspection = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;

    // var cycleCount = numberOfWorkingDays / timeReqPerformDetailedInspOneCycleInDays;


    // if(inspectionType == "Daily"){
    //   if(Number(WorkingHours) > timeRequiredForDailyInspectionInHr){
    //     for(let i = 1; i < cycleCount; i++){
    //       numberOfWorkingDays = numberOfWorkingDays - timeReqPerformDetailedInspOneCycleInDays;
    //       console.log(i + "cycle of detailed inspection with" + numberOfWorkingDays + "+" + timeReqPerformDetailedInspOneCycleInDays + "buffer days")
    //     }
    //   }
    //   else{
    //     alert("Change way of inspection");
    //   }
    // }



    // console.log("timeRequiredForDailyInspection", timeRequiredForDailyInspection);
    //           console.log("timeRequiredForDailyInspectionInHr", timeRequiredForDailyInspectionInHr);
    //           console.log("WorkingHours", WorkingHours);
    // console.log("timeRequiredForDailyInspection", timeRequiredForDailyInspection);
    // console.log("timeRequiredForDailyInspectionInHr", timeRequiredForDailyInspectionInHr);
    // // console.log("timeRemainingToPerformDetailedInspPerDay", timeRemainingToPerformDetailedInspPerDay);
    // console.log("timeRemainingToPerformDetailedInspPerYear", timeRemainingToPerformDetailedInspPerYear);
    // console.log("timeRemainingToPerformDetailedInspPerYearInMin", timeRemainingToPerformDetailedInspPerYearInMin);
    // console.log("timeReqPerformDetailedInspOneCycle", timeReqPerformDetailedInspOneCycle);
    // console.log("timeReqPerformDetailedInspOneCycleInHr", timeReqPerformDetailedInspOneCycleInHr);
    // console.log("timeReqPerformDetailedInspOneCycleInDays", timeReqPerformDetailedInspOneCycleInDays);
    // console.log("cycleCount", cycleCount);
  });
