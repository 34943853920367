import { createSlice } from "@reduxjs/toolkit";
import { SweetAlertOptionalProps } from "../../../reusable/SweetAlert/SweetAlert";

const initialiState = {
  show: false,
  message: "",
  title: "",
  onConfirm: {},
} as SweetAlertOptionalProps;

const sweetAlertSlice = createSlice({
  name: "SweetAlertSlice",
  initialState: initialiState,
  reducers: {
    activateSweetAlert(state, action) {
      const { message, title = "", onConfirm, confirmBtnText, type } = action.payload;
      state.show = true;
      state.type = type;
      state.message = message;
      state.title = title;
      state.onConfirm = onConfirm;
      state.confirmBtnText = confirmBtnText;
      state.closeOnClickOutside = false
    },
    deActivateSweetAlert(state) {
      state.show = false;
    },
  },
});

export const { activateSweetAlert, deActivateSweetAlert } =
  sweetAlertSlice.actions;
export default sweetAlertSlice.reducer;
