import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface NotificationState {
  notification: {
    load: boolean;
    data: [];
  };
}

export const NotificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification: {
      load: false,
      data: [],
    },
  } as NotificationState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchNotification.pending, (state: NotificationState) => {
        state.notification.load = true;
      })
      .addCase(fetchNotification.fulfilled, (state: NotificationState, action: any) => {
        state.notification.data = action.payload;
        state.notification.load = false;
      })
      .addCase(fetchNotification.rejected, (state: NotificationState) => {
        state.notification.load = false;
      });
  },
});

export default NotificationSlice.reducer;

export const fetchNotification = createAsyncThunk(
  "notification/fetchNotification",
  async (param: {apiParam: {loginUserId: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getNotificationData", {
        login_user_id: param.apiParam.loginUserId
      });
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);
