import { FC } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type Position =
  | "top-start"
  | "top-center"
  | "top-end"
  | "middle-start"
  | "middle-center"
  | "middle-end"
  | "bottom-start"
  | "bottom-center"
  | "bottom-end";

export interface toastsProps {
  isShow: boolean;
  onClose: Function;
  position?: Position;
  animation?: boolean;
  delay?: number;
  autohide?: boolean;
  header?: JSX.Element;
  message: any;
  closeButton?: boolean;
}
const Toasts: FC<toastsProps> = ({
  isShow,
  onClose,
  position = "bottom-end",
  animation = true,
  delay = 3000,
  autohide = true,
  header,
  message,
  closeButton = true,
}) => {
  return (
    <ToastContainer position={position} className="toasts m-4">
      <Toast
        show={isShow}
        onClose={() => onClose(!isShow)}
        animation={animation}
        delay={delay}
        autohide={autohide}
      >
        <Toast.Header closeButton={closeButton}>{header}</Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
export default Toasts;
