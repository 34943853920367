import { FC } from "react";
import AlertComponent from "../components/AlertComponent";
import Header from "./appHeader/header";
import Sidebar from "./appHeader/sidebar";
import "@fontsource/inter";
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import SweetAlertComponent from "../components/SweetAlertComponent";

interface layout {
  children: JSX.Element;
}

const LayoutContainer: FC<layout> = ({ children }) => {
  return (
    <>
      <Header />
      <div className="d-flex app-header">
        <Sidebar />
        <div className="content-width">
          <AlertComponent />
          <SweetAlertComponent />
          <div className="main-content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default LayoutContainer;
