import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface InspectorState {
  completeInspectionList: {
    load: boolean;
    data: [];
  };
}

export const inspectionSlice = createSlice({
  name: "completeInspectionList",
  initialState: {
    completeInspectionList: {
      load: false,
      data: [],
    },
  } as InspectorState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchInspectionList.pending, (state: InspectorState) => {
        state.completeInspectionList.load = true;
      })
      .addCase(fetchInspectionList.fulfilled, (state: InspectorState, action: any) => {
        state.completeInspectionList.data = action.payload;
        state.completeInspectionList.load = false;
      })
      .addCase(fetchInspectionList.rejected, (state: InspectorState) => {
        state.completeInspectionList.load = false;
      });
  },
});

export default inspectionSlice.reducer;

export const fetchInspectionList = createAsyncThunk(
  "completeInspectionList/fetchInspectionList",
  async (param: {apiParam : {per_page: any, page: any, sortby: any, inspectionby: any, search: any, warehouse_id: any, loginUserId: any, layout_id: any}}, thunkAPI) => {

    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/completeInspection" , {
        warehouse_id: param.apiParam.warehouse_id,
        sort_by: param.apiParam.sortby,
        inspection_by: param.apiParam.inspectionby,
        per_page: param.apiParam.per_page,
        page: param.apiParam.page,
        search: param.apiParam.search,
        login_user_id: param.apiParam.loginUserId,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
