import { DataServer } from "../../../../sensorconfig.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface OverviewStaticsState {
  OverviewStatics: {
    load: boolean;
    data: [];
  };
}

export const OverviewStaticsSlice = createSlice({
  name: "OverviewStatics",
  initialState: {
    OverviewStatics: {
      load: false,
      data: [],
    },
  } as OverviewStaticsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder   
      .addCase(getOverviewStatics.pending, (state: OverviewStaticsState) => {
        state.OverviewStatics.load = true;
      })
      .addCase(getOverviewStatics.fulfilled, (state: OverviewStaticsState, action: any) => {
        state.OverviewStatics.data = action.payload;
        state.OverviewStatics.load = false;
      })
      .addCase(getOverviewStatics.rejected, (state: OverviewStaticsState) => {
        state.OverviewStatics.load = false;
      });
  },
});

export default OverviewStaticsSlice.reducer;

export const getOverviewStatics = createAsyncThunk(
  "OverviewStatics/getOverviewStatics",
  async (param: {apiParam: { company_id: any, warehouse_id: any }}, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get("management/v1/getOverviewStatics?company_id=" + param.apiParam.company_id + "&warehouse_id=" + param.apiParam.warehouse_id);
      thunkAPI.dispatch(toggleLoading());

      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
  