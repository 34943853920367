import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../SweetAlert";

interface PersistentDatabaseSettingState {
  persistentdatabasesetting: {
    load: boolean;
    data: [];
  };
}

export const IssueImageSlice = createSlice({
  name: "persistentdatabasesetting",
  initialState: {
    persistentdatabasesetting: {
      load: false,
      data: [],
    },
  } as PersistentDatabaseSettingState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(
        fetchPersistentDatabaseSetting.pending,
        (state: PersistentDatabaseSettingState) => {
          state.persistentdatabasesetting.load = true;
        }
      )
      .addCase(
        fetchPersistentDatabaseSetting.fulfilled,
        (state: PersistentDatabaseSettingState, action: any) => {
          state.persistentdatabasesetting.data = action.payload;
          state.persistentdatabasesetting.load = false;
        }
      )
      .addCase(
        fetchPersistentDatabaseSetting.rejected,
        (state: PersistentDatabaseSettingState) => {
          state.persistentdatabasesetting.load = false;
        }
      );
  },
});

export default IssueImageSlice.reducer;

export const postPersistentDatabaseSetting = createAsyncThunk(
  "setting/postPersistentDatabaseSetting",
  async (param: { apiParam: { warehouse_id: any, persistant_database: any } }, thunkAPI) => {
    try {
      const { data } = await DataServer.post(
        "api/postPersistentDatabaseSetting",
        {
          warehouse_id: param.apiParam.warehouse_id,
          persistant_database: param.apiParam.persistant_database
        }
      );

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: "Done",
          message: data.message,
          onConfirm: () => {
            handleonConfirm();
          },
        })
      );

      const handleonConfirm = () => {
        thunkAPI.dispatch(deActivateSweetAlert());
        document.location.reload();
      };
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);


export const fetchPersistentDatabaseSetting = createAsyncThunk(
    "setting/fetchPersistentDatabaseSetting",
    async (param: { apiParam: { warehouse_id: any } }, thunkAPI) => {
      try {
        const { data } = await DataServer.post(
          "api/getPersistentDatabaseSetting",
          {
            warehouse_id: param.apiParam.warehouse_id,
          }
        );

        return data.data;
      } catch (error) {
        // console.log("Error", error);
      }
    }
  );
